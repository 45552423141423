import { InfoIcon } from "@chakra-ui/icons";
import {
  useToast,
  useDisclosure,
  Spinner,
  Box,
  ButtonGroup,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Flex,
  Text,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  HStack,
} from "@chakra-ui/react";
import { Header, SplitPage, ConfirmationModal, PersonIcon } from "@sciencecorp/helix-components";
import { titleize, humanize } from "inflection";
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router";
import { useUpdateInventory, useGetInventory, useSearchInventories } from "../../../api/inventory";
import { useUserOptions } from "../../../api/options";
import { useCurrentUserQuery, userHasRole } from "../../../api/user";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { NewInventoryModal } from "../components/NewInventoryModal";
import { inventoryStatusColor } from "../util";
import { AssignmentTable } from "./components/AssignmentTable";
import { InventoryDetailInformation } from "./components/InventoryDetailInformation";
import { NewInventoryItemModal } from "./components/RestockModal";
import { printBarcodes } from "./components/RestockModalComponents/util";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaEllipsisV, FaPrint } from "react-icons/fa";
import { BsArchive } from "react-icons/bs";
import { InventoryPopover } from "./components/InventoryPopover";

export const InventoryPage = () => {
  const { id, tab } = useParams();
  const currentUserQuery = useCurrentUserQuery();
  const isInventoryManager = userHasRole(currentUserQuery, "inventory_manager");
  const { mutate: updateInventory } = useUpdateInventory();
  const { debouncedSearch, search } = useDebouncedSearch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(tab?.toString().toLowerCase() || "details");

  const {
    data: inventory,
    isLoading: isLoadingInventory,
    isError: isErrorInventory,
    isSuccess: isSuccessInventory,
  } = useGetInventory(Number(id));

  const userOptions = useUserOptions();
  const { data: inventoryOptions } = useSearchInventories({
    term: search || "*",
    pagination: { per_page: -1 },
    order: { name: "asc" },
    filters: {
      is_archived: false,
    },
  });

  const toast = useToast();
  const {
    isOpen: archiveConfirmationModalIsOpen,
    onOpen: archiveConfirmationModalOnOpen,
    onClose: archiveConfirmationModalOnClose,
  } = useDisclosure();
  const {
    isOpen: archiveDenyModalIsOpen,
    onOpen: archiveDenyModalOnOpen,
    onClose: archiveDenyModalOnClose,
  } = useDisclosure();
  const {
    isOpen: restoreConfirmationModalIsOpen,
    onOpen: restoreConfirmationModalOnOpen,
    onClose: restoreConfirmationModalOnClose,
  } = useDisclosure();

  const handleArchiveSuccess = () => {
    archiveConfirmationModalOnClose();
    toast({
      title: "Successful!",
      description: `You sent ${inventory ? inventory.name : "the item"} to the archive.`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate("/inventory/items");
  };

  const handleRestoreSuccess = () => {
    restoreConfirmationModalOnClose();
    toast({
      title: "Successful!",
      description: `You sent ${inventory ? inventory.name : "the item"} back to the item list.`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate("/inventory/items");
  };

  const qrCodesToPrint = useMemo(
    () =>
      inventory?.inventory_item_barcodes?.map((item) => ({
        barcode_code: item.barcode_code,
        id: item.id.toString(),
      })) || [],
    [inventory]
  );

  const tabList = [
    {
      label: "Details",
      value: "details",
      icon: <IoDocumentTextOutline />,
      disabled: false,
    },
    {
      label: "Assignments",
      value: "assignments",
      icon: <PersonIcon />,
      disabled: !isInventoryManager || inventory?.is_consumable,
    },
  ];

  const handlePrintBarcodes = () => {
    if (qrCodesToPrint.length === 0) {
      toast({
        title: "Error Printing QR Codes",
        description: `There are no available items to print QR codes for`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      printBarcodes(qrCodesToPrint);
    }
  };
  const tabs = useMemo(() => tabList.filter((t) => !t.disabled), [isInventoryManager, inventory]);

  useEffect(() => {
    setActiveTab(tab?.toString().toLowerCase() || "details");
  }, [tab]);

  if (isLoadingInventory) {
    return <Spinner />;
  }

  if (isErrorInventory) {
    return <Text>Error loading inventory item</Text>;
  }

  const actions = isInventoryManager
    ? inventory.stock > 0
      ? [
          <ButtonGroup key="action-group-1">
            <InventoryPopover
              inventoryOptions={inventoryOptions}
              navigate={navigate}
              selectedItemId={inventory.id}
              debouncedSearch={debouncedSearch}
            />
            <NewInventoryModal inventory={inventory} />
            <Menu>
              <MenuButton as={IconButton} icon={<FaEllipsisV />} />
              <MenuList>
                <MenuItem icon={<BsArchive />} onClick={archiveConfirmationModalOnOpen}>
                  Send to Archive
                </MenuItem>
              </MenuList>
            </Menu>
          </ButtonGroup>,
        ]
      : [
          <ButtonGroup>
            <InventoryPopover
              inventoryOptions={inventoryOptions}
              navigate={navigate}
              selectedItemId={inventory.id}
              debouncedSearch={debouncedSearch}
            />
            <NewInventoryModal inventory={inventory} />
            <Menu>
              <MenuButton as={IconButton} icon={<FaEllipsisV />} />
              <MenuList>
                <MenuItem icon={<BsArchive />} onClick={archiveConfirmationModalOnOpen}>
                  Send to Archive
                </MenuItem>
              </MenuList>
            </Menu>
          </ButtonGroup>,
        ]
    : [];

  const statusLabel = inventory.is_archived
    ? "Unavailable"
    : titleize(humanize(inventory.status || ""));
  const statusColorScheme = inventory.is_archived
    ? "red"
    : inventoryStatusColor(inventory.status || "");

  return isSuccessInventory ? (
    <Box>
      {inventory.is_archived && (
        <Alert status="warning" variant="subtle" flexDirection="row" alignItems="center" mb={4}>
          <AlertIcon />
          <Box>
            <AlertTitle mr={2}>Archived Item</AlertTitle>
            <AlertDescription>
              This item is in the inventory archive. Restore this item to place it back on the item
              list.
            </AlertDescription>
          </Box>
          <Button onClick={restoreConfirmationModalOnOpen} colorScheme="orange" ml="auto">
            Restore Item
          </Button>
        </Alert>
      )}
      <Header
        title={inventory.name}
        badge={{
          label: statusLabel,
          colorScheme: statusColorScheme,
        }}
        crumbs={[
          {
            label: "Inventory",
            url: "/inventory/items",
          },
        ]}
        crumbsColor="teal"
        actions={actions}
      />
      <SplitPage
        sidebar={
          <Flex direction="column" gap={2}>
            {tabs.map(({ label, value, icon }) => (
              <Button
                key={value}
                size="md"
                width="100%"
                justifyContent="start"
                colorScheme={activeTab === value ? "teal" : "gray"}
                bg={activeTab === value ? "teal.500" : "transparent"}
                leftIcon={icon}
                onClick={() => {
                  setActiveTab(value);
                  navigate(`/inventory/items/${id}/${value}`);
                }}>
                {label}
              </Button>
            ))}
          </Flex>
        }
        sidebarWidth="200px"
        sidebarWidthXL="250px"
        breakpoint="md"
        main={
          <Box>
            <Stack
              direction={{ base: "column", md: "column" }}
              width="100%"
              align="start"
              spacing={4}>
              <Stack direction={{ base: "row", md: "column" }} justify="space-between" width="100%">
                <Flex
                  flex="1"
                  direction={["column", "row"]}
                  w="100%"
                  border={"1px"}
                  borderColor="chakra-border-color"
                  borderRadius="md"
                  justifyContent="space-between">
                  <StatGroup
                    p={6}
                    borderRightWidth={["0", "1px"]}
                    borderBottomWidth={["1px", "0"]}
                    w="100%">
                    {!inventory.is_consumable ? (
                      <>
                        <Stat p={2}>
                          <StatLabel w="max-content">Total Inventory Items</StatLabel>
                          <StatNumber fontWeight="medium" fontSize="xx-large">
                            {inventory.total_inventory_items} {inventory.unit_of_measurement || ""}
                          </StatNumber>
                        </Stat>
                        <Stat p={2}>
                          <StatLabel w="max-content">Available to Checkout</StatLabel>
                          <StatNumber fontWeight={"medium"} fontSize="xx-large">
                            {inventory.stock} {inventory.unit_of_measurement || ""}
                          </StatNumber>
                        </Stat>
                      </>
                    ) : (
                      <Stat p={2}>
                        <StatLabel w="max-content">In Stock</StatLabel>
                        <StatNumber fontWeight="medium" fontSize="xx-large">
                          {inventory.total_quantity_stock}{" "}
                          {inventory.unit_of_measurement || "units"}
                        </StatNumber>
                      </Stat>
                    )}
                  </StatGroup>
                  <HStack p={6}>
                    <Button variant="outline" onClick={handlePrintBarcodes} leftIcon={<FaPrint />}>
                      Print QR Codes
                    </Button>
                    <NewInventoryItemModal inventory={inventory} />
                  </HStack>
                </Flex>
              </Stack>

              {/* Render the main content based on the active tab */}
              {activeTab === "details" && (
                <InventoryDetailInformation
                  inventory={inventory}
                  isInventoryManager={isInventoryManager}
                />
              )}
              {activeTab === "assignments" && <AssignmentTable inventoryId={inventory.id} />}
            </Stack>

            <ConfirmationModal
              colorScheme="teal"
              children={`Restoring this item will place it back on the item list.`}
              header="Restore Inventory Item"
              isOpen={restoreConfirmationModalIsOpen}
              onClick={() => {
                updateInventory(
                  { id: inventory.id, is_archived: false },
                  { onSuccess: () => handleRestoreSuccess() }
                );
              }}
              confirmText="Confirm"
              onClose={restoreConfirmationModalOnClose}
            />
            <ConfirmationModal
              colorScheme="teal"
              children={
                <span>
                  Are you sure you want to archive <strong>{inventory.name}</strong>? This inventory
                  will no longer be available for checkout.
                </span>
              }
              header="Archive Inventory Item"
              isOpen={archiveConfirmationModalIsOpen}
              onClick={() => {
                updateInventory(
                  { id: inventory.id, is_archived: true },
                  { onSuccess: () => handleArchiveSuccess() }
                );
              }}
              confirmText="Confirm Archive"
              onClose={archiveConfirmationModalOnClose}
            />
            <ConfirmationModal
              colorScheme="teal"
              children={`You can only send items with no stock and pending items to the archive.`}
              header="Send To Archive"
              isOpen={archiveDenyModalIsOpen}
              onClick={() => {
                archiveDenyModalOnClose();
              }}
              onClose={archiveDenyModalOnClose}
            />
          </Box>
        }
      />
    </Box>
  ) : (
    <Text>Error loading inventory item</Text>
  );
};
