import React, { ReactNode, useState } from "react";
import {
  Box,
  Heading,
  HStack,
  VStack,
  Text,
  Divider,
  Avatar,
  Icon,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Flex,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { FiChevronRight, FiArrowLeft } from "react-icons/fi";
import { RecordLink, Select } from "@sciencecorp/helix-components";
import { IoMedalOutline } from "react-icons/io5";
import { GoStopwatch } from "react-icons/go";
import {
  useGetUserCredentialFlags,
  UserCredentialFlagData,
} from "../../../../api/user_credentials";
import { humanize, titleize } from "inflection";

export const UserCredentialFlags = () => {
  const [selectedUser, setSelectedUser] = useState<UserCredentialFlagData | null>(null);

  const [status, setStatus] = useState("expired");

  const { data: flags, isLoading, isError } = useGetUserCredentialFlags(status);

  const handleBack = () => {
    setSelectedUser(null);
  };

  const expirationColor = useColorModeValue("gray.600", "gray.300");

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <Text>Error fetching flags</Text>;
  }

  if (selectedUser) {
    return (
      <VStack
        align="start"
        border="1px"
        justify="stretch"
        p={5}
        spacing={4}
        borderRadius="md"
        flex="1"
        height="100%"
        borderColor="chakra-border-color"
        width="100%">
        <HStack spacing={4}>
          <Icon as={FiArrowLeft} boxSize={6} onClick={handleBack} cursor="pointer" />
          <Avatar size="sm" src={selectedUser.user.picture_uri} name={selectedUser.user.name} />
          <Heading size="md">{selectedUser.user.name}</Heading>
        </HStack>
        <Box>
          <Stat>
            <StatLabel>{titleize(humanize(status))} Credentials</StatLabel>
            <StatNumber>{selectedUser.user_credentials.length}</StatNumber>
          </Stat>
        </Box>
        <VStack
          spacing={4}
          p={2}
          align="start"
          width="100%"
          justify="start"
          maxH={60}
          overflow="auto">
          {selectedUser.user_credentials.map((userCredential) => (
            <HStack justify="space-between" w="100%">
              <RecordLink
                identifier={userCredential.credential.name}
                icon={<IoMedalOutline />}
                link={`/credentials/all/${userCredential.credential.id}`}
              />
              <HStack>
                <HStack>
                  <Icon as={GoStopwatch} color={expirationColor} />
                  {status === "expiring_soon" ? (
                    <Text fontSize="sm" color={expirationColor} w="max-content">
                      Expires in: {userCredential.expiration_date.diffNow("days").days.toFixed()}{" "}
                      days
                    </Text>
                  ) : (
                    <Text fontSize="sm" color={expirationColor} w="max-content">
                      Expired on: {userCredential.expiration_date.toFormat("LLL dd yyyy")}
                    </Text>
                  )}
                </HStack>
              </HStack>
            </HStack>
          ))}
        </VStack>
      </VStack>
    );
  }

  // Main flags list view
  return (
    <VStack
      flex="1"
      align="start"
      border="1px"
      p={5}
      spacing={4}
      borderRadius="md"
      borderColor={useColorModeValue("gray.200", "gray.600")}
      width="100%">
      <HStack justify="space-between" width="100%">
        <Heading size="md">Flags</Heading>
        <Select
          size="sm"
          value={status}
          options={[
            { label: "Expired", value: "expired" },
            { label: "Expiring Soon", value: "expiring_soon" },
          ]}
          onChange={(value) => setStatus(value.toString())}
        />
      </HStack>

      <StatGroup w="100%">
        <Stat>
          <StatLabel>{titleize(humanize(status))}</StatLabel>
          <StatNumber>{flags.credential_count}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Employees</StatLabel>
          <StatNumber>{flags.results.length}</StatNumber>
        </Stat>
      </StatGroup>

      <Divider />

      <VStack width="100%" align="start" spacing={4} maxH="2xs" overflowY="scroll">
        {flags.results.map((employee) => (
          <HStack
            key={employee.user.name}
            justify="space-between"
            w="100%"
            onClick={() => setSelectedUser(employee)}
            cursor="pointer">
            <HStack>
              <Avatar size="sm" src={employee.user.picture_uri} name={employee.user.name} />
              <Text fontSize="sm">{employee.user.name}</Text>
            </HStack>
            <HStack>
              <Text fontSize="sm" color="gray.500">
                {employee.user_credentials.length} {humanize(status)}
              </Text>
              <Icon as={FiChevronRight} />
            </HStack>
          </HStack>
        ))}
      </VStack>
    </VStack>
  );
};
