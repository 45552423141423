import { RichTextEditor } from "@sciencecorp/helix-components";
import { Project, useUpdateProject } from "../../../api/planning/projects";
import React, { useState } from "react";
import { Button, Flex, Heading, Tooltip, useColorModeValue } from "@chakra-ui/react";

export interface ProjectDescriptionEditorProps {
  project: Project;
}

export const ProjectDescriptionEditor = ({
  project,
}: ProjectDescriptionEditorProps): JSX.Element => {
  const { mutate: updateProject } = useUpdateProject();
  const [editable, setEditable] = useState(!project.description);
  return (
    <>
      <Flex
        justifyContent={"start"}
        direction={"column"}
        w={"100%"}
        border="1px"
        borderColor={useColorModeValue("gray.200", "gray.600")}
        p={6}
        gap={4}
        borderRadius="md">
        <Flex flexDir={"column"} width={"100%"} key={`project-${project.id}-description-editor`}>
          <Heading size="md">Description</Heading>
          <Flex alignItems={"center"} width={"100%"}>
            <Tooltip label="Double click to edit description" isDisabled={editable} placement="top">
              <Flex
                width={"100%"}
                onDoubleClick={() => setEditable(true)}
                gap={2}
                flexDir={"column"}>
                <RichTextEditor
                  width="100%"
                  editable={editable}
                  defaultValue={project.description ? JSON.stringify(project.description) : null}
                  onSave={(raw, _rich) => {
                    updateProject(
                      { id: project.id, description: JSON.parse(raw) },
                      { onSuccess: () => setEditable(false) }
                    );
                  }}
                />
                <Flex justifyContent={"end"} alignItems={"end"}>
                  <Button
                    display={!editable ? "block" : "none"}
                    onClick={() => {
                      setEditable(true);
                    }}>
                    Edit
                  </Button>
                </Flex>
              </Flex>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
