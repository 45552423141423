import {
  Box,
  FormControl,
  FormLabel,
  Input,
  MenuItem,
  Stack,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import React, { useState } from "react";
import { FaRegFileAlt } from "react-icons/fa";
import { useCreateContract } from "../../../../api/contracts";
import { useVendorOptions } from "../../../../api/options";
import { MinimalSpendingAuthority } from "../../../../api/spending_authority";
import { useNewVendor } from "../../../../api/vendor";
import { SpendingAuthoritySelect } from "../../SpendingAuthoritySelectTree";

export const NewContractModal = () => {
  const vendorOptions = useVendorOptions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [vendorId, setVendorId] = useState<number | null>(null);
  const [description, setDescription] = useState<string>("");
  const [name, setName] = useState<string>();
  const [spendingAuthority, setSpendingAuthority] = useState<MinimalSpendingAuthority | null>(null);

  const [encodedFiles, setEncodedFiles] = useState<{ name: string; file: string }[]>([]);

  const { mutate: createContract, isLoading } = useCreateContract();
  const { mutate: createVendor } = useNewVendor();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);

      const promises = files.map((file) => {
        return new Promise<{ name: string; file: string }>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.result) {
              resolve({
                name: file.name,
                file: reader.result as string,
              });
            } else reject(new Error("File couldn't be read"));
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      Promise.all(promises)
        .then((encodedFileObjects) => setEncodedFiles(encodedFileObjects))
        .catch((error) => console.log(error));
    }
  };

  const handleClose = () => {
    setVendorId(null);
    setSpendingAuthority(null);
    setDescription("");
    setEncodedFiles([]);
    setName(undefined);
    onClose();
  };

  const handleSubmit = () => {
    if (vendorId && spendingAuthority && name) {
      createContract(
        {
          name: name,
          vendor_id: vendorId,
          spending_authority_id: spendingAuthority.id,
          spending_authority_type: spendingAuthority.type,
          description,
          files: encodedFiles,
          status: "new",
        },
        { onSuccess: () => handleClose() }
      );
    }
  };

  return (
    <>
      <MenuItem onClick={() => onOpen()} fontWeight="semibold" p={3} pl={6} icon={<FaRegFileAlt />}>
        Contract
      </MenuItem>

      <FormModal
        title="New Contract"
        isOpen={isOpen}
        onClose={handleClose}
        isLoading={isLoading}
        submitButtonDisabled={!vendorId || !spendingAuthority || !name}
        handleSubmit={handleSubmit}
        submitButtonColorSchema="teal"
        submitButtonTitle="Create Contract"
        size="2xl">
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </FormControl>
        <Stack direction={{ base: "column", md: "row" }} w="100%">
          <FormControl>
            <FormLabel>Vendor</FormLabel>
            <Select
              options={vendorOptions}
              value={vendorId}
              creatable
              onCreate={(value) => {
                createVendor(
                  {
                    name: value.toString(),
                    website: "",
                    status: "approved",
                    description: "",
                    address: "",
                    payment_terms: null,
                    tax_id: null,
                    category: null,
                  },
                  {
                    onSuccess: (data) => {
                      setVendorId(data.id);
                    },
                  }
                );
              }}
              onChange={(value) => setVendorId(value as number)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Spending Authority</FormLabel>
            <SpendingAuthoritySelect
              onChange={setSpendingAuthority}
              spendingAuthority={spendingAuthority}
            />
          </FormControl>
        </Stack>
        <FormControl>
          <FormLabel>Additional Information</FormLabel>
          <Textarea value={description} onChange={(e) => setDescription(e.target.value)} />
        </FormControl>
        <FormControl>
          <FormLabel>Attach Documents</FormLabel>
          <Box
            width="100%"
            border="1px"
            p={1}
            borderColor={useColorModeValue("gray.200", "gray.500")}
            borderRadius="base">
            <input type="file" multiple={true} onChange={(e) => handleFileChange(e)} />
          </Box>
        </FormControl>
      </FormModal>
    </>
  );
};
