import {
  Button,
  ButtonGroup,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { CheckedOutInventory } from ".";
import { useBudgetItemSummary } from "../../../api/budget_items";
import { useBulkCheckoutInventory } from "../../../api/inventory_item";
import {
  NullableSpendingAuthority,
  useGetSpendingAuthority,
} from "../../../api/spending_authority";
import { useCurrency } from "../../../contexts/CurrencyContext";
import { Money } from "../../../helpers/Money";
import { calculateCostAndItemsUsed } from "../util";
import { CheckoutSummary } from "./CheckoutModalComponents/CheckoutSummary";
import { SelectCheckoutPurpose } from "./CheckoutModalComponents/SelectCheckoutPurpose";
import { SelectSpendingAuthority } from "./CheckoutModalComponents/SelectSpendingAuthority";

const defaultFormValues = {
  purpose: null,
  spending_authority: null,
};

export type FormValuesType = {
  purpose: "spending_authority" | "waste" | "used" | null;
  spending_authority: NullableSpendingAuthority;
};
type CheckoutModalProps = {
  selectedInventory: CheckedOutInventory[];
  sum: Money;
};

export const CheckoutModal = ({ selectedInventory, sum }: CheckoutModalProps) => {
  const currency = useCurrency();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [page, setPage] = useState<number>(0);
  const navigate = useNavigate();
  const { mutate: bulkCheckoutInventory, isLoading: isLoadingBulkCheckout } =
    useBulkCheckoutInventory();
  const toast = useToast();

  const { handleSubmit, control, reset, watch, setValue } = useForm<FormValuesType>({
    defaultValues: defaultFormValues,
  });

  const formValues = watch();

  const handleClose = () => {
    reset();
    onClose();
    setPage(0);
  };

  const onSubmit = (data: FormValuesType) => {
    const items = selectedInventory.flatMap(
      (item) =>
        calculateCostAndItemsUsed(item.amount_checked_out, item.inventory_items, currency).itemsUsed
    );
    if (data.purpose) {
      bulkCheckoutInventory(
        {
          items,
          purpose: data.purpose,
          spending_authority_id: data.spending_authority?.id || null,
          spending_authority_type: data.spending_authority?.type || null,
        },
        {
          onSuccess: () => {
            let description: string;
            if (data.purpose === "waste") {
              description = "marked as waste.";
            } else if (data.purpose === "used") {
              description = "marked as used.";
            } else {
              description = "checked out to " + data.spending_authority?.name;
            }

            navigate("/inventory/items");
            toast({
              title: "Inventory checked out successfully",
              description: "The selected items have been " + description,
              status: "success",
              isClosable: true,
            });
          },
        }
      );
    }
  };

  const spendingAuthority = formValues.spending_authority;

  const spendingAuthorityQuery = useGetSpendingAuthority(
    spendingAuthority?.id,
    spendingAuthority?.type
  );

  const rootSpendingAuthority = spendingAuthorityQuery.data?.rootSpendingAuthority;

  const biSummaryQuery = useBudgetItemSummary(
    rootSpendingAuthority?.id,
    DateTime.now().endOf("year")
  );

  const disableNextButton = () => {
    if (page === 0) return !formValues.purpose;
    if (page === 1) {
      if (formValues.purpose === "spending_authority") return !formValues.spending_authority;
      else false;
    }
    if (page === 2) {
      if (
        biSummaryQuery.isLoading ||
        (
          biSummaryQuery.data?.budget_group_summary?.remaining_amount ||
          biSummaryQuery.data?.remaining_amount ||
          Money.zero(currency)
        ).lt(sum)
      )
        return true;
    } else return false;
  };

  const handleNextButtonClick = () => {
    if (page === 0) {
      setPage(1);
    } else if (page === 1) {
      if (formValues.purpose === "spending_authority") {
        setPage(2);
      } else {
        handleSubmit(onSubmit)();
      }
    } else if (page === 2) {
      handleSubmit(onSubmit)();
    }
  };

  const buttonTitle = () => {
    if (page === 0) return "Next";
    if (page === 1 && formValues.purpose === "spending_authority") return "Next";
    return "Submit";
  };

  return (
    <>
      <Button
        onClick={onOpen}
        colorScheme="teal"
        w="100%"
        isDisabled={selectedInventory.length === 0}>
        Checkout
      </Button>
      <Modal isOpen={isOpen} onClose={handleClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Checkout</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column">
              {page === 0 && <SelectCheckoutPurpose control={control} setValue={setValue} />}
              {page === 1 && (
                <SelectSpendingAuthority
                  control={control}
                  setValue={setValue}
                  formValues={formValues}
                />
              )}
              {page === 2 && (
                <CheckoutSummary
                  selectedInventory={selectedInventory}
                  sum={sum}
                  budgetItemSummary={biSummaryQuery.data}
                  formValues={formValues}
                />
              )}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup justifyContent={page === 0 ? "end" : "space-between"} w="100%">
              {page !== 0 && (
                <Button onClick={() => setPage((prev) => --prev)} variant="ghost">
                  Back
                </Button>
              )}
              <Button
                colorScheme="teal"
                onClick={handleNextButtonClick}
                isDisabled={disableNextButton()}
                isLoading={isLoadingBulkCheckout}>
                {buttonTitle()}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
