import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { invalidateInventories } from "./inventory";
import { zodParse } from "./zodParse";

export const INVENTORY_VENDORS_BASE_URL = "/inventory_vendors";

export const inventoryVendorSchema = z.object({
  id: z.number(),
  vendor_id: z.number(),
  inventory_id: z.number(),
  link: z.string().nullable(),
});

export const inventoryVendorShowSchema = inventoryVendorSchema
  .omit({ vendor_id: true, inventory_id: true })
  .extend({
    vendor: z.object({
      id: z.number(),
      name: z.string(),
    }),
  });

export const bulkInventoryVendorCreateParamsSchema = z.object({
  vendor_ids: z.number().array(),
  inventory_id: z.number(),
});
export type InventoryVendorData = z.infer<typeof inventoryVendorSchema>;
export type InventoryVendorShowData = z.infer<typeof inventoryVendorShowSchema>;
export type InventoryVendorCreateParams = Omit<InventoryVendorData, "id">;
export type BulkInventoryVendorCreateParams = z.infer<typeof bulkInventoryVendorCreateParamsSchema>;

/** queries */

export const createInventoryVendor = async (inventory_vendor: InventoryVendorCreateParams) => {
  const result = await api.post(INVENTORY_VENDORS_BASE_URL, { inventory_vendor });
  return zodParse(inventoryVendorSchema, result.data);
};

export const bulkCreateInventoryVendor = async (
  inventory_vendor: BulkInventoryVendorCreateParams
) => {
  await api.post(`${INVENTORY_VENDORS_BASE_URL}/bulk`, { inventory_vendor });
};

export const updateInventoryVendor = async (inventory_vendor: Partial<InventoryVendorData>) => {
  await api.put(`${INVENTORY_VENDORS_BASE_URL}/${inventory_vendor.id}`, { inventory_vendor });
};

export const deleteInventoryVendor = async (id: number) => {
  await api.delete(`${INVENTORY_VENDORS_BASE_URL}/${id}`);
  return id;
};

/** hooks */

export const useBulkCreateInventoryVendor = (id?: number) => {
  return useMutation({
    mutationFn: bulkCreateInventoryVendor,
    onSuccess: invalidateInventories(id),
  });
};

export const useCreateInventoryVendor = (id?: number) => {
  return useMutation({
    mutationFn: createInventoryVendor,
    onSuccess: invalidateInventories(id),
  });
};

export const useUpdateInventoryVendor = (id: number) => {
  return useMutation({
    mutationFn: updateInventoryVendor,
    onSuccess: invalidateInventories(id),
  });
};

export const useDeleteInventoryVendor = (id: number) => {
  return useMutation({
    mutationFn: deleteInventoryVendor,
    onSuccess: invalidateInventories(id),
  });
};
