import React, { useState } from "react";

import { FormModal, AttributesTable, Select } from "@sciencecorp/helix-components";
import {
  Text,
  Avatar,
  Input,
  Textarea,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  HStack,
  useDisclosure,
  ModalHeader,
  ModalCloseButton,
  Flex,
  Divider,
  useColorModeValue,
  Box,
  FormControl,
  ButtonGroup,
  ModalFooter,
  FormLabel,
} from "@chakra-ui/react";
import {
  BudgetItemShowData,
  useApproveBudgetItemQuery,
  useDeclineBudgetItemQuery,
} from "../../../api/budget_items";
import { useGeneralLedgerCodeOptions } from "../../../api/options";

export const ReviewBudgetItemModal = ({ budgetItem }: { budgetItem: BudgetItemShowData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [glCode, setGlCode] = useState<string | null>("");
  const [memo, setMemo] = useState("");
  const glCodeOptions = useGeneralLedgerCodeOptions();

  const { mutate: approveBudgetItem, isLoading: isLoadingApprove } = useApproveBudgetItemQuery();
  const { mutate: declineBudgetItem, isLoading: isLoadingDecline } = useDeclineBudgetItemQuery();
  const handleClose = () => {
    setGlCode("");
    setMemo("");
    onClose();
  };

  const handleApprove = () => {
    approveBudgetItem(
      {
        id: budgetItem.id,
        general_ledger_code_id: Number(glCode),
        message: memo,
      },
      { onSuccess: () => handleClose() }
    );
  };

  const handleDecline = () => {
    declineBudgetItem({ id: budgetItem.id, message: memo }, { onSuccess: () => handleClose() });
  };

  return (
    <>
      <Button onClick={onOpen} colorScheme="orange" size="sm" variant="outline">
        Review
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Review Budget Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" flexDir="column" gap={4}>
            <Flex
              direction="column"
              p={6}
              gap={4}
              border="1px"
              borderColor="chakra-border-color"
              borderRadius="md">
              <Box>
                <HStack justify="space-between" mb={2}>
                  <Text color={useColorModeValue("gray.600", "auto")}>Item Name</Text>
                  <Text fontWeight="semibold">{budgetItem.item_name}</Text>
                </HStack>
                <Divider />
              </Box>
              <Box>
                <HStack justify="space-between" mb={2}>
                  <Text color={useColorModeValue("gray.600", "auto")}>Created By</Text>
                  <HStack>
                    <Avatar
                      size="sm"
                      src={budgetItem.requested_by?.picture_uri}
                      name={budgetItem.requested_by?.name}
                    />
                    <Text fontWeight="semibold">{budgetItem.requested_by?.name}</Text>
                  </HStack>
                </HStack>
                <Divider />
              </Box>
              <Box>
                <HStack justify="space-between" mb={2}>
                  <Text color={useColorModeValue("gray.600", "auto")}>Created on</Text>
                  <Text fontWeight="semibold">
                    {budgetItem.created_at?.toFormat("LLL dd, yyyy")}
                  </Text>
                </HStack>
                <Divider />
              </Box>
            </Flex>
            <FormControl>
              <FormLabel>General Ledger Code</FormLabel>
              <Select
                options={glCodeOptions}
                value={glCode}
                onChange={(value) => setGlCode(value.toString())}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Memo</FormLabel>
              <Textarea placeholder="Type here" resize="none" />
            </FormControl>

            <ModalFooter px={0}>
              <ButtonGroup>
                <Button variant="outline" colorScheme="red" onClick={handleDecline}>
                  Decline
                </Button>
                <Button colorScheme="teal" isDisabled={!glCode} onClick={handleApprove}>
                  Approve
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
