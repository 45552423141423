import React, { useState, useMemo } from "react";
import {
  Box,
  Stack,
  VStack,
  Text,
  Icon,
  Link,
  useColorModeValue,
  HStack,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  Tag,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Collection,
  RecordLink,
  ConfirmationModal,
  Column,
  type Pagination,
} from "@sciencecorp/helix-components";
import { EditIcon, InfoOutlineIcon, Search2Icon } from "@chakra-ui/icons";
import { TbPresentationAnalytics } from "react-icons/tb";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiArchive } from "react-icons/fi";
import {
  TrainingData,
  TrainingIndexData,
  TrainingShowData,
  useUpdateTraining,
} from "../../../api/trainings";
import { Link as RouterLink } from "react-router-dom";
import { NewTrainingModal } from "./components/NewTrainingModal";
import { attendanceHistoryColorScheme } from "../util";
import { DateTime } from "luxon";
import { humanize, titleize } from "inflection";
import { Order } from "../../../api/collection_types";

type TrainingsProps = {
  data: TrainingIndexData;
  isLoading: boolean;
  onPagination: (pagination: Pagination) => void;
  pagination: Pagination;
  order: Order;
  onOrder: (order: Order) => void;
  isCredentialsAdmin: boolean;
};

export const Trainings = ({
  data,
  isLoading,
  onPagination,
  pagination,
  order,
  onOrder,
  isCredentialsAdmin,
}: TrainingsProps) => {
  const { mutate: updateTraining } = useUpdateTraining();
  const [activeTraining, setActiveTraining] = useState<TrainingShowData | null>(null);
  const {
    isOpen: isOpenTraining,
    onOpen: onOpenTraining,
    onClose: onCloseTraining,
  } = useDisclosure();

  const { isOpen: isOpenArchive, onOpen: onOpenArchive, onClose: onCloseArchive } = useDisclosure();

  const columns = useMemo(
    () =>
      [
        {
          label: "Training",
          orderOptions: { orderKey: "name" },
          render: (training: TrainingShowData) => (
            <RecordLink
              type=""
              icon={<Icon as={TbPresentationAnalytics} ml={2} />}
              identifier={training.name}
              link={`/trainings/${training.id}`}
            />
          ),
        },

        {
          label: "Awarded Credential",
          render: (training: TrainingShowData) => (
            <HStack>
              <Tag>{training.credential_trainings[0]?.credential.name} </Tag>
              {training.credential_trainings.length > 1 && (
                <Text fontWeight="medium" fontSize="sm">
                  + {training.credential_trainings.length - 1} others
                </Text>
              )}
            </HStack>
          ),
        },
        isCredentialsAdmin && {
          label: "Status",
          render: (training: TrainingShowData) => (
            <Tag>{training.is_draft ? "Draft" : "Active"}</Tag>
          ),
        },
        {
          label: "Next Session",
          render: (training: TrainingShowData) =>
            training.next_session
              ? DateTime.fromISO(training.next_session).toFormat("LLL dd yyyy")
              : "N/A",
        },

        isCredentialsAdmin && {
          label: "Completed Sessions",
          render: (training: TrainingShowData) => training.completed_training_sessions,
        },

        isCredentialsAdmin && {
          label: "",
          render: (training: TrainingData) => (
            <ActionsMenu
              training={training}
              setActiveTraining={setActiveTraining}
              onOpenTraining={onOpenTraining}
              onOpenArchive={onOpenArchive}
            />
          ),
        },
      ].filter(Boolean) as Column[],
    [data, isCredentialsAdmin]
  );

  return (
    <Box width="100%">
      <VStack width="100%" align="start" spacing={4}>
        {!isCredentialsAdmin && (
          <HStack
            p={3}
            spacing={4}
            mb={6}
            borderRadius="md"
            border="1px"
            borderColor={useColorModeValue("gray.200", "gray.500")}>
            <InfoOutlineIcon color="gray.500" />
            <Text fontSize="sm">
              Below is a list of all the trainings offered by Science. You may register for any
              training, but you are only required to complete the trainings linked to the
              credentials that have been assigned to you.
            </Text>
          </HStack>
        )}

        {isCredentialsAdmin && (
          <Stack width="100%" direction={{ base: "column", lg: "row" }}>
            <StatGroup
              py={3}
              px={6}
              flexDir={{ base: "column", md: "row" }}
              border="1px"
              borderColor="chakra-border-color"
              borderRadius="md"
              flex="3">
              <Stat>
                <StatLabel>No. of Trainings</StatLabel>
                <StatNumber>{data?.training_count}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Total Completed Sessions</StatLabel>
                <StatNumber>{data?.total_completed_sessions}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Total Trained Employees</StatLabel>
                <StatNumber>{data?.total_completed_user_training_sessions}</StatNumber>
              </Stat>
            </StatGroup>

            <HStack
              py={3}
              px={6}
              border="1px"
              borderColor="chakra-border-color"
              borderRadius="md"
              spacing={4}
              flex="1"
              justify="space-between"
              bg={useColorModeValue("gray.50", "gray.700")}>
              <Stat>
                <StatLabel>Archived Trainings</StatLabel>
                <StatNumber>{data?.archived_trainings}</StatNumber>
              </Stat>
              <Link
                as={RouterLink}
                fontSize="sm"
                color="teal.500"
                to="/credentials/archive/trainings">
                View
              </Link>
            </HStack>
          </Stack>
        )}
        {data?.results.length ? (
          <Box width="100%">
            <Collection
              columns={columns}
              items={data?.results || []}
              pagination={data?.pagination || pagination}
              isLoading={isLoading}
              order={order}
              onPagination={onPagination}
              onOrder={onOrder}
            />

            {activeTraining && (
              <>
                <NewTrainingModal
                  isOpen={isOpenTraining}
                  onClose={onCloseTraining}
                  training={activeTraining}
                />
                <ConfirmationModal
                  colorScheme="red"
                  isOpen={isOpenArchive}
                  onClose={onCloseArchive}
                  header="Archive Training"
                  children="Archiving the training will remove it from the current list, but you can retrieve it by going to Trainings >> Archive."
                  onClick={() => {
                    updateTraining(
                      { id: activeTraining.id, is_archived: true },
                      { onSuccess: () => onCloseArchive() }
                    );
                  }}
                />
              </>
            )}
          </Box>
        ) : (
          <VStack
            width="100%"
            minHeight="xs"
            justify="center"
            borderRadius="md"
            bg={useColorModeValue("gray.50", "gray.700")}
            opacity="0.7"
            spacing={5}>
            <Search2Icon boxSize={6} />
            <VStack spacing={0}>
              <Text fontWeight="semibold" fontSize="sm">
                It’s empty here.
              </Text>
              <Text fontWeight="medium" fontSize="xs">
                There are no trainings.
              </Text>
            </VStack>
          </VStack>
        )}
      </VStack>
    </Box>
  );
};

export const ActionsMenu = ({ training, setActiveTraining, onOpenTraining, onOpenArchive }) => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<Icon as={BsThreeDotsVertical} />}
        variant="ghost"
        size="sm"
      />
      <MenuList fontSize="md">
        <MenuItem
          gap={3}
          onClick={() => {
            setActiveTraining(training);
            onOpenTraining();
          }}>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem
          gap={3}
          color="red.500"
          onClick={() => {
            setActiveTraining(training);
            onOpenArchive();
          }}>
          <FiArchive />
          Archive
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
