import { AddIcon, ArrowForwardIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  HStack,
  IconButton,
  Stack,
  Tag,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {
  buildFacets,
  Collection,
  EmptyState,
  Facets,
  Header,
  RecordLink,
  useCollection,
} from "@sciencecorp/helix-components";
import { humanize, inflect, titleize } from "inflection";
import { initial } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { InventoryCheckoutData, useSearchInventoriesCheckout } from "../../../api/inventory";
import {
  LocationTreeKeyValue,
  LocationTreeValue,
  useTreeSelectInventoryLocations,
} from "../../../api/inventory_location";
import { useCurrency } from "../../../contexts/CurrencyContext";
import { Money } from "../../../helpers/Money";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { MoneyText } from "../../MoneyText";
import { findSelectedOption, TreeSelect } from "../../shared/TreeSelect";
import { calculateCostAndItemsUsed, inventoryStatusColor } from "../util";
import { CheckoutModal } from "./CheckoutModal";

export type CheckedOutInventory = InventoryCheckoutData & {
  amount_checked_out: number;
  inventory_location: { id: number; name: string } | null;
};

export const InventoryCheckout = () => {
  const currency = useCurrency();
  const { search, debouncedSearch } = useDebouncedSearch();
  const [locationSearch, setLocationSearch] = useState<string | undefined>();
  const [showPreview, setShowPreview] = useState(false);
  const treeSelectInventoryLocationsQuery = useTreeSelectInventoryLocations(
    locationSearch,
    "Select Location"
  );
  const [location, setLocation] = useState<LocationTreeValue | null>(null);
  const { pagination, order, onPagination, onOrder, facets, onFacets, filters } = useCollection();
  const { data, isLoading } = useSearchInventoriesCheckout({
    inventory_location_id: location?.id || null,
    term: search || "*",
    aggs: ["category"],
    pagination,
    order,
    filters: {
      ...filters,
      is_archived: false,
      is_consumable: true,
      status: ["in_stock", "low_stock"],
    },
  });

  const [selectedInventory, setSelectedInventory] = useState<CheckedOutInventory[]>([]);

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);
  const [pathToLocation, setPathToLocation] = useState<LocationTreeKeyValue[] | undefined>();

  useEffect(() => {
    const data = treeSelectInventoryLocationsQuery?.data;
    if (location && data) {
      const path = findSelectedOption(data, location);
      setPathToLocation(initial(path));
      setShowPreview(true);
    }
  }, [treeSelectInventoryLocationsQuery?.data, location]);

  const handleCheckoutInventory = (item: InventoryCheckoutData, value: number) => {
    const itemInSelectedInventory = selectedInventory.find(
      (i) => i.id === item.id && i.inventory_location?.id === location?.id
    );

    if (itemInSelectedInventory) {
      if (value === 0) {
        setSelectedInventory((prev) =>
          prev.filter((i) => !(i.id === item.id && i.inventory_location?.id === location?.id))
        );
      } else
        setSelectedInventory((prev) => {
          return prev.map((i) => {
            if (i.id === item.id && i.inventory_location?.id === location?.id) {
              return {
                ...i,
                amount_checked_out: value,
              };
            }
            return i;
          });
        });
    } else {
      if (value > 0) {
        setSelectedInventory([
          ...selectedInventory,
          {
            ...item,
            amount_checked_out: value,
            inventory_location: { id: location!.id, name: location!.name },
          },
        ]);
      }
    }
  };

  const checkoutTotal = useMemo(
    () =>
      Money.sum(
        Money.zero(currency),
        ...selectedInventory.map(
          (item) =>
            calculateCostAndItemsUsed(item.amount_checked_out, item.inventory_items, currency)
              .totalCost
        )
      ),
    [selectedInventory]
  );

  const columns = [
    {
      label: "Item Name",
      render: (item: InventoryCheckoutData) => (
        <RecordLink identifier={item.name} link={`/inventory/items/${item.id}`} />
      ),
    },
    {
      label: "Category",
      render: (item: InventoryCheckoutData) => titleize(humanize(item.category)),
    },
    {
      label: "Item ID/SKU",
      render: (item: InventoryCheckoutData) => item.sku || "N/A",
    },
    {
      label: "Stock",
      render: (item: InventoryCheckoutData) =>
        `${item.total_quantity_stock} ${item.unit_of_measurement || "units"}`,
    },
    {
      label: "Status",
      render: (inventory: InventoryCheckoutData) => (
        <Tag
          colorScheme={inventoryStatusColor(String(inventory.status))}
          flexShrink={0}
          whiteSpace="nowrap"
          size={["sm", "md"]}>
          {titleize(humanize(String(inventory.status)))}
        </Tag>
      ),
    },
    {
      label: "",
      render: (item: InventoryCheckoutData) => {
        const itemInSelectedInventory = selectedInventory.find(
          (i) => i.id === item.id && i.inventory_location?.id === location?.id
        );

        return (
          <Editable
            w={20}
            value={itemInSelectedInventory?.amount_checked_out.toString() || "0"}
            defaultValue={"0"}
            isDisabled={item.total_quantity_stock === 0}>
            <HStack spacing={2}>
              <IconButton
                size="xs"
                borderRadius="full"
                aria-label="decrement"
                icon={<MinusIcon />}
                isDisabled={(itemInSelectedInventory?.amount_checked_out || 0) <= 0}
                onClick={() =>
                  handleCheckoutInventory(
                    item,
                    (itemInSelectedInventory?.amount_checked_out || 0) - 1
                  )
                }
              />
              <EditablePreview />
              <Box>
                <EditableInput
                  minW={10}
                  maxW={24}
                  type="number"
                  max={item.total_quantity_stock}
                  onInput={(e) => {
                    const value = e.currentTarget.value;
                    if (
                      value &&
                      !isNaN(Number(value)) &&
                      Number(value) <= item.total_quantity_stock
                    ) {
                      handleCheckoutInventory(item, Number(value));
                    } else if (value === "") {
                      // Allow empty value temporarily
                      handleCheckoutInventory(item, 0);
                    }
                  }}
                  onBlur={(e) => {
                    const value = e.target.value;
                    if (value === "" || isNaN(Number(value)) || Number(value) <= 0) {
                      e.target.value = "0";
                    }
                  }}
                />
              </Box>
              <IconButton
                isDisabled={
                  (itemInSelectedInventory?.amount_checked_out || 0) >= item.total_quantity_stock
                }
                size="xs"
                borderRadius="full"
                aria-label="decrement"
                icon={<AddIcon />}
                onClick={() =>
                  handleCheckoutInventory(
                    item,
                    (itemInSelectedInventory?.amount_checked_out || 0) + 1
                  )
                }
              />
            </HStack>
          </Editable>
        );
      },
    },
  ];

  return (
    <Flex direction="column" gap={4}>
      <HStack align="end">
        <Header
          title="Checkout Inventory"
          crumbs={[{ label: "Inventory", url: "/inventory/items" }]}
          crumbsColor="teal"
          actions={[
            showPreview ? (
              <Stack
                onClick={() => setShowPreview(false)}
                direction="row"
                flexWrap="wrap"
                px={4}
                py={2}
                border="1px"
                borderRadius="md"
                borderColor="chakra-border-color">
                {pathToLocation?.map((location, idx) => {
                  const isLast = idx === pathToLocation?.length - 1;
                  return (
                    <Box key={`destination-${location.label}-${location.value?.id}`}>
                      <HStack>
                        <Text
                          color={isLast ? "teal.500" : "auto"}
                          fontWeight={isLast ? "semibold" : "normal"}>
                          {location.label}
                        </Text>
                        {idx < (pathToLocation?.length || 1) - 1 && <ArrowForwardIcon />}
                      </HStack>
                    </Box>
                  );
                })}
              </Stack>
            ) : (
              <Box>
                <TreeSelect
                  options={treeSelectInventoryLocationsQuery.data || []}
                  placeholder="Select Location"
                  isLoading={treeSelectInventoryLocationsQuery.isLoading}
                  onSearchChanged={(value) => {
                    setLocationSearch(value);
                  }}
                  defaultValue={location}
                  onChange={(value) => {
                    if (value) {
                      setLocation(value);
                    }
                  }}
                  renderOption={(option) => defaultOption(option)}
                />
              </Box>
            ),
            <Box alignSelf="end" display={{ base: "flex", xl: "none" }}>
              <Facets
                variant="button"
                debouncedSearch={debouncedSearch}
                background
                search
                facets={populatedFacets}
                onChange={onFacets}
              />
            </Box>,
          ]}
        />
      </HStack>
      <Stack direction={{ base: "column", lg: "row" }} gap={4} overflow="auto">
        <Box flex="3" overflow="auto">
          {location ? (
            <Stack direction="row">
              <Box display={{ base: "none", xl: "block" }}>
                <Facets
                  debouncedSearch={debouncedSearch}
                  background
                  search
                  facets={populatedFacets}
                  onChange={onFacets}
                />
              </Box>
              <Box>
                <Collection
                  items={data?.results || []}
                  columns={columns}
                  isLoading={isLoading}
                  pagination={data?.pagination || pagination}
                  onPagination={onPagination}
                  order={order}
                  onOrder={onOrder}
                />
              </Box>
            </Stack>
          ) : (
            <EmptyState title="Select a location to get started" />
          )}
        </Box>
        <VStack flex="1" w="100%" spacing={4}>
          <Flex
            p={6}
            w="100%"
            direction="column"
            gap={6}
            bg={useColorModeValue("gray.50", "gray.700")}
            borderRadius="md"
            border="1px"
            borderColor="chakra-border-color">
            <HStack justify="space-between">
              <Text fontWeight="semibold">
                {selectedInventory.length} {inflect("item", selectedInventory.length)} selected
              </Text>
              <Button
                variant="ghost"
                colorScheme="red"
                onClick={() => setSelectedInventory([])}
                isDisabled={selectedInventory.length === 0}>
                Clear all
              </Button>
            </HStack>
            <Divider />
            <Flex direction="column" w="100%" gap={6} h={60} overflowY="auto">
              {selectedInventory.length > 0 &&
                selectedInventory.map((item, idx) => (
                  <HStack justify="space-between" key={`${idx}-${item.name}`} align="center">
                    <Flex flexDir="column">
                      <Text fontWeight="medium">
                        {item.name}{" "}
                        <Box as="i" opacity="0.7">
                          ({item.sku || "N/A"})
                        </Box>{" "}
                      </Text>
                      <Text color={useColorModeValue("gray.500", "auto")}>
                        {item.inventory_location?.name}
                      </Text>
                      <Text color={useColorModeValue("gray.500", "auto")}>
                        {item.amount_checked_out} {item.unit_of_measurement || "units"}
                      </Text>
                      <Text
                        color="red.500"
                        fontWeight="semibold"
                        _hover={{ cursor: "pointer" }}
                        onClick={() => handleCheckoutInventory(item, 0)}>
                        Remove
                      </Text>
                    </Flex>
                    <MoneyText
                      as="span"
                      fontWeight="semibold"
                      formatOptions={{ compact: "never" }}
                      money={
                        calculateCostAndItemsUsed(
                          item.amount_checked_out,
                          item.inventory_items,
                          currency
                        ).totalCost
                      }
                    />
                  </HStack>
                ))}
            </Flex>
            {selectedInventory.length > 0 && <Divider />}
            <HStack justify="space-between">
              <Text fontWeight="semibold">Total</Text>
              <MoneyText
                as="span"
                fontSize="2xl"
                fontWeight="bold"
                money={checkoutTotal}
                formatOptions={{ compact: "never" }}
              />
            </HStack>
          </Flex>
          <CheckoutModal selectedInventory={selectedInventory} sum={checkoutTotal} />
        </VStack>
      </Stack>
    </Flex>
  );
};

const defaultOption = (option: LocationTreeKeyValue) => {
  const fontWeight = option.value?.storage_item ? "semibold" : "normal";
  return (
    <HStack>
      <Text fontWeight={fontWeight}>{option.label}</Text>
      {option?.value?.capacity_for_storage && <Tag colorScheme="teal">In Stock</Tag>}
    </HStack>
  );
};
