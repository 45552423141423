import {
  Badge,
  Heading,
  VStack,
  LinkBox,
  LinkOverlay,
  useColorModeValue,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Tag,
  useDisclosure,
  HStack,
  Input,
  Icon,
  InputGroup,
  InputLeftElement,
  PopoverCloseButton,
  PopoverBody,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import React from "react";
import { BsLayoutTextWindowReverse } from "react-icons/bs";
import { humanize, titleize } from "inflection";
import { inventoryStatusColor } from "../../util";
import { SearchIcon } from "@chakra-ui/icons";

export const InventoryPopover = ({
  inventoryOptions,
  navigate,
  selectedItemId,
  debouncedSearch,
}) => {
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const selectedBg = useColorModeValue("gray.100", "gray.600");

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <IconButton
          icon={<BsLayoutTextWindowReverse />}
          colorScheme="teal"
          aria-label={"other-items"}
        />
      </PopoverTrigger>
      <PopoverContent p={4} zIndex={9999}>
        <PopoverBody>
          <VStack width="100%" align="start" spacing={4} py={2}>
            <HStack w="100%" justify="space-between" align="center">
              <Heading width={"100%"} size="md" fontWeight="semibold">
                Other Inventory Items
              </Heading>
            </HStack>
            <HStack w="100%" justify="space-between" align="center">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={SearchIcon} color="gray.300" />}
                />
                <Input
                  placeholder="Search..."
                  onChange={(e) => debouncedSearch(e.target.value)}
                  background={useColorModeValue("white", "gray.700")}
                />
              </InputGroup>
            </HStack>
            <VStack width="100%" align="start" height="25vh" overflow={"auto"}>
              {inventoryOptions?.results.map((item) => (
                <LinkBox key={item.id} width="100%" _hover={{ bg: hoverBg, borderRadius: "md" }}>
                  <LinkOverlay
                    as={RouterLink}
                    to={`/inventory/items/${item.id}`}
                    onClick={() => navigate(item.id)}>
                    <VStack
                      align="start"
                      p={4}
                      borderRadius={item.id === selectedItemId ? "md" : ""}
                      borderBottom={item.id === selectedItemId ? "none" : "1px"}
                      borderColor="chakra-border-color"
                      bg={item.id === selectedItemId ? selectedBg : ""}>
                      <Heading size="sm" key={item.id}>
                        {item.name}
                      </Heading>
                      <Tag
                        variant="subtle"
                        colorScheme={
                          item.is_archived ? "red" : inventoryStatusColor(item.status || "")
                        }
                        mt={1}>
                        {item.is_archived ? "" : titleize(humanize(item.status || ""))}
                      </Tag>
                    </VStack>
                  </LinkOverlay>
                </LinkBox>
              ))}
            </VStack>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
