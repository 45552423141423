import React from "react";
import {
  Box,
  Flex,
  Heading,
  VStack,
  HStack,
  AvatarGroup,
  Avatar,
  Text,
  useColorModeValue,
  Tag,
  Badge,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { FiMoreVertical } from "react-icons/fi";
import { TrainingSessionMinimalData } from "../../../api/training_sessions";
import { Link } from "react-router-dom";

export const NextTraining = ({
  trainingSession,
}: {
  trainingSession: TrainingSessionMinimalData;
}) => {
  const cardBg = useColorModeValue("teal.50", "teal.700");
  const cardHoverBg = useColorModeValue("teal.100", "teal.600");

  return (
    <LinkBox
      flex="1"
      bg={cardBg}
      _hover={{ cursor: "pointer", bg: cardHoverBg }}
      borderLeft="4px solid"
      borderColor="teal.400">
      <LinkOverlay as={Link} to={`/trainings/${trainingSession.training.id}`}>
        <Flex
          key={trainingSession.id}
          px={4}
          h="100%"
          align="center"
          justify="space-between"
          boxShadow="sm"
          w="100%"
          borderRadius="base"
          direction={["column", "row"]}>
          <HStack>
            {/* Date Section */}
            <VStack
              align="start"
              pr={4}
              spacing={0}
              fontSize="md"
              fontWeight="medium"
              textColor={useColorModeValue("teal.500", "auto")}>
              <Text>{trainingSession.scheduled_date.toFormat("dd")}</Text>
              <Text
                fontSize="md"
                fontWeight="medium"
                textColor={useColorModeValue("teal.500", "auto")}>
                {trainingSession.scheduled_date.toFormat("LLL")}
              </Text>
            </VStack>
            <VStack align="start" spacing={1}>
              <Tag colorScheme="teal" size="sm" variant="solid">
                Up Next
              </Tag>
              <Text fontWeight="semibold" fontSize="md">
                {trainingSession.training.name}
              </Text>
              <Text fontSize="xs" textColor={useColorModeValue("gray.600", "auto")}>
                {trainingSession.scheduled_date.toFormat("hh:mm a")}
              </Text>
            </VStack>
          </HStack>
          {/* Facilitator */}
          <VStack
            bgColor={useColorModeValue("rgba(178, 245, 234, 0.3)", "rgba(4, 78, 73, 0.3)")}
            p={4}
            spacing={2}
            borderRadius="lg">
            <Avatar
              key={trainingSession.facilitator.name}
              name="Facilitator"
              src={trainingSession.facilitator.picture_uri}
              size={"sm"}
            />
            <Text fontSize="sm" fontWeight="bold" textAlign="center">
              {trainingSession.facilitator.name}
            </Text>
            <Text fontSize="xs" textColor={useColorModeValue("gray.600", "auto")}>
              Facilitator
            </Text>
          </VStack>
        </Flex>
      </LinkOverlay>
    </LinkBox>
  );
};
