import { z } from "zod";
import { api } from ".";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { indexParams, searchParams, SearchParams } from "./collection_types";
import { createSearchResponseSchema } from "./shared";
import { inventoryVendorShowSchema } from "./inventory_vendor";
import {
  INVENTORY_ITEM_BASE_URL,
  inventoryItemCheckoutSchema,
  inventoryItemSchema,
} from "./inventory_item";
import * as _ from "lodash";
import { buildInventoryLocationOptions } from "./inventory_location";
import { dateTimeSchema } from "../helpers/dateTime";
import { userMinimalSchema } from "./user";
import { zodParse } from "./zodParse";
import { PURCHASES_BASE_URL } from "./purchase";
import { blobFileSchema } from "./blob_files";

export const INVENTORY_BASE_URL = "inventories";
export const INVENTORY_USERS_BASE_URL = "inventory_users";

export const inventorySchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  sku: z.string(),
  category: z.string(),
  track_lots: z.boolean(),
  track_expiration_dates: z.boolean(),
  advanced_tracking: z.boolean(),
  unit_of_measurement: z.string().nullable(),
  reorder_threshold: z.number(),
  track_reorder_threshold: z.boolean(),
  is_archived: z.boolean(),
  archived_at: dateTimeSchema.nullable(),
  is_consumable: z.boolean(),
});

export const inventoryMinimalSchema = inventorySchema.pick({
  id: true,
  name: true,
  sku: true,
});

export const inventoryUserSchema = z.object({
  user_ids: z.number(),
  inventory_id: z.number(),
});

export const inventoryUserShowSchema = z.object({
  id: z.number(),
  user: z.lazy(() => userMinimalSchema),
});

export const inventoryIndexSchema = inventorySchema
  .pick({
    id: true,
    name: true,
    sku: true,
    category: true,
    is_archived: true,
    archived_at: true,
    advanced_tracking: true,
    is_consumable: true,
    unit_of_measurement: true,
  })
  .extend({
    total_quantity_stock: z.number(),
    stock: z.number(),
    total_inventory_items: z.number().optional(),
    status: z.string().nullable(),
    users: z.array(inventoryUserShowSchema),
  });

export const inventoryCheckoutSchema = inventoryIndexSchema
  .omit({
    is_archived: true,
    archived_at: true,
    advanced_tracking: true,
    is_consumable: true,
    users: true,
    stock: true,
  })
  .extend({
    inventory_items: z.array(inventoryItemCheckoutSchema),
  });

export const inventoryShowSchema = inventorySchema.extend({
  total_quantity_stock: z.number(),
  vendors: z.array(inventoryVendorShowSchema),
  users: z.array(inventoryUserShowSchema),
  stock: z.number(),
  total_inventory_items: z.number().optional(),
  inventory_item_barcodes: z
    .array(z.object({ id: z.number(), barcode_code: z.string() }))
    .optional(),
  status: z.string().nullable(),
  original_equipment_manufacturer: z.string(),
  manufacturing_part_number: z.string(),
  uploaded_files: z.array(blobFileSchema),
});

export const inventoryUserCreateSchema = z.object({
  user_ids: z.number().array(),
  inventory_id: z.number(),
});

export const inventoryLocationsWithInventorySchema = z.object({
  id: z.number(),
  name: z.string(),
  capacity_for_storage: z.boolean(),
  has_stock: z.boolean(),
  children: z.array(z.lazy(() => inventoryLocationsWithInventorySchema)),
});

export const truncatedInventoryLocationsWithInventorySchema = z.object({
  isTruncated: z.boolean(),
  targetLocation: z.object({ id: z.number(), name: z.string() }),
  id: z.number(),
  name: z.string(),
  inventory_items: z.array(inventoryItemSchema).nullable(),
  children: z.array(z.lazy(() => inventoryLocationsWithInventorySchema)),
});

export type InventoryData = z.infer<typeof inventorySchema>;
export type InventoryIndexData = z.infer<typeof inventoryIndexSchema>;
export type InventoryShowData = z.infer<typeof inventoryShowSchema>;
export type InventoryCheckoutData = z.infer<typeof inventoryCheckoutSchema>;

export type InventoryLocationsWithInventoryData = z.infer<
  typeof inventoryLocationsWithInventorySchema
>;

export type TruncatedInventoryLocationsWithInventoryData = z.infer<
  typeof truncatedInventoryLocationsWithInventorySchema
>;

export type InventoryCreateParams = Omit<
  InventoryData,
  "id" | "description" | "advanced_tracking" | "is_archived" | "archived_at"
>;
export type InventoryUserData = z.infer<typeof inventoryUserSchema>;
export type InventoryUserCreateData = z.infer<typeof inventoryUserCreateSchema>;

// api queries
export const createInventory = async (inventory: InventoryCreateParams) => {
  const result = await api.post(INVENTORY_BASE_URL, { inventory });
  return result.data;
};

export const getInventory = async (id: number | null | undefined) => {
  const result = await api.get(`${INVENTORY_BASE_URL}/${id}`);
  return zodParse(inventoryShowSchema, result.data);
};

export const getInventoryLocationsWithInventory = async (id: number, searchTerm?: string) => {
  const result = await api.get(
    `${INVENTORY_BASE_URL}/${id}/locations`,
    (searchTerm && { params: { search_term: searchTerm } }) || undefined
  );
  return zodParse(z.array(inventoryLocationsWithInventorySchema), result.data);
};

export const getInventories = async () => {
  const result = await api.get(INVENTORY_BASE_URL);
  return zodParse(z.array(inventoryMinimalSchema), result.data);
};

export const searchInventories = async ({
  aggs,
  filters,
  pagination,
  order,
  term,
}: SearchParams) => {
  const path = [INVENTORY_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(createSearchResponseSchema(inventoryIndexSchema), result.data);
};

export const searchInventoryCheckout = async ({
  inventory_location_id,
  aggs,
  filters,
  pagination,
  order,
  term,
}: SearchParams & { inventory_location_id: number | null }) => {
  const path = [INVENTORY_BASE_URL, "search", "checkout"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(
    path.join("/"),
    _.omitBy({ ...index, ...search, inventory_location_id }, _.isNull)
  );
  return zodParse(createSearchResponseSchema(inventoryCheckoutSchema), result.data);
};

export const updateInventory = async (inventory: Partial<InventoryShowData>) => {
  await api.put(`${INVENTORY_BASE_URL}/${inventory.id}`, { inventory });
};

export const deleteInventory = async (id: number) => {
  await api.delete(`${INVENTORY_BASE_URL}/${id}`);
  return id;
};

export const createInventoryUser = async (inventory_user: InventoryUserCreateData) => {
  const result = await api.post(`${INVENTORY_USERS_BASE_URL}`, { inventory_user });
  return result.data;
};

export const updateInventoryUser = async (inventory_user: InventoryUserData) => {
  await api.put(`${INVENTORY_USERS_BASE_URL}/${inventory_user.inventory_id}`, {
    inventory_user,
  });
};

export const deleteInventoryUser = async (inventory_user: { ids: number[] }) => {
  await api.post(`${INVENTORY_USERS_BASE_URL}/delete`, { inventory_user });
};

/** hooks */

export const invalidateInventories = (id?: number, params?) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [INVENTORY_BASE_URL];
  if (id) queryKey.push(id);
  if (params) queryKey.push(params);
  return async () => {
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
    queryClient.invalidateQueries({
      queryKey: [INVENTORY_ITEM_BASE_URL],
    });
    queryClient.invalidateQueries({
      queryKey: [PURCHASES_BASE_URL],
    });
  };
};

export const useGetInventories = () => {
  return useQuery({
    queryKey: [INVENTORY_BASE_URL],
    queryFn: () => getInventories(),
  });
};

export const useGetInventory = (id: number | null | undefined) => {
  return useQuery({
    queryKey: [INVENTORY_BASE_URL, id],
    queryFn: () => getInventory(id),
    enabled: !!id,
  });
};

export const useSearchInventories = (params: SearchParams) => {
  return useQuery({
    queryKey: [INVENTORY_BASE_URL, params],
    queryFn: () => searchInventories(params),
  });
};

export const useSearchInventoriesCheckout = (
  params: SearchParams & { inventory_location_id: number | null }
) => {
  return useQuery({
    queryKey: [INVENTORY_BASE_URL, "checkout", params],
    queryFn: () => searchInventoryCheckout(params),
  });
};

export const useCreateInventory = () => {
  return useMutation({
    mutationFn: createInventory,
    onSuccess: invalidateInventories(),
  });
};

export const useUpdateInventory = () => {
  return useMutation({
    mutationFn: updateInventory,
    onSuccess: invalidateInventories(),
  });
};

export const useDeleteInventory = () => {
  return useMutation({
    mutationFn: deleteInventory,
    onSuccess: invalidateInventories(),
  });
};

export const useCreateInventoryUser = (inventoryId: number) => {
  return useMutation({
    mutationFn: createInventoryUser,
    onSuccess: invalidateInventories(inventoryId),
  });
};

export const useDeleteInventoryUser = (inventoryId: number) => {
  return useMutation({
    mutationFn: deleteInventoryUser,
    onSuccess: invalidateInventories(inventoryId),
  });
};

export const useInventoryLocationsWithInventoryMap = (inventoryId: number, searchTerm?: string) => {
  return useQuery({
    queryKey: [INVENTORY_BASE_URL, inventoryId, searchTerm, "map"],
    queryFn: async () => {
      const result = await getInventoryLocationsWithInventory(inventoryId, searchTerm);
      const truncatedData = processDataForDisplay(result);
      return {
        tree: result,
        arrowData: processLocations(truncatedData),
      };
    },
  });
};

const processDataForDisplay = (
  data: InventoryLocationsWithInventoryData[]
): (InventoryLocationsWithInventoryData | TruncatedInventoryLocationsWithInventoryData)[] => {
  const truncateHierarchy = (
    location: InventoryLocationsWithInventoryData,
    depth = 0,
    path: InventoryLocationsWithInventoryData[] = []
  ) => {
    path.push(location);

    if (depth === 2) {
      let totalDepth = calculateDepth(location);
      if (totalDepth + depth > 4) {
        const lastTwoLevels = getDeepLastTwoLevels(location);
        const secondToLastLevel = lastTwoLevels[0];
        const lastLevel = lastTwoLevels[1];
        return {
          isTruncated: true,
          id: location.id,
          inventory_items: location.inventory_items,
          targetLocation: lastLevel, // used to find path in the component
          name: `${path.length} more`,
          children: [secondToLastLevel], // 2nd to last child will have last location as a child already
        };
      }
    }

    return {
      ...location,
      children: location.children.map((child) => truncateHierarchy(child, depth + 1, path.slice())),
    };
  };

  const calculateDepth = (location) => {
    if (!location.children || location.children.length === 0) {
      return 1;
    }
    return 1 + Math.max(...location.children.map(calculateDepth));
  };

  // Function to fetch the deepest last two levels
  const getDeepLastTwoLevels = (location) => {
    let current = location;
    let stack: InventoryLocationsWithInventoryData[] = [];
    while (current.children && current.children.length > 0) {
      stack.push(current);
      current = current.children[current.children.length - 1];
    }
    stack.push(current);
    return stack.slice(-2);
  };

  return data.map((location) => truncateHierarchy(location));
};

export type InventoryLocationsWithArrowData = InventoryLocationsWithInventoryData & {
  isTruncated?: boolean;
  targetLocation?: { id: number; name: string };
  arrowInfo: {
    targetId: string;
    sourceAnchor: string;
    targetAnchor: string;
    style: { [key: string]: string };
  }[];
};

const processLocations = (
  data: (InventoryLocationsWithInventoryData | TruncatedInventoryLocationsWithInventoryData)[]
): InventoryLocationsWithArrowData[] => {
  const processedData: InventoryLocationsWithArrowData[] = data.map((location) => {
    if (!location["arrowInfo"]) {
      location["arrowInfo"] = [];
    }

    if (location.children.length > 0) {
      location.arrowInfo.push({
        targetId: `location${location.children[0].id}`,
        sourceAnchor: "right",
        targetAnchor: "left",
        style: { strokeColor: "gray", strokeWidth: 1, strokeDasharray: "4,4" },
      });

      for (let i = 0; i < location.children.length - 1; i++) {
        if (!location.children[i].arrowInfo) {
          location.children[i].arrowInfo = [];
        }

        location.children[i].arrowInfo.push({
          targetId: `location${location.children[i + 1].id}`,
          sourceAnchor: "left",
          targetAnchor: "left",
          style: { strokeColor: "gray", strokeWidth: 1, strokeDasharray: "5,5" },
        });
      }

      processLocations(location.children);
    }
    return location;
  });
  return processedData;
};

export const useInventoryLocationsWithInventoryOptions = (
  inventoryId: number,
  searchTerm?: string
) => {
  return useQuery({
    queryKey: [INVENTORY_BASE_URL, inventoryId, searchTerm, "option"],
    queryFn: async () => {
      const result = await getInventoryLocationsWithInventory(inventoryId, searchTerm);
      return result.map((location) =>
        buildInventoryLocationOptions(searchTerm, location, "Remove stock")
      );
    },
  });
};
