import React, { useState, useMemo } from "react";

import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
  useDisclosure,
  IconButton,
  Textarea,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { SplitPage, Header, ConfirmationButton } from "@sciencecorp/helix-components";
import { EditIcon } from "@chakra-ui/icons";
import { FiArchive } from "react-icons/fi";
import { RiCheckFill, RiCloseFill, RiEdit2Line } from "react-icons/ri";
import { useGetCredential, useUpdateCredential, useSearchCredentials } from "../../api/credentials";
import { useCurrentUserQuery, userHasRole } from "../../api/user";
import { NewCredentialModal } from "./components/NewCredentialModal";
import { UserCredentialTable } from "./components/UserCredentialTable";

import { useDebouncedSearch } from "../hooks/useDebouncedSearch";
import { credentialBadge, SidebarList, TrainingLink } from "./util";
import { useParams } from "react-router";
import { CiUndo } from "react-icons/ci";

export const CredentialPage = () => {
  const { id } = useParams();
  if (!id) return null;

  const { search, debouncedSearch } = useDebouncedSearch();
  const { data: credential } = useGetCredential(+id);
  const { mutate: updateCredential } = useUpdateCredential();
  const { data: credentialsSearch } = useSearchCredentials({
    term: search || "*",
    pagination: { per_page: 10 },
  });
  const currentUser = useCurrentUserQuery();
  const isCredentialsAdmin = userHasRole(currentUser, "credentials_admin");

  const [editDescription, setEditDescription] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");

  const {
    isOpen: isOpenNewCredential,
    onOpen: onOpenNewCredential,
    onClose: onCloseNewCredential,
  } = useDisclosure();

  const actions = !credential?.is_archived
    ? ([
        credential?.is_draft && (
          <ConfirmationButton
            label="Publish"
            variant="Button"
            confirmationButtonLabel="Yes"
            colorScheme="teal"
            children="Publishing this credential will make it active and visible to all employees. Are you sure you want to proceed?"
            confirmationHeader="Archive Credential"
            onConfirm={() => updateCredential({ id: +id, is_draft: false })}
          />
        ),
        <ConfirmationButton
          buttonVariant="outline"
          leftIcon={<FiArchive />}
          label="Archive"
          variant="Button"
          confirmationButtonLabel="Yes"
          colorScheme="red"
          children="Archiving the credential will remove it from the current list, but you can retrieve it by going to All Credentials >> Archive."
          confirmationHeader="Archive Credential"
          onConfirm={() => updateCredential({ id: +id, is_archived: true })}
        />,
        <Button leftIcon={<EditIcon />} onClick={() => onOpenNewCredential()}>
          Edit
        </Button>,
      ].filter(Boolean) as JSX.Element[])
    : [
        <ConfirmationButton
          leftIcon={<CiUndo />}
          label="Restore"
          variant="Button"
          confirmationButtonLabel="Continue"
          colorScheme="teal"
          confirmationHeader="Restore Credential"
          children="Restoring this credential will place it back on the credentials list and reassign it to all previously linked employees."
          onConfirm={() => {
            updateCredential({ id: +id, is_archived: false });
          }}
        />,
      ];

  return isCredentialsAdmin ? (
    <>
      {credential?.has_training && !credential?.trainings.length && (
        <Alert status="warning" mb={4}>
          <AlertIcon />
          <VStack align="start" spacing={0}>
            <AlertTitle>Training Required</AlertTitle>
            <AlertDescription>
              This credential requires training, but no training has been linked to it yet
            </AlertDescription>
          </VStack>
        </Alert>
      )}
      {credential?.is_archived && (
        <>
          <Alert status="warning" width="100%" mb={4}>
            <AlertIcon />
            <VStack align="start" spacing={0}>
              <AlertTitle>Archived</AlertTitle>
              <AlertDescription>This credential is in the credential archive. </AlertDescription>
            </VStack>
          </Alert>
        </>
      )}
      <SplitPage
        sidebar={
          !credential?.is_archived ? (
            <SidebarList
              data={credentialsSearch?.results || []}
              title="Other Credentials"
              breadcrumbTitle="Back to All Credentials"
              debouncedSearch={debouncedSearch}
              url="/credentials/all"
              selectedItemId={+id}
            />
          ) : null
        }
        sidebarWidth="350px"
        sidebarWidthXL="400px"
        breakpoint="md"
        main={
          <>
            <VStack width="100%" align="start" spacing={4} maxW="4xl">
              {credential && (
                <Flex direction="column" width="100%" pb={0}>
                  <Header
                    title={credential.name}
                    badge={credentialBadge(credential)}
                    actions={actions}
                  />
                </Flex>
              )}
              <HStack justify="space-between" width="100%" mb={2}>
                <HStack spacing={6}>
                  <Text fontSize="sm">
                    Expires:{" "}
                    <Box as="span" fontWeight="medium">
                      {credential?.expiration_months
                        ? `${credential?.expiration_months} Months`
                        : "N/A"}
                    </Box>
                  </Text>

                  <Text fontSize="sm">
                    Visibility:{" "}
                    <Box as="span" fontWeight="medium">
                      {credential?.is_public ? "Public" : "Private"}
                    </Box>
                  </Text>
                </HStack>
              </HStack>
              {credential?.trainings && credential?.trainings.length > 0 && (
                <TrainingLink trainings={credential?.trainings} />
              )}
              {isCredentialsAdmin && credential && <UserCredentialTable credential={credential} />}
            </VStack>
            {credential && (
              <NewCredentialModal
                isOpen={isOpenNewCredential}
                onClose={onCloseNewCredential}
                credential={credential}
              />
            )}
          </>
        }
      />
    </>
  ) : (
    <Heading>Not Authorized</Heading>
  );
};
