import React, { useState } from "react";
import {
  Box,
  HStack,
  Heading,
  Button,
  Grid,
  useColorModeValue,
  useDisclosure,
  Spinner,
  Stack,
  useMediaQuery,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import CredentialPackageCard from "./CredentialPackageCard";
import { buildFacets, EmptyState, Facets, useCollection } from "@sciencecorp/helix-components";
import { NewCredentialPackageModal } from "./NewCredentialPackageModal";
import { useSearchCredentialPackages } from "../../../../api/credential_packages";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";

export const CredentialPackagesGrid: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { search, debouncedSearch } = useDebouncedSearch();
  const { filters, facets, onFacets, order } = useCollection();

  const { data, isLoading } = useSearchCredentialPackages({
    term: search || "*",
    pagination: { per_page: -1 },
    order,
    filters,
  });
  const [isSmallScreen] = useMediaQuery("(max-width: 767px)");

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  return (
    <Box
      border="1px"
      p={5}
      borderRadius="md"
      borderColor={useColorModeValue("gray.200", "gray.600")}
      width="100%">
      <HStack justify="space-between" mb={4}>
        <Heading size="md">Credential Packages</Heading>
        <Stack spacing={4} direction={{ base: "column-reverse", md: "row" }}>
          <Facets variant="button" onChange={onFacets} facets={populatedFacets} />
          <Button onClick={onOpen} leftIcon={<AddIcon />} colorScheme="teal">
            {isSmallScreen ? "New" : "Create New Package"}
          </Button>
        </Stack>
      </HStack>

      {isLoading ? (
        <Spinner />
      ) : (data?.results.length || 0) > 0 ? (
        <Grid templateColumns="repeat(auto-fit, minmax(var(--chakra-space-64), 1fr))" gap={6}>
          {data?.results.map((pkg) => (
            <CredentialPackageCard key={pkg.id} credentialPackage={pkg} />
          ))}
        </Grid>
      ) : (
        <EmptyState title="No credential packages" size="2xs" />
      )}
      <NewCredentialPackageModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};
