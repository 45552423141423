import React, { useEffect, useMemo, useState } from "react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import {
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  Input,
  Stack,
  useDisclosure,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  CredentialPackageShowData,
  useAssociateCredentialPackageCredential,
} from "../../../../api/credential_packages";
import { AddIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import { IoMedalOutline } from "react-icons/io5";

import { useCredentialsOptions } from "../../../../api/options";

type NewCredentialPackageCredentialProps = {
  credentialPackage: CredentialPackageShowData;
  location?: string;
};

export const AddCredentialPackageCredential = ({
  credentialPackage,
  location,
}: NewCredentialPackageCredentialProps) => {
  const { mutate: associateCredentialsToCredentialPackage, isLoading } =
    useAssociateCredentialPackageCredential();

  const credentialsFromPackage = useMemo(
    () => credentialPackage.credential_package_credentials.map((c) => c.credential),
    [credentialPackage]
  );
  const [credentials, setCredentials] = useState<{ id: number; name: string }[]>([]);

  useEffect(() => {
    setCredentials(credentialsFromPackage);
  }, [credentialPackage]);

  const credentialOptions = useCredentialsOptions(credentials.map((c) => c.id));
  const toast = useToast();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const onSubmit = () => {
    if (credentials.length > 0) {
      associateCredentialsToCredentialPackage(
        {
          credential_package_id: credentialPackage.id,
          credential_ids: credentials.map((c) => c.id),
        },
        {
          onSuccess: () => {
            toast({
              title: "Credentials added to package.",
              status: "success",
              duration: 5000,
            });
            onClose();
          },
          onError: (error) => {
            toast({
              title: "Error adding credentials to package.",
              description: error instanceof Error ? error.message : "An error occurred",
              status: "error",
              duration: 5000,
            });
          },
        }
      );
    }
  };

  const handleClose = () => {
    setCredentials(credentialsFromPackage);
    onClose();
  };

  return (
    <>
      <IconButton
        onClick={onOpen}
        aria-label="Add Credential"
        icon={location === "header" ? <EditIcon /> : <AddIcon />}
        size="sm"
        colorScheme="gray"
      />
      <FormModal
        title="Add Associated Credentials"
        submitButtonColorSchema="teal"
        submitButtonTitle="Submit"
        size="lg"
        isOpen={isOpen}
        submitButtonDisabled={credentials.length === 0}
        isLoading={isLoading}
        onClose={handleClose}
        handleSubmit={onSubmit}>
        <Flex direction="column" width="100%" gap={4}>
          <Stack direction="row" spacing={3} wrap="wrap" w="100%">
            {credentials.map((c) => (
              <HStack
                w="max-content"
                p={2}
                borderRadius="md"
                key={c.id}
                bg={useColorModeValue("gray.100", "gray.600")}>
                <Icon as={IoMedalOutline} />
                <Text fontSize="sm">{c.name}</Text>
                <IconButton
                  size="xs"
                  variant="ghost"
                  aria-label="Remove Credential"
                  icon={<CloseIcon />}
                  onClick={() =>
                    setCredentials((prev) => prev.filter((credential) => credential.id !== c.id))
                  }
                />
              </HStack>
            ))}
          </Stack>
          <Select
            placeholder="Select a credential"
            options={credentialOptions}
            onChange={(value) =>
              setCredentials((prev) => {
                const newCredential = credentialOptions.find((c) => c.value === value);
                if (newCredential) {
                  return [...prev, { id: Number(newCredential.value), name: newCredential.label }];
                } else return prev;
              })
            }
          />
        </Flex>
      </FormModal>
    </>
  );
};
