import React from "react";
import {
  Avatar,
  Flex,
  HStack,
  Text,
  VStack,
  useColorModeValue,
  Box,
  Icon,
  IconButton,
  Heading,
  List,
  ListItem,
  ListIcon,
  Divider,
  LinkBox,
  LinkOverlay,
  Breadcrumb,
  BreadcrumbLink,
  Input,
  Tag,
  Stack,
  InputGroup,
  InputLeftElement,
  Badge,
} from "@chakra-ui/react";
import { RecordLink } from "@sciencecorp/helix-components";
import { UserMinimalData, useUserQuery } from "../../api/user";
import { TbPresentationAnalytics } from "react-icons/tb";
import { IoMedalOutline } from "react-icons/io5";
import { CloseIcon, InfoIcon, SearchIcon } from "@chakra-ui/icons";
import { RiAsterisk } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import { DateTime } from "luxon";
import { humanize, titleize } from "inflection";

export const attendanceHistoryColorScheme = (status: string) => {
  switch (status) {
    case "expired" || "skipped":
      return "red";
    case "completed":
      return "green";
    case "registered":
      return "blue";
    case "attended":
      return "orange";
    case "pending":
      return "blue";
    case "assigned":
      return "cyan";
    default:
      return "gray";
  }
};

export const userCredentialStatusMap = {
  active: "green",
  expiring_soon: "yellow",
  expired: "red",
  unfulfilled: "gray",
};

export const credentialColorScheme = (status: string) => {
  switch (status) {
    case "active":
      return {
        background: useColorModeValue("gray.50", "gray.700"),
        hover: useColorModeValue("gray.100", "gray.600"),
        bar: "teal",
        icon: useColorModeValue("gray.400", "gray.200"),
        text: useColorModeValue("gray.700", "gray.300"),
      };
    case "expiring_soon":
      return {
        background: useColorModeValue("orange.50", "orange.900"),
        hover: useColorModeValue("orange.100", "orange.800"),
        bar: "orange",
        icon: useColorModeValue("orange.400", "orange.200"),
        text: useColorModeValue("orange.700", "auto"),
      };
    case "expired":
      return {
        background: useColorModeValue("red.50", "red.900"),
        hover: useColorModeValue("red.100", "red.800"),
        bar: "red",
        icon: useColorModeValue("red.400", "red.200"),
        text: useColorModeValue("red.700", "auto"),
      };
    default:
      return {
        background: useColorModeValue("gray.50", "gray.700"),
        hover: useColorModeValue("gray.100", "gray.600"),
        bar: "gray.300",
        icon: useColorModeValue("gray.400", "gray.200"),
        text: useColorModeValue("gray.700", "gray.300"),
      };
  }
};

export const FacilitatorCard = ({ facilitator }: { facilitator: UserMinimalData }) => {
  return (
    <HStack>
      <Avatar size="sm" src={facilitator.picture_uri} aria-label="facilitator" />
      <VStack align="start">
        <Text fontSize="sm" fontWeight="semibold">
          {facilitator.name}
        </Text>
      </VStack>
    </HStack>
  );
};

export const TrainingLink = ({
  trainings,
  status,
}: {
  trainings: { id: number; name: string; status?: string; expiration_date?: string | null }[];
  status?: string;
}) => {
  if (!trainings.length)
    return (
      <HStack
        width="100%"
        justify="space-between"
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md"
        p={4}>
        <HStack>
          <Flex bg={useColorModeValue("green.100", "green.600")} p={2} borderRadius="md">
            <Icon
              as={IoMedalOutline}
              boxSize={5}
              color={useColorModeValue("green.700", "green.200")}
            />
          </Flex>
          <Text fontSize="sm">
            This credential requires training, but no training has been linked to it yet
          </Text>
        </HStack>
      </HStack>
    );
  return (
    <VStack
      width="100%"
      align="start"
      border="1px"
      borderColor="chakra-border-color"
      borderRadius="md"
      spacing={4}
      p={4}>
      <Stack direction={{ base: "column", md: "row" }} width="100%" justify="space-between">
        <HStack>
          <Flex bg={useColorModeValue("green.100", "green.600")} p={2} borderRadius="md">
            <Icon
              as={IoMedalOutline}
              boxSize={5}
              color={useColorModeValue("green.700", "green.200")}
            />
          </Flex>
          {trainings.length === 1 ? (
            <Text fontSize="sm" textColor={useColorModeValue("gray.700", "gray.300")}>
              This credential is gained by completing a training at Science:
            </Text>
          ) : (
            <Text fontSize="sm" textColor={useColorModeValue("gray.700", "gray.300")}>
              This credential is gained by completing the following trainings at Science:
            </Text>
          )}
        </HStack>
        {trainings.length === 1 && (
          <RecordLink
            type=""
            identifier={trainings[0].name}
            link={`/trainings/${trainings[0].id}`}
            icon={
              <Box ml={3} mt={1}>
                <Icon as={TbPresentationAnalytics} boxSize={4} />
              </Box>
            }
          />
        )}
      </Stack>
      {trainings.length > 1 && (
        <>
          <Divider />

          {trainings.map((training) => (
            <HStack justify="space-between" width="100%">
              <HStack>
                <RecordLink
                  type=""
                  identifier={training.name}
                  link={`/trainings/${training.id}`}
                  icon={
                    <Box ml={3} mt={1}>
                      <Icon as={TbPresentationAnalytics} boxSize={4} />
                    </Box>
                  }
                />
                {training.expiration_date && (
                  <Text>
                    {DateTime.fromISO(training.expiration_date).toRelative({
                      base: DateTime.now(),
                      style: "long",
                    })}
                  </Text>
                )}
              </HStack>
              {training.status && (
                <Tag colorScheme={attendanceHistoryColorScheme(training.status) || ""}>
                  {titleize(humanize(training.status))}
                </Tag>
              )}
            </HStack>
          ))}
        </>
      )}
      {status === "unfulfilled" && (
        <>
          <Divider />
          <VStack
            width="100%"
            align="start"
            p={6}
            bg={useColorModeValue("gray.50", "gray.700")}
            borderRadius="base">
            <HStack>
              <InfoIcon />
              <Heading size="sm">What You Should Know</Heading>
            </HStack>
            <List spacing={2}>
              <ListItem fontSize="sm">
                <ListIcon as={RiAsterisk} color={useColorModeValue("gray.500", "gray.300")} />
                This credential was assigned to you by virtue of your role.
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={RiAsterisk} color={useColorModeValue("gray.500", "gray.300")} />
                You should complete the above mentioned training to gain the credential.
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={RiAsterisk} color={useColorModeValue("gray.500", "gray.300")} />
                You will be assigned to the next session of the training.
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={RiAsterisk} color={useColorModeValue("gray.500", "gray.300")} />
                Please, look out for the training invite in your Inbox and be sure to attend.
              </ListItem>
            </List>
          </VStack>
        </>
      )}
    </VStack>
  );
};

export const UserCredentialCard = ({ userId, setUserIds }) => {
  const { data: user } = useUserQuery(userId);

  return (
    <HStack justify="space-between">
      <HStack>
        <Avatar size="sm" src={user?.picture_uri} aria-label="user" />
        <Text fontSize="sm" fontWeight="semibold">
          {user?.name}
        </Text>
      </HStack>

      <IconButton
        onClick={() => setUserIds((prev) => prev.filter((id: number) => id !== userId))}
        aria-label="remove user"
        icon={<CloseIcon />}
        size="xs"
        variant="ghost"
        colorScheme="red"
      />
    </HStack>
  );
};

type SidebarListProps = {
  title: string | JSX.Element;
  data: any[];
  url: string;
  breadcrumbTitle?: string;
  breadcrumbUrl?: string;
  debouncedSearch: (value: string) => void;
  selectedItemId: number;
};

export const SidebarList = ({
  title,
  data,
  url,
  breadcrumbTitle,
  breadcrumbUrl = url,
  debouncedSearch,
  selectedItemId,
}: SidebarListProps) => {
  const hoverBg = useColorModeValue("gray.100", "gray.700");
  const selectedBg = useColorModeValue("gray.200", "gray.600");
  return (
    <VStack
      width="100%"
      align="start"
      spacing={4}
      py={6}
      display={{ base: "none", lg: "flex" }}
      px={4}
      bgColor={useColorModeValue("gray.50", "gray.700")}
      border="1px"
      borderColor="chakra-border-color"
      borderRadius="md">
      <Breadcrumb>
        <BreadcrumbLink as={RouterLink} to={breadcrumbUrl} color="teal.500">
          ← {breadcrumbTitle}
        </BreadcrumbLink>
      </Breadcrumb>
      <Heading width={"100%"} size="md">
        {title}
      </Heading>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
        <Input
          placeholder="Search..."
          onChange={(e) => debouncedSearch(e.target.value)}
          background={useColorModeValue("white", "gray.700")}
        />
      </InputGroup>
      <VStack width="100%" align="start" height="75vh" overflow="auto">
        {data.map((item) => (
          <LinkBox key={item.id} width="100%" _hover={{ bg: hoverBg, borderRadius: "md" }}>
            <LinkOverlay as={RouterLink} to={`${url}/${item.id}`}>
              <VStack
                align="start"
                p={4}
                borderRadius={item.id === selectedItemId ? "md" : ""}
                borderBottom={item.id === selectedItemId ? "none" : "1px"}
                borderColor="chakra-border-color"
                bg={item.id === selectedItemId ? selectedBg : ""}>
                <Heading size="sm" key={item.id}>
                  {item.name || item.credential.name}
                </Heading>
                {item.description &&
                  item.description.split("\n").map((line: string, index: number) => (
                    <Text fontSize="sm" noOfLines={1} key={index}>
                      {line}
                    </Text>
                  ))}
                {"approved" in item && (
                  <Badge
                    colorScheme={item.approved ? "green" : "red"}
                    variant="outline"
                    px={2}
                    py={1}
                    borderRadius="md"
                    size="xs"
                    fontWeight="medium"
                    textTransform="none">
                    {item.approved ? "Approved" : "Not Approved"}
                  </Badge>
                )}
                {"status" in item && (
                  <Badge variant="outline" px={2} py={1} borderRadius="md" fontSize="2xs">
                    {titleize(humanize(item.status))}
                  </Badge>
                )}
              </VStack>
            </LinkOverlay>
          </LinkBox>
        ))}
      </VStack>
    </VStack>
  );
};

export const trainingSessionInPast = (trainingDate: DateTime | null) => {
  return DateTime.now().toMillis() > (trainingDate?.toMillis() || 0);
};

export const calendarColors = [
  { border: "orange.400", backgroundLight: "orange.50", backgroundDark: "orange.600" },
  { border: "green.600", backgroundLight: "green.50", backgroundDark: "green.600" },
  { border: "purple.600", backgroundLight: "purple.50", backgroundDark: "purple.600" },
];

export const credentialBodyOptions = {
  expiration_type: {
    filters: {
      filters: {
        "Expiration Date": {
          bool: {
            must: [{ exists: { field: "expiration_months" } }],
          },
        },
      },
    },
  },
};

export const credentialBadge = (credential) => {
  if (credential.is_draft) {
    return {
      label: "Draft",
      colorScheme: "gray",
    };
  } else if (credential.is_archived) {
    return {
      label: "Archived",
      colorScheme: "gray",
    };
  } else {
    return {
      label: "Active",
      colorScheme: "green",
    };
  }
};
