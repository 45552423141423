import { Avatar, Box, HStack, Icon, Tag, Text, VStack } from "@chakra-ui/react";
import { Collection, Column, RecordLink } from "@sciencecorp/helix-components";
import { humanize, titleize } from "inflection";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { MdOutlineStorefront } from "react-icons/md";
import { PurchaseIndexItemData } from "../../api/purchase";
import { MoneyText } from "../MoneyText";
import { LinkifyText, PurchaseAdditionalStatusText, purchaseRequestStatusColor } from "./util";

export const PurchasesTab = ({
  data,
  isLoading,
  pagination,
  onPagination,
  order,
  onOrder,
  location = "purchasing",
}) => {
  const columns = useMemo(
    () =>
      [
        {
          label: "Requests",
          orderOptions: { orderKey: "id" },
          render: (purchase: PurchaseIndexItemData) => (
            <VStack align="start" data-testid={`purchase-link-${purchase.id}`}>
              <RecordLink
                link={`/services/purchasing/${purchase.id}`}
                identifier={`#${purchase.id}`}
                size="small"
              />
            </VStack>
          ),
        },
        {
          label: "Requested By",
          render: (purchase: PurchaseIndexItemData) => (
            <HStack marginTop={["2", "0"]}>
              <Avatar src={purchase.user?.picture_uri} size="sm" />
              <Box>
                <Text fontSize={["xs", "sm"]}>{purchase.user?.name}</Text>
                <Text fontSize="xs" color="gray.500">
                  {purchase.team?.name}
                </Text>
              </Box>
            </HStack>
          ),
        },
        location !== "vendors" && {
          label: "Vendor(s)",
          orderOptions: { orderKey: "vendor_name" },
          render: (purchase: PurchaseIndexItemData) =>
            purchase.vendor && (
              <Box mt={{ base: 2, sm: 0 }}>
                <RecordLink
                  maxWidth="15ch"
                  type=""
                  identifier={purchase.vendor.name}
                  link={`/services/vendors/${purchase.vendor.id}`}
                  icon={
                    <Box ml={3} mt={1}>
                      <Icon as={MdOutlineStorefront} />
                    </Box>
                  }
                />
              </Box>
            ),
        },
        {
          label: "Details",
          orderOptions: { orderKey: "description" },
          render: (purchase: PurchaseIndexItemData) => (
            <VStack align="start">
              {purchase.purchase_type === "purchase_order" && (
                <Tag colorScheme="teal">Purchase Order</Tag>
              )}

              <Text maxHeight={["10ch", "10ch", "15ch", "15ch", "15ch"]} isTruncated={true}>
                <LinkifyText
                  text={purchase.description || "No description"}
                  maxWidth={["50ch", "20ch", "20ch", "30ch", "30ch", "40ch"]}
                  linkProps={{
                    maxWidth: ["50ch", "20ch", "20ch", "30ch", "30ch", "40ch"],
                  }}
                  isTruncated={true}
                />
              </Text>
            </VStack>
          ),
        },
        {
          label: "Cost",
          orderOptions: { orderKey: "amount_cents" },
          render: (purchase: PurchaseIndexItemData) => (
            <MoneyText money={purchase.amount} formatOptions={{ compact: "never" }} />
          ),
        },
        {
          label: "Created",
          orderOptions: { orderKey: "created_at" },
          render: (purchase: PurchaseIndexItemData) => (
            <Text>{DateTime.fromISO(purchase.created_at).toFormat("LLL dd yyyy")}</Text>
          ),
        },
        {
          label: "Status",
          render: (purchase: PurchaseIndexItemData) => (
            <VStack align="start" marginTop={["2", "0"]}>
              <Tag
                colorScheme={purchaseRequestStatusColor(purchase.status)}
                flexShrink={0}
                whiteSpace="nowrap"
                size={["sm", "md"]}>
                {titleize(humanize(purchase.status))}
              </Tag>
              <PurchaseAdditionalStatusText purchase={purchase} />
            </VStack>
          ),
        },
      ].filter(Boolean) as Column[],
    [data?.results]
  );

  return (
    <Box width="100%">
      <Collection
        columns={columns}
        tableProps={{
          layout: "fixed long",
        }}
        items={data?.results || []}
        isLoading={isLoading}
        onPagination={onPagination}
        pagination={data?.pagination || pagination}
        order={order}
        onOrder={onOrder}
      />
    </Box>
  );
};
