import React from "react";
import { CollectionTable, Column } from "@sciencecorp/helix-components";
import { Avatar, HStack, IconButton, Input, Switch, Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import {
  TrainingSessionShowData,
  invalidateTrainingSession,
} from "../../../../api/training_sessions";
import {
  useDeleteUserTrainingSession,
  UserTrainingSessionData,
} from "../../../../api/user_training_sessions";
import { orderBy } from "lodash";
import { trainingSessionInPast } from "../../util";

type UserTrainingSessionTableProps = {
  userTrainingSessions: Partial<UserTrainingSessionData>[];
  trainingSession: TrainingSessionShowData;
  skippedUserTrainingSessions?: Partial<UserTrainingSessionData>[];
  handleTestScoreChange?: (
    userTrainingSessionId: number,
    score: number,
    trainingSession: any
  ) => void;
  handleAttendanceChange?: (userTrainingSessionId: number, attended: boolean) => void;
};

export const UserTrainingSessionTable = ({
  userTrainingSessions,
  trainingSession,
  handleTestScoreChange,
  handleAttendanceChange,
  skippedUserTrainingSessions = [],
}: UserTrainingSessionTableProps) => {
  const { mutate: deleteUserTrainingSession } =
    useDeleteUserTrainingSession(invalidateTrainingSession);

  const items = orderBy(
    !trainingSession.ended_at
      ? userTrainingSessions
      : [...userTrainingSessions, ...skippedUserTrainingSessions],
    ["user.name"],
    ["asc"]
  );
  const columns =
    !trainingSession.started_at && trainingSessionInPast(trainingSession.scheduled_date)
      ? ([
          {
            label: "Employee",
            render: (userTrainingSession: UserTrainingSessionData) => (
              <HStack>
                <Avatar
                  size="sm"
                  name={userTrainingSession.user.name}
                  src={userTrainingSession.user.picture_uri}
                />
                <Text fontWeight="semibold" fontSize="sm">
                  {userTrainingSession.user.name}
                </Text>
              </HStack>
            ),
          },
          trainingSession.cutoff_score && {
            label: "Test Score",
            render: (userTrainingSession: UserTrainingSessionData) => (
              <Input
                size="sm"
                type="number"
                value={userTrainingSession.test_score || ""}
                onChange={(e) => {
                  handleTestScoreChange &&
                    handleTestScoreChange(
                      userTrainingSession.id,
                      +e.target.value,
                      userTrainingSessions
                    );
                }}
                borderRadius="md"
              />
            ),
          },
          {
            label: "",
            render: (userTrainingSession: UserTrainingSessionData) => (
              <IconButton
                aria-label="delete user training session"
                icon={<CloseIcon />}
                size="sm"
                variant="ghost"
                colorScheme="red"
                onClick={() => deleteUserTrainingSession(userTrainingSession.id)}
              />
            ),
          },
        ].filter(Boolean) as Column[])
      : !trainingSession.started_at
      ? [
          {
            label: "Employee",
            render: (userTrainingSession: UserTrainingSessionData) => (
              <HStack>
                <Avatar
                  size="sm"
                  name={userTrainingSession.user.name}
                  src={userTrainingSession.user.picture_uri}
                />
                <Text fontWeight="semibold" fontSize="sm">
                  {userTrainingSession.user.name}
                </Text>
              </HStack>
            ),
          },
          {
            label: "",
            render: (userTrainingSession: UserTrainingSessionData) =>
              userTrainingSession.status === "registered" && (
                <IconButton
                  aria-label="delete user training session"
                  icon={<CloseIcon />}
                  size="sm"
                  variant="ghost"
                  colorScheme="red"
                  onClick={() => deleteUserTrainingSession(userTrainingSession.id)}
                />
              ),
          },
        ]
      : ([
          {
            label: "Employee",
            render: (userTrainingSession: UserTrainingSessionData) => (
              <HStack>
                <Avatar
                  size="sm"
                  name={userTrainingSession.user.name}
                  src={userTrainingSession.user.picture_uri}
                />
                <Text fontWeight="semibold" fontSize="sm">
                  {userTrainingSession.user.name}
                </Text>
              </HStack>
            ),
          },
          trainingSession.cutoff_score && {
            label: "Test Score",
            render: (userTrainingSession: UserTrainingSessionData) => (
              <Input
                size="sm"
                type="number"
                value={userTrainingSession.test_score || ""}
                isDisabled={
                  userTrainingSession.status !== "attended" &&
                  userTrainingSession.status !== "completed"
                }
                onChange={(e) =>
                  handleTestScoreChange &&
                  handleTestScoreChange(
                    userTrainingSession.id,
                    +e.target.value,
                    userTrainingSessions
                  )
                }
                borderRadius="md"
              />
            ),
          },
          {
            label: "Attendance",
            render: (userTrainingSession: UserTrainingSessionData) => (
              <Switch
                colorScheme="teal"
                isChecked={
                  userTrainingSession.status === "attended" ||
                  userTrainingSession.status === "completed"
                }
                onChange={(e) =>
                  handleAttendanceChange &&
                  handleAttendanceChange(userTrainingSession.id, e.target.checked)
                }
              />
            ),
          },
        ].filter((x) => x) as Column[]);
  return <CollectionTable items={items} columns={columns} />;
};
