import React from "react";
import {
  Box,
  Heading,
  HStack,
  VStack,
  Button,
  Text,
  Flex,
  Badge,
  Input,
  Divider,
  useColorModeValue,
  IconButton,
  Spinner,
  useToast,
  Icon,
  Stack,
  LinkBox,
  LinkOverlay,
  Tag,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { CloseIcon } from "@chakra-ui/icons";
import { ConfirmationButton, EmptyState, Header, SplitPage } from "@sciencecorp/helix-components";
import { IoMedalOutline } from "react-icons/io5";
import {
  useDeleteCredentialPackageCredential,
  useGetCredentialPackage,
  useSearchCredentialPackages,
  useUpdateCredentialPackage,
} from "../../../api/credential_packages";
import { SidebarList } from "../../shared/SidebarList";
import { UserCredentialPackageTable } from "./components/CredentialPackageUserTable";
import { AddCredentialPackageCredential } from "./components/AddCredentialtoPackage";
import { Link as RouterLink } from "react-router-dom";

import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";

export const CredentialPackage = () => {
  const { id } = useParams();

  const { search, debouncedSearch } = useDebouncedSearch();
  const { data: credentialPackages } = useSearchCredentialPackages({
    term: search || "*",
    pagination: { per_page: -1 },
    order: { name: "asc" },
  });
  const hoverBg = useColorModeValue("gray.100", "gray.600");
  const selectedBg = useColorModeValue("gray.200", "gray.600");

  const {
    data: credentialPackage,
    isLoading,
    isError,
    error,
  } = useGetCredentialPackage(Number(id));

  const { mutate: updateCredentialPackage } = useUpdateCredentialPackage(Number(id));
  const { mutate: deleteCredentialPackageCredential } = useDeleteCredentialPackageCredential(
    Number(id)
  );

  const toast = useToast();

  if (isLoading) return <Spinner />;
  if (isError)
    return (
      <Text>
        Error loading credential package:{" "}
        {error instanceof Error ? error.message : "An error occurred"}
      </Text>
    );

  const handlePublish = () => {
    updateCredentialPackage(
      { id: credentialPackage?.id, is_draft: false },
      {
        onSuccess: () => {
          toast({
            title: "Credential package published.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        },
        onError: () => {
          toast({
            title: "Error",
            description: "Failed to publish credential package",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        },
      }
    );
  };

  const status = credentialPackage.is_draft ? "Draft" : "Active";
  const statusColor = credentialPackage.is_draft ? "gray" : "green";

  return (
    <SplitPage
      sidebarWidth="350px"
      sidebarWidthXL="450px"
      sidebar={
        <SidebarList
          title="Other Credential Packages"
          breadcrumbTitle={"Back to Credentials Hub"}
          url="/credentials/hub"
          debouncedSearch={debouncedSearch}>
          {credentialPackages?.results.map((item) => (
            <LinkBox key={item.id} width="100%" _hover={{ bg: hoverBg, borderRadius: "sm" }}>
              <LinkOverlay as={RouterLink} to={`/credential_packages/${item.id}`}>
                <VStack
                  align="start"
                  p={4}
                  borderRadius={item.id === credentialPackage.id ? "sm" : ""}
                  borderBottom={item.id === credentialPackage.id ? "none" : "1px"}
                  borderColor="chakra-border-color"
                  bg={item.id === credentialPackage.id ? selectedBg : ""}>
                  <HStack justify="space-between" w="100%">
                    <Heading size="sm" key={item.id}>
                      {item.name}
                    </Heading>
                    <Tag colorScheme={item.is_draft ? "gray" : "green"}>
                      {item.is_draft ? "Draft" : "Active"}
                    </Tag>
                  </HStack>
                </VStack>
              </LinkOverlay>
            </LinkBox>
          ))}
        </SidebarList>
      }
      main={
        <Box w="100%">
          <Header
            title={credentialPackage.name}
            badge={{
              label: status,
              colorScheme: statusColor,
            }}
            actions={
              credentialPackage.is_draft
                ? [
                    <ConfirmationButton
                      label="Publish"
                      title="Publish Credential Package"
                      variant="Button"
                      colorScheme="teal"
                      isLoading={isLoading}
                      onConfirm={handlePublish}
                      children="Publishing this credential package will make it active and visible to all employees. Are you sure you want to proceed?"
                    />,
                  ]
                : []
            }
          />
          <VStack mt={8} gap={6} w="100%">
            <VStack
              p={5}
              border="1px"
              borderColor="chakra-border-color"
              borderRadius="md"
              align="start"
              spacing={4}
              width="100%">
              <HStack justify="space-between" w="100%">
                <Heading size="md">Associated Credentials</Heading>
                <AddCredentialPackageCredential credentialPackage={credentialPackage} />
              </HStack>
              <Divider />
              {credentialPackage.credential_package_credentials.length > 0 ? (
                <Stack direction="row" spacing={3} wrap="wrap" w="100%">
                  {credentialPackage.credential_package_credentials.map((c) => (
                    <HStack
                      key={c.id}
                      w="max-content"
                      p={2}
                      borderRadius="md"
                      bg={useColorModeValue("gray.100", "gray.600")}>
                      <Icon as={IoMedalOutline} />
                      <Text fontSize="sm">{c.credential.name}</Text>
                      {credentialPackage.is_draft && (
                        <IconButton
                          size="xs"
                          variant="ghost"
                          aria-label="Remove Credential"
                          icon={<CloseIcon />}
                          onClick={() => deleteCredentialPackageCredential(c.id)}
                        />
                      )}
                    </HStack>
                  ))}
                </Stack>
              ) : (
                <EmptyState size="2xs" title="No associated credentials" />
              )}
            </VStack>
            <UserCredentialPackageTable credentialPackage={credentialPackage} />
          </VStack>
        </Box>
      }
    />
  );
};
