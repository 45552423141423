import { InfoOutlineIcon, SmallAddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  HStack,
  IconButton,
  Link,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Tag,
  Text,
  VStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import {
  AttributesTable,
  EditableDate,
  RecordLink,
  StatusSelect,
  buildFacets,
  useCollection,
} from "@sciencecorp/helix-components";
import { Select } from "chakra-react-select";
import { orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useTeamMembersOptions } from "../../../api/options";
import { useCreateServiceRequestEvent } from "../../../api/service_request_events";
import {
  ServiceRequestShowData,
  useCreateAssignedUser,
  useCreateServiceRequestSubscriber,
  useDeleteAssignedUser,
  useDeleteServiceRequestSubscriber,
  useSearchServiceRequestsQuery,
  useUpdateServiceRequest,
} from "../../../api/service_requests";
import { ServicePartialData } from "../../../api/services";
import { UserLoggedInData } from "../../../api/user";
import { EditableSpendingAuthoritySelect } from "../../Purchasing/SpendingAuthoritySelectTree";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import {
  SidebarList,
  SubscriberOptions,
  UserOptionType,
  UserRequestPill,
  UserServiceRequestPill,
  serviceStatusMap,
  serviceStatusOption,
} from "../utils";

const PRIORITY_OPTIONS = [
  { label: "3", value: 3 },
  { label: "2", value: 2 },
  { label: "1", value: 1 },
];

const statusMap = [
  { label: "3", value: 3, color: "red" },
  { label: "2", value: 2, color: "orange" },
  { label: "1", value: 1, color: "yellow" },
];
type subscriberOptionsType = {
  label: string;
  value: number;
  user: UserOptionType;
};

type ServiceRequestDetailsSidebarProps = {
  serviceRequest: ServiceRequestShowData;
  service: ServicePartialData;
  currentUser: UserLoggedInData;
  isServiceAdmin: boolean;
};

export const ServiceRequestDetailsSidebar = ({
  serviceRequest,
  service,
  currentUser,
  isServiceAdmin,
}: ServiceRequestDetailsSidebarProps) => {
  const { mutate: createServiceRequestEvent } = useCreateServiceRequestEvent(serviceRequest.id);
  const { mutate: updateServiceRequest } = useUpdateServiceRequest(serviceRequest.id);
  const { mutate: createAssignedUser } = useCreateAssignedUser(serviceRequest.id);
  const { mutate: deleteAssignedUser } = useDeleteAssignedUser(serviceRequest.id);
  const { mutate: createSubscriber } = useCreateServiceRequestSubscriber(serviceRequest.id);
  const { mutate: deleteSubscriber } = useDeleteServiceRequestSubscriber(serviceRequest.id);
  const { data: subOptions, isSuccess: subscriberOptionsSuccess } = SubscriberOptions();
  const [subscriberOptions, setSubscriberOptions] = useState<subscriberOptionsType[]>([]);
  const { facets, onFacets } = useCollection();
  const AGGREGATION_QUERY = ["status", "assigned_user_name"];

  const { search, debouncedSearch } = useDebouncedSearch();

  const { data: sidebarResults } = serviceRequest.user_in_fulfilling_team
    ? useSearchServiceRequestsQuery({
        term: search || "*",
        filters: { service_team_id: service.team_id },
        aggs: AGGREGATION_QUERY,
        pagination: { per_page: -1 },
        order: { created_at: "desc" },
      })
    : useSearchServiceRequestsQuery({
        term: search || "*",
        pagination: { per_page: 5 },
        order: { created_at: "desc" },
        filters: { requesting_user_id: currentUser.id },
      });

  const populatedFacets = buildFacets(sidebarResults?.aggregations || {}, facets);

  const handleDelete = (user) => {
    deleteAssignedUser(user.id);
  };

  useEffect(() => {
    const filteredSubOptions = subOptions?.filter(
      (member) =>
        !serviceRequest.service_requests_assigned_users.some(
          (assignedUser) => assignedUser.assigned_user.id === member.value
        ) &&
        !serviceRequest.service_request_subscribers.some(
          (subscriber) => subscriber.user.id === member.value
        )
    );
    setSubscriberOptions(orderBy(filteredSubOptions, ["label"]));
  }, [subscriberOptionsSuccess]);

  const handleSubscriberDelete = (user: { id: number; user: UserOptionType }) => {
    deleteSubscriber(user.id, {
      onSuccess: () => {
        setSubscriberOptions((prev) =>
          orderBy(
            [...prev, { value: user.user.id, label: user.user.name, user: user.user }],
            ["label"]
          )
        );
      },
    });
  };

  const assignedUserIds = serviceRequest.service_requests_assigned_users.map(
    (assignedUser) => assignedUser.assigned_user.id
  );

  const teamMemberOptions = useTeamMembersOptions(
    serviceRequest.top_level_team_id,
    assignedUserIds
  );

  const editable = serviceRequest.user_in_fulfilling_team || isServiceAdmin;
  const toast = useToast();

  return (
    <Stack direction="column" spacing={4}>
      <Box
        bg={useColorModeValue("gray.50", "gray.700")}
        borderRadius="md"
        width={"100%"}
        border="1px"
        borderColor="chakra-border-color"
        p={4}
        overflow="clip">
        <AttributesTable
          title=""
          attributes={[
            { label: "Request ID", value: serviceRequest.id },
            {
              label: "Requested By",
              value: (
                <Link href={`/users/${serviceRequest.requesting_user.id}`}>
                  <UserServiceRequestPill
                    user={serviceRequest.requesting_user}
                    location="attributes"
                  />
                </Link>
              ),
            },
            {
              label: "Requesting Team",
              value: serviceRequest.requesting_team ? (
                <RecordLink
                  identifier={serviceRequest.requesting_team?.name || ""}
                  link={`/teams/${serviceRequest.requesting_team?.id}/readme`}
                />
              ) : (
                "N/A"
              ),
            },
            {
              label: "Spending Authority",
              value:
                serviceRequest.spending_authority &&
                serviceRequest.spending_authority.type === "RevenueItem" ? (
                  <Tag colorScheme="green">Attributed to Revenue Item</Tag>
                ) : (
                  <EditableSpendingAuthoritySelect
                    onSubmit={(spendingAuthority) => {
                      if (spendingAuthority) {
                        updateServiceRequest({
                          id: serviceRequest.id,
                          spending_authority_type: spendingAuthority.type,
                          spending_authority_id: spendingAuthority.id,
                        });
                      }
                    }}
                    spendingAuthority={serviceRequest.spending_authority}
                    excludeTypes={["Service"]}
                  />
                ),
            },
            {
              label: "Site",
              value: serviceRequest.site.name,
            },
            {
              label: "Fulfilled by",
              value: (
                <RecordLink
                  identifier={service.team_id ? service.team.name : "Unassigned"}
                  link={`/teams/${service.team_id}/readme`}
                />
              ),
            },
            {
              label: "Est. Completion Date",
              value: (
                <EditableDate
                  defaultValue={
                    serviceRequest.estimated_completion_date
                      ? serviceRequest.estimated_completion_date
                      : undefined
                  }
                  disabled={!editable}
                  persistentEdit
                  onSubmit={(date) => {
                    serviceRequest &&
                      date &&
                      updateServiceRequest(
                        {
                          id: serviceRequest.id,
                          estimated_completion_date: date,
                        },
                        {
                          onSuccess: () => {
                            createServiceRequestEvent({
                              service_request_id: serviceRequest.id,
                              user_id: currentUser.id,
                              event_type: "date",
                              event_info: {
                                description: `changed the estimated completion date to`,
                                date: date,
                              },
                              slack_message: `changed the estimated completion date to ${date}`,
                            });
                            toast({
                              title: "Successful!",
                              description: "You updated the request completion date",
                              status: "success",
                              duration: 5000,
                              isClosable: true,
                            });
                          },
                        }
                      );
                  }}
                />
              ),
            },
            {
              label: "Priority",
              value: (
                <StatusSelect
                  options={PRIORITY_OPTIONS}
                  isDisabled={!editable}
                  variant="badge"
                  type="solid"
                  status={
                    serviceRequest.priority_level
                      ? statusMap.find((ele) => ele.value === serviceRequest.priority_level)
                      : undefined
                  }
                  onSubmit={(option) => {
                    option &&
                      updateServiceRequest(
                        {
                          id: serviceRequest.id,
                          priority_level: Number(option),
                        },
                        {
                          onSuccess: () =>
                            toast({
                              title: "Successful!",
                              description: "You updated the priority level",
                              status: "success",
                              duration: 5000,
                              isClosable: true,
                            }),
                        }
                      );
                  }}
                />
              ),
            },
            {
              label: "Request Status",
              value: (
                <StatusSelect
                  options={serviceStatusOption}
                  isDisabled={
                    !editable ||
                    (serviceRequest.requires_approval && !serviceRequest.fully_approved)
                  }
                  variant="tag"
                  status={
                    serviceRequest.status
                      ? serviceStatusMap.find(
                          (ele) => ele.value === serviceRequest.status.toString()
                        )
                      : undefined
                  }
                  onSubmit={(status) => {
                    if (status) {
                      updateServiceRequest(
                        {
                          id: serviceRequest.id,
                          status: status.toString(),
                        },
                        {
                          onSuccess: () => {
                            createServiceRequestEvent({
                              service_request_id: serviceRequest.id,
                              user_id: currentUser.id,
                              event_type: "status",
                              event_info: {
                                description: "moved status to",
                                status: status.toString(),
                              },
                              slack_message: `moved status to ${status.toString()}`,
                            });
                            toast({
                              title: "Successful!",
                              description: "You updated the request status",
                              status: "success",
                              duration: 5000,
                              isClosable: true,
                            });
                          },
                        }
                      );
                    }
                  }}
                />
              ),
            },
          ]}
        />
      </Box>
      <VStack
        p={4}
        border="1px"
        bg={useColorModeValue("gray.50", "gray.700")}
        borderRadius="md"
        borderColor={useColorModeValue("gray.200", "gray.600")}>
        <HStack width="100%" justify="space-between">
          <Text fontSize="xs" textTransform="uppercase" fontWeight="bold">
            Assigned Users
          </Text>
          {editable ? (
            <Popover placement="bottom-end">
              <PopoverTrigger>
                <IconButton
                  p={0}
                  bg="transparent"
                  aria-label="add-link"
                  size="sm"
                  icon={<SmallAddIcon />}
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverCloseButton />
                <PopoverBody maxH={52} overflow="scroll">
                  <VStack align="start">
                    <Text fontWeight="semibold">Assign someone to this task</Text>
                    {teamMemberOptions &&
                      serviceRequest &&
                      teamMemberOptions.map((member: UserOptionType) => (
                        <Button
                          key={`${member.id} ${member.name}`}
                          cursor="pointer"
                          justifyContent="start"
                          bg="transparent"
                          width="100%"
                          onClick={() =>
                            createAssignedUser({
                              assigned_user_id: member.id,
                              service_request_id: serviceRequest.id,
                            })
                          }>
                          <UserServiceRequestPill user={member} />
                        </Button>
                      ))}
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ) : null}
        </HStack>
        {serviceRequest.service_requests_assigned_users.length
          ? serviceRequest.service_requests_assigned_users.map((user) => (
              <UserRequestPill
                key={`assigned_${user.id}`}
                user={user}
                handleDelete={editable ? handleDelete : undefined}
              />
            ))
          : null}
      </VStack>
      <VStack
        p={4}
        border="1px"
        bg={useColorModeValue("gray.50", "gray.700")}
        borderRadius="md"
        spacing={2}
        borderColor={useColorModeValue("gray.200", "gray.600")}>
        <HStack width="100%" justify="space-between">
          <Text fontSize="xs" textTransform="uppercase" fontWeight="bold">
            Subscribers
          </Text>
          <Box width="150px" bg={useColorModeValue("white", "gray.800")}>
            <Select
              components={{
                DropdownIndicator: null,
              }}
              size="sm"
              options={subscriberOptions}
              value={null}
              placeholder="Select User"
              onChange={(value) => {
                value &&
                  serviceRequest &&
                  createSubscriber(
                    {
                      user_id: value.value,
                      service_request_id: serviceRequest.id,
                    },
                    {
                      onSuccess: () => {
                        setSubscriberOptions((prev) =>
                          prev.filter((ele) => ele.value !== value.value)
                        );
                      },
                    }
                  );
              }}
            />
          </Box>
        </HStack>
        {serviceRequest.service_request_subscribers.length
          ? serviceRequest.service_request_subscribers.map((user) => (
              <UserServiceRequestPill
                key={`sub_${user.id}`}
                user={user}
                handleDelete={handleSubscriberDelete}
              />
            ))
          : null}
        <Divider />
        <HStack color={useColorModeValue("gray.500", "gray.300")} width="100%" pt={1}>
          <InfoOutlineIcon boxSize={3} />
          <Text fontSize="xs" fontWeight="medium">
            Subscribers receive update notifications.
          </Text>
        </HStack>
      </VStack>

      <SidebarList
        title={serviceRequest.user_in_fulfilling_team ? "Work Queue" : "Your Open Requests"}
        data={sidebarResults?.results || []}
        url={"/services/requests"}
        facets={populatedFacets}
        onFacets={onFacets}
        debouncedSearch={debouncedSearch}
        selectedItemId={serviceRequest.id}
      />
    </Stack>
  );
};
