import { SearchIcon } from "@chakra-ui/icons";
import {
  useColorModeValue,
  VStack,
  Breadcrumb,
  BreadcrumbLink,
  Heading,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
} from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

type SidebarListProps = {
  title: string;
  children: React.ReactNode;
  url: string;
  breadcrumbTitle: string;
  breadcrumbUrl?: string;
  debouncedSearch: (value: string) => void;
};

export const SidebarList = ({
  title,
  children,
  url,
  breadcrumbTitle,
  breadcrumbUrl = url,
  debouncedSearch,
}: SidebarListProps) => {
  return (
    <VStack
      width="100%"
      align="start"
      spacing={4}
      py={6}
      display={{ base: "none", lg: "flex" }}
      px={4}
      bgColor={useColorModeValue("gray.50", "gray.700")}
      border="1px"
      borderColor="chakra-border-color"
      borderRadius="md">
      <Breadcrumb>
        <BreadcrumbLink as={RouterLink} to={breadcrumbUrl} color="teal.500">
          ← {breadcrumbTitle}
        </BreadcrumbLink>
      </Breadcrumb>
      <Heading width={"100%"} size="md">
        {title}
      </Heading>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
        <Input
          placeholder="Search..."
          onChange={(e) => debouncedSearch(e.target.value)}
          background={useColorModeValue("white", "gray.700")}
        />
      </InputGroup>
      <VStack spacing={1} width="100%" align="start" height="75vh" overflow="auto">
        {children}
      </VStack>
    </VStack>
  );
};
