import { z } from "zod";
import { api } from ".";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { createPaginatedResponseSchema, paginationSchema } from "./shared";
import { aggregationSchema, indexParams, searchParams, SearchParams } from "./collection_types";
import { trainingMaterialShowSchema } from "./training_materials";
import { trainingSessionIndexSchema, trainingSessionShowSchema } from "./training_sessions";
import { userTrainingSessionSchema } from "./user_training_sessions";
import { zodParse } from "./zodParse";
import { userMinimalSchema } from "./user";

export const TRAININGS_BASE_URL = "trainings";
export const CREDENTIAL_TRAININGS_BASE_URL = "credential_trainings";

export const credentialTrainingSchema = z.object({
  id: z.number(),
  credential: z.object({ id: z.number(), name: z.string() }),
});

export const credentialTrainingCreateSchema = z.object({
  credential_ids: z.number().array(),
  training_id: z.number(),
});

export const trainingSchema = z.object({
  id: z.number(),
  name: z.string(),
  is_draft: z.boolean(),
  description: z.string(),
  cutoff_score: z.number().nullable().optional(),
  is_archived: z.boolean(),
});

export const trainingShowSchema = trainingSchema.extend({
  is_facilitator: z.boolean(),
  is_next_session_facilitator: z.boolean(),
  credential_trainings: z.array(credentialTrainingSchema),
  next_session: z.string().nullable(),
  has_next_session: z.boolean(),
  completed_training_sessions: z.number(),
  user_training_session_status: z.string().nullable(),
});

export const trainingSessionAttendanceSchema = z.object({
  id: z.number(),
  scheduled_date: z.string(),
  facilitator: z.lazy(() => userMinimalSchema),
  user_training_status: z.string(),
});

export const trainingSessionAttendanceIndexSchema = z.object({
  results: z.array(trainingSessionAttendanceSchema),
  pagination: paginationSchema,
});

export const trainingIndexSchema = z.object({
  results: z.array(trainingShowSchema),
  pagination: paginationSchema,
  aggregation: aggregationSchema,
  training_count: z.number(),
  total_completed_sessions: z.number(),
  archived_trainings: z.number(),
  total_completed_user_training_sessions: z.number(),
});

export const nextSessionSchema = z.object({
  training_session: trainingSessionShowSchema.nullable(),
  user_training_session: userTrainingSessionSchema.nullable(),
});

export const trainingSessionsForTableSchema =
  createPaginatedResponseSchema(trainingSessionShowSchema);

export type TrainingData = z.infer<typeof trainingSchema>;
export type TrainingShowData = z.infer<typeof trainingShowSchema>;
export type TrainingIndexData = z.infer<typeof trainingIndexSchema>;
export type TrainingCreateParams = Pick<TrainingData, "name" | "cutoff_score">;

export type NextSessionData = z.infer<typeof nextSessionSchema>;

export type TrainingSessionAttendanceData = z.infer<typeof trainingSessionAttendanceSchema>;
export type CredentialTrainingCreateData = z.infer<typeof credentialTrainingCreateSchema>;

// api queries

export const getTraining = async (id: number) => {
  const result = await api.get(`${TRAININGS_BASE_URL}/${id}`);
  return zodParse(trainingShowSchema, result.data);
};

// gets a list of the training sessions for a training
export const getTrainingSessions = async (id: number) => {
  const result = await api.get(`${TRAININGS_BASE_URL}/${id}/sessions`);
  return zodParse(trainingSessionIndexSchema, result.data);
};

export const getNextTrainingSession = async (id: number) => {
  const result = await api.get(`${TRAININGS_BASE_URL}/${id}/next_session`);
  return zodParse(nextSessionSchema, result.data);
};

export const getUserAttendanceHistory = async (id: number) => {
  const result = await api.get(`${TRAININGS_BASE_URL}/${id}/me`);
  return zodParse(trainingSessionAttendanceIndexSchema, result.data);
};

export const getUserFacilitatorTrainingHistory = async (id: number) => {
  const result = await api.get(`${TRAININGS_BASE_URL}/${id}/me/facilitator`);
  return zodParse(trainingSessionIndexSchema, result.data);
};

export const getTrainingMaterials = async (id: number) => {
  const result = await api.get(`${TRAININGS_BASE_URL}/${id}/materials`);
  return zodParse(z.array(trainingMaterialShowSchema), result.data);
};

export const searchTrainings = async ({ aggs, filters, pagination, order, term }: SearchParams) => {
  const path = [TRAININGS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return result.data;
};

export const newTraining = async (training: TrainingCreateParams) => {
  const result = await api.post(TRAININGS_BASE_URL, { training });
  return result.data;
};

export const updateTraining = async (training: Partial<TrainingData>) => {
  const result = await api.put(`${TRAININGS_BASE_URL}/${training.id}`, {
    training,
  });
  return result.data;
};

export const deleteTraining = async (id: number) => {
  await api.delete(`${TRAININGS_BASE_URL}/${id}`);
};

export const createCredentialTraining = async (
  credentialTraining: CredentialTrainingCreateData
) => {
  const result = await api.post(CREDENTIAL_TRAININGS_BASE_URL, {
    credential_training: credentialTraining,
  });
  return result.data;
};

export const deleteCredentialTraining = async (credentialTrainings: number[]) => {
  await api.post(`${CREDENTIAL_TRAININGS_BASE_URL}/delete`, {
    credential_training: { ids: credentialTrainings },
  });
};

// query hooks

export const invalidateTraining = (id?: number) => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({
      queryKey: id ? [TRAININGS_BASE_URL, id] : [TRAININGS_BASE_URL],
    });
};

export const useGetTrainingSessions = (id: number) => {
  return useQuery({
    queryKey: [TRAININGS_BASE_URL, id, "sessions"],
    queryFn: () => getTrainingSessions(id),
  });
};

export const useGetNextTrainingSession = (id: number) => {
  return useQuery({
    queryKey: [TRAININGS_BASE_URL, id, "next_session"],
    queryFn: () => getNextTrainingSession(id),
  });
};

export const useGetUserAttendanceHistory = (id: number) => {
  return useQuery({
    queryKey: [TRAININGS_BASE_URL, id, "me"],
    queryFn: () => getUserAttendanceHistory(id),
  });
};

export const useGetUserFacilitatorTrainingHistory = (id: number) => {
  return useQuery({
    queryKey: [TRAININGS_BASE_URL, id, "me", "facilitator"],
    queryFn: () => getUserFacilitatorTrainingHistory(id),
  });
};

export const useGetTrainingMaterials = (id: number) => {
  return useQuery({
    queryKey: [TRAININGS_BASE_URL, id, "materials"],
    queryFn: () => getTrainingMaterials(id),
  });
};

export const useGetTraining = (id: number) => {
  return useQuery({
    queryKey: [TRAININGS_BASE_URL, id],
    queryFn: () => getTraining(id),
  });
};

export const useSearchTrainings = (params: SearchParams) => {
  return useQuery({
    queryKey: [TRAININGS_BASE_URL, params],
    queryFn: () => searchTrainings(params),
  });
};

export const useNewTraining = () => {
  return useMutation({
    mutationFn: newTraining,
    onSuccess: invalidateTraining(),
  });
};

export const useUpdateTraining = (id?: number) => {
  return useMutation({
    mutationFn: updateTraining,
    onSuccess: invalidateTraining(id),
  });
};

export const useDeleteUserCredential = () => {
  return useMutation({
    mutationFn: deleteTraining,
    onSuccess: invalidateTraining(),
  });
};

export const useCreateCredentialTraining = () => {
  return useMutation({
    mutationFn: createCredentialTraining,
    onSuccess: invalidateTraining(),
  });
};

export const useDeleteCredentialTraining = () => {
  return useMutation({
    mutationFn: deleteCredentialTraining,
    onSuccess: invalidateTraining(),
  });
};
