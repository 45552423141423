import React, { useState, useEffect } from "react";
import { Button, Flex, Box } from "@chakra-ui/react";
import {
  Header,
  SplitPage,
  Facets,
  useCollection,
  buildFacets,
} from "@sciencecorp/helix-components";
import { useParams, useNavigate, Link as RouterLink } from "react-router-dom";
import { IoCubeOutline } from "react-icons/io5";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useSearchInventoryLocations } from "../../api/inventory_location";
import { useSearchInventories } from "../../api/inventory";
import { useDebouncedSearch } from "../hooks/useDebouncedSearch";
import { Inventories } from "./Inventories";
import { Locations } from "./Locations";
import { userHasRole, useCurrentUserQuery } from "../../api/user";
import { NewInventoryModal } from "./components/NewInventoryModal";
import { FiShoppingCart } from "react-icons/fi";

export const Inventory = () => {
  const { tab } = useParams();
  const [activeTab, setActiveTab] = useState(tab || "inventory");
  const navigate = useNavigate();
  const currentUserQuery = useCurrentUserQuery();
  const isInventoryManager = userHasRole(currentUserQuery, "inventory_manager");
  const {
    pagination,
    order,
    onPagination,
    onOrder,
    facets,
    onFacets,
    filters,
    resetFacets,
    resetPagination,
  } = useCollection();

  const { search, debouncedSearch } = useDebouncedSearch();

  const tabs = [
    { label: "Items", value: "items", icon: <IoCubeOutline /> },
    { label: "Locations", value: "locations", icon: <HiOutlineLocationMarker /> },
  ];

  const INVENTORY_AGGREGATIONS_QUERY = [
    "category",
    "status",
    "is_archived",
    "is_consumable",
    "users",
    "sites",
    "locations",
  ];

  const { data, isLoading, isSuccess } =
    activeTab === "locations"
      ? useSearchInventoryLocations({
          term: search || "*",
          pagination: { per_page: -1, page: 1 },
          filters,
          order,
        })
      : useSearchInventories({
          term: search || "*",
          pagination,
          order,
          filters: { ...filters, is_archived: false },
          aggs: INVENTORY_AGGREGATIONS_QUERY,
        });

  useEffect(() => {
    if (tab && tab !== activeTab) {
      setActiveTab(tab);
    }
    resetFacets();
    resetPagination();
  }, [tab]);

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  return (
    <Flex direction="column">
      <Header
        title="Inventory"
        actions={
          isInventoryManager
            ? [
                <Button
                  as={RouterLink}
                  leftIcon={<FiShoppingCart />}
                  to={"/inventory/items/checkout"}
                  colorScheme="teal">
                  Checkout Items
                </Button>,
                <NewInventoryModal />,
              ]
            : [
                <Button
                  as={RouterLink}
                  leftIcon={<FiShoppingCart />}
                  to={"checkout"}
                  colorScheme="teal">
                  Checkout Items
                </Button>,
              ]
        }
      />
      <Box overflow="auto">
        <SplitPage
          sidebarWidth="250px"
          sidebarWidthXL="300px"
          sidebar={
            <Flex direction="column" gap={2}>
              {tabs.map((tabInfo) => (
                <Button
                  key={tabInfo.value}
                  leftIcon={tabInfo.icon}
                  width="100%"
                  justifyContent="start"
                  colorScheme={activeTab === tabInfo.value ? "teal" : "gray"}
                  bg={activeTab === tabInfo.value ? "teal" : "transparent"}
                  onClick={() => {
                    navigate(`/inventory/${tabInfo.value}`);
                    setActiveTab(tabInfo.value);
                  }}>
                  {tabInfo.label}
                </Button>
              ))}
              <Box display={["flex", "flex", "none"]} alignSelf="end">
                <Facets
                  variant="button"
                  defaultIndex={[]}
                  facets={populatedFacets}
                  onChange={onFacets}
                  search
                  background
                  debouncedSearch={debouncedSearch}
                />
              </Box>
              <Box display={["none", "none", "inline"]} width="100%" alignSelf="start">
                <Facets
                  defaultIndex={activeTab === "items" ? [0, 4] : []}
                  facets={populatedFacets}
                  onChange={onFacets}
                  search
                  background
                  debouncedSearch={debouncedSearch}
                />
              </Box>
            </Flex>
          }
          main={
            <>
              {activeTab === "items" && (
                <Inventories
                  data={data}
                  isLoading={isLoading}
                  onPagination={onPagination}
                  onOrder={onOrder}
                  order={order}
                  pagination={pagination}
                />
              )}
              {activeTab === "locations" && (
                <Locations
                  data={data}
                  isLoading={isLoading}
                  isSuccess={isSuccess}
                  isInventoryManager={isInventoryManager}
                />
              )}
            </>
          }
        />
      </Box>
    </Flex>
  );
};
