import { AddIcon, SettingsIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Switch,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  EditableSelect,
  EditableText,
  FormModal,
  Header,
  Select,
  SplitPage,
  StatusSelect,
} from "@sciencecorp/helix-components";
import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { DateTime } from "luxon";
import React, { useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { Controller, useForm } from "react-hook-form";
import { BsThreeDotsVertical } from "react-icons/bs";
import { LiaPiggyBankSolid } from "react-icons/lia";
import { MdCreditCard } from "react-icons/md";
import { useNavigate, useParams } from "react-router";
import { useVendorOptions } from "../../../api/options";
import { useSearchPurchases } from "../../../api/purchase";
import { useCurrentUserQuery, userHasRole } from "../../../api/user";
import {
  VendorData,
  useDeleteVendor,
  useGetVendor,
  useMergeVendor,
  useNewVendor,
  useSearchVendors,
  useUpdateVendor,
} from "../../../api/vendor";
import { SidebarList } from "../../Credentials/util";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { EmptyState } from "../../shared/EmptyState";
import { vendorStatusMap, vendorStatusOptions } from "../util";
import { NewVendorContactModal } from "./components/NewVendorContactModal";
import { NewVendorModal } from "./components/NewVendorModal";
import { VendorContactsTable } from "./components/VendorContactsTable";
import { VendorPurchasesTable } from "./components/VendorPurchasesTable";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SpendingGraph = ({ vendorId }) => {
  const { data: purchasesData, isLoading } = useSearchPurchases({
    term: "*",
    filters: { vendor_id: [vendorId] },
    order: { created_at: "asc" },
  });

  const chartData = useMemo(() => {
    if (!purchasesData) return null;

    const labels = purchasesData.results.map((purchase) =>
      DateTime.fromISO(purchase.created_at).toFormat("MMM yyyy")
    );
    const data = purchasesData.results.map((purchase) => purchase.amount.majorUnits.toNumber());

    return {
      labels,
      datasets: [
        {
          label: "Amount Spent",
          data,
          borderColor: "rgba(75, 192, 192, 1)",
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          pointRadius: 3,
          pointHoverRadius: 5,
          pointStyle: "circle",
        },
      ],
    };
  }, [purchasesData]);

  if (isLoading) return <Spinner />;

  return (
    <Line
      data={chartData as ChartData<"line", number[], string>}
      options={{
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
            position: "bottom",
          },
        },
      }}
    />
  );
};

type MergeVendorFormValues = {
  targetVendorId: number | null;
};

type MergeVendorModalFormProps = {
  vendor: VendorData;
  isOpen: boolean;
  onClose: () => void;
};
const MergeVendorModalForm: React.FC<MergeVendorModalFormProps> = ({ vendor, isOpen, onClose }) => {
  const vendorOptions = useVendorOptions().filter(
    (option) => option.value !== vendor.id.toString()
  );
  const { mutateAsync: mergeVendor, isLoading: isMerging } = useMergeVendor();
  const defaultFormValues: MergeVendorFormValues = { targetVendorId: null };
  const toast = useToast();
  const navigation = useNavigate();
  const { setValue, control, watch } = useForm<MergeVendorFormValues>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });
  const isLoading = vendorOptions.length == 0 || isMerging;

  const onSubmit = () => {
    const formData = watch();
    if (formData.targetVendorId !== null) {
      mergeVendor({ sourceVendorId: vendor.id, targetVendorId: formData.targetVendorId })
        .then(() => {
          navigation(`/services/vendors/${formData.targetVendorId}`);
          toast({ status: "success", title: `Successfully consolidated ${vendor.name}!` });
          onClose();
        })
        .catch(() => {
          toast({ status: "error", title: "Failed to merge vendor" });
        });
    }
  };

  return (
    <FormModal
      title="Merge Vendor"
      submitButtonColorSchema="teal"
      submitButtonTitle="Submit"
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      submitButtonDisabled={watch("targetVendorId") === null || isMerging}
      handleSubmit={onSubmit}>
      <VStack width="100%" align="start" spacing={5}>
        <Controller
          name="targetVendorId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl>
              <FormLabel>
                Transfer all records from <strong>{vendor.name}</strong> to:
              </FormLabel>
              <Select options={vendorOptions} {...field} />
            </FormControl>
          )}
        />
      </VStack>
    </FormModal>
  );
};

export const Vendor = (): JSX.Element => {
  const { id } = useParams();
  if (!id) {
    return <Text>Invalid vendor id</Text>;
  }
  const navigate = useNavigate();
  const vendorQuery = useGetVendor(+id);

  const { mutate: deleteVendor } = useDeleteVendor();
  const { mutate: createVendor, isLoading, isError } = useNewVendor();
  const { mutate: updateVendor, isLoading: isMutateLoading } = useUpdateVendor();

  const { search, debouncedSearch } = useDebouncedSearch();
  const currentUserQuery = useCurrentUserQuery();
  const isPurchasingAdmin = userHasRole(currentUserQuery, "purchasing_admin");

  const { data } = useSearchVendors({
    term: search || "*",
    pagination: { per_page: 10 },
  });
  const {
    isOpen: isOpenNewContact,
    onOpen: onOpenNewContact,
    onClose: onCloseNewContact,
  } = useDisclosure();

  const {
    isOpen: isOpenMergeVendor,
    onOpen: onOpenMergeVendor,
    onClose: onCloseMergeVendor,
  } = useDisclosure();

  const vendorsData = data?.results.map((vendor) => ({
    id: vendor.id,
    name: vendor.name,
    status: vendor.status,
  }));

  if (vendorQuery.isLoading) return <Spinner />;
  if (vendorQuery.isSuccess) {
    const vendor = vendorQuery.data;
    const menuActions = (
      <>
        {!vendor.can_be_deleted && <MenuItem onClick={onOpenMergeVendor}>Merge Vendor</MenuItem>}
        {vendor.archived_at && (
          <MenuItem onClick={() => updateVendor({ id: +id, archived_at: null })}>Restore</MenuItem>
        )}
        {vendor.can_be_deleted ? (
          <MenuItem
            onClick={() => {
              deleteVendor(vendor.id);
              navigate("/services/vendors");
            }}>
            Delete Vendor
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              updateVendor({ id: vendor.id, archived_at: DateTime.now().toISO() });
            }}>
            Archive Vendor
          </MenuItem>
        )}
      </>
    );

    const isArchived = !!vendor.archived_at;

    if (isLoading) return <Spinner />;
    if (isError || !vendor) return <Text>Vendor not found</Text>;

    return (
      <>
        {isArchived && (
          <Alert status="warning" mb={4}>
            <AlertIcon />
            <VStack align="start" spacing={0}>
              <AlertTitle>Archived</AlertTitle>
              <AlertDescription>This vendor has been archived.</AlertDescription>
            </VStack>
          </Alert>
        )}
        <SplitPage
          sidebarWidth="300px"
          sidebarWidthXL="350px"
          breakpoint="lg"
          sidebar={
            !isArchived ? (
              <VStack
                align="start"
                width="100%"
                borderRadius="md"
                spacing={4}
                bg={useColorModeValue("gray.50", "gray.700")}>
                <SidebarList
                  data={vendorsData || []}
                  selectedItemId={+id}
                  title={<Heading size="md">Vendors</Heading>}
                  url="/services/vendors"
                  breadcrumbTitle="Back to Vendors"
                  debouncedSearch={debouncedSearch}
                />
              </VStack>
            ) : null
          }
          main={
            <VStack width="100%" align="start" spacing={8}>
              <Flex direction="column" width="100%" justify={"space-between"}>
                <Header
                  title={vendor.name}
                  actions={
                    isPurchasingAdmin
                      ? [
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              icon={<SettingsIcon />}
                              backgroundColor={useColorModeValue("gray.100", "gray.600")}
                            />
                            <MenuList>
                              <NewVendorModal
                                vendor={vendor}
                                location="vendor"
                                buttonLabel="Edit Vendor"
                              />
                            </MenuList>
                          </Menu>,
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              icon={<BsThreeDotsVertical />}
                              backgroundColor={useColorModeValue("gray.100", "gray.600")}
                            />
                            <MenuList>{menuActions}</MenuList>
                          </Menu>,
                        ]
                      : []
                  }
                />
              </Flex>
              <Stack
                justify="space-between"
                spacing={6}
                alignItems={"start"}
                direction={["column", "column", "row"]}>
                {/* Address Section */}
                <Box>
                  <HStack alignItems="start">
                    <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.400")}>
                      Address
                    </Text>
                    <EditableText
                      multiline
                      defaultValue={vendor.address || ""}
                      onSubmit={(value) => {
                        value && updateVendor({ id: vendor.id, address: value });
                      }}
                      disabled={!isPurchasingAdmin}
                    />
                  </HStack>
                </Box>

                {/* Website Section */}
                <Box>
                  <HStack alignItems="center">
                    <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.400")}>
                      Website
                    </Text>
                    {vendor.website ? (
                      <Link isTruncated={true}>
                        <EditableText
                          defaultValue={vendor.website}
                          onSubmit={(value) => {
                            value && updateVendor({ id: vendor.id, website: value });
                          }}
                          disabled={!isPurchasingAdmin}
                        />
                      </Link>
                    ) : (
                      <EditableText
                        defaultValue={vendor.website}
                        onSubmit={(value) => {
                          value && updateVendor({ id: vendor.id, website: value });
                        }}
                        disabled={!isPurchasingAdmin}
                      />
                    )}
                  </HStack>
                </Box>

                {/* Status Section */}
                <Box>
                  <HStack alignItems="center">
                    <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.400")}>
                      Status
                    </Text>
                    <StatusSelect
                      variant="tag"
                      isDisabled={!isPurchasingAdmin}
                      options={vendorStatusOptions}
                      status={vendorStatusMap.find((ele) => ele.value === vendor.status)}
                      onSubmit={(option) => {
                        option && updateVendor({ id: vendor.id, status: option.toString() });
                      }}
                    />
                  </HStack>
                </Box>
              </Stack>
              <Stack
                justify="space-between"
                spacing={6}
                alignItems={"start"}
                direction={["column", "column", "row"]}>
                <Box>
                  <HStack alignItems="center" w="max-content">
                    <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.400")}>
                      Payment Terms
                    </Text>
                    <EditableSelect
                      options={[
                        { label: "Due on Receipt", value: "due_on_receipt" },
                        { label: "Net 10", value: "net_10" },
                        { label: "Net 15", value: "net_15" },
                        { label: "Net 30", value: "net_30" },
                        { label: "Net 60", value: "net_60" },
                        { label: "Net 90", value: "net_90" },
                        { label: "EOM", value: "end_of_month" },
                        { label: "COD", value: "cash_on_delivery" },
                        { label: "CIA", value: "cash_in_advance" },
                        { label: "Installments", value: "installments" },
                        { label: "Staged Payments", value: "staged_payments" },
                        { label: "Prepaid", value: "prepaid" },
                      ]}
                      selectedValue={vendor.payment_terms}
                      onSubmit={(value) => {
                        value && updateVendor({ id: vendor.id, payment_terms: value.toString() });
                      }}
                      disabled={!isPurchasingAdmin}
                    />
                  </HStack>
                </Box>
                <Box>
                  <HStack alignItems="center" w="max-content">
                    <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.400")}>
                      Tax ID
                    </Text>
                    <EditableText
                      defaultValue={vendor.tax_id || ""}
                      onSubmit={(value) => {
                        value && updateVendor({ id: vendor.id, tax_id: value });
                      }}
                      disabled={!isPurchasingAdmin}
                    />
                  </HStack>
                </Box>
                <Box>
                  <HStack alignItems="center" w="max-content">
                    <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.400")}>
                      Supplier Category
                    </Text>
                    <EditableSelect
                      options={[
                        { label: "A", value: "A" },
                        { label: "B", value: "B" },
                        { label: "C", value: "C" },
                        { label: "X", value: "X" },
                      ]}
                      selectedValue={vendor.category || ""}
                      onSubmit={(value) => {
                        value && updateVendor({ id: vendor.id, category: value.toString() });
                      }}
                      disabled={!isPurchasingAdmin}
                    />
                  </HStack>
                </Box>
                <Box width="full">
                  <HStack alignItems="center" w="max-content">
                    <Text
                      fontSize="sm"
                      color={useColorModeValue("gray.600", "gray.400")}
                      whiteSpace="nowrap">
                      Description
                    </Text>
                    <EditableText
                      defaultValue={vendor.description}
                      onSubmit={(value) => {
                        value && updateVendor({ id: vendor.id, description: value });
                      }}
                      disabled={!isPurchasingAdmin}
                    />
                  </HStack>
                </Box>
              </Stack>

              <Flex
                direction={["column", "column", "row"]}
                justify="space-between"
                width={"100%"}
                gap={8}>
                <VStack
                  align="start"
                  width="100%"
                  p={5}
                  borderRadius="md"
                  spacing={4}
                  border="1px"
                  borderColor="chakra-border-color">
                  <HStack>
                    <Box
                      px={2}
                      py={1.5}
                      bg="purple.100"
                      borderRadius="md"
                      justifyContent="center"
                      alignItems="center">
                      <Icon as={MdCreditCard} color="purple.700" />
                    </Box>
                    <Heading size="md" fontWeight={"semibold"}>
                      Virtual Cards
                    </Heading>
                  </HStack>
                  <Divider />
                  <HStack width="100%" justify="space-between">
                    <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.400")}>
                      Is this vendor card enabled?
                    </Text>
                    <Switch
                      size="sm"
                      colorScheme="teal"
                      checked={vendor.is_virtual_card_enabled}
                      isDisabled={!isPurchasingAdmin}
                      onChange={(e) =>
                        updateVendor({ id: vendor.id, is_virtual_card_enabled: e.target.checked })
                      }
                    />
                  </HStack>
                </VStack>

                <VStack
                  align="start"
                  width="100%"
                  p={5}
                  borderRadius="md"
                  spacing={4}
                  border="1px"
                  borderColor="chakra-border-color">
                  <HStack>
                    <Box
                      px={2}
                      py={1.5}
                      bg="green.100"
                      borderRadius="md"
                      justifyContent="center"
                      alignItems="center">
                      <Icon
                        as={LiaPiggyBankSolid}
                        color="green.700"
                        justifySelf={"center"}
                        alignSelf={"center"}
                      />
                    </Box>
                    <Heading size="md" fontWeight={"semibold"}>
                      Spending
                    </Heading>
                  </HStack>
                  <Divider />
                  <SpendingGraph vendorId={vendor.id} />
                </VStack>
              </Flex>
              <Divider />
              <VStack width="100%" align="start" spacing={6}>
                <HStack justify="space-between" width="100%">
                  <Heading size="md">Transactions</Heading>
                </HStack>
                <VendorPurchasesTable vendor={vendor} />
              </VStack>
              <VStack width="100%" align="start" spacing={6}>
                <HStack justify="space-between" width="100%">
                  <Heading size="md">Payment Methods</Heading>
                </HStack>
                <EmptyState title="No payment methods yet" size="3xs" />
                <VStack width="100%" align="start" spacing={6}>
                  <HStack justify="space-between" width="100%">
                    <Heading size="md">Contacts</Heading>
                    <Button
                      leftIcon={<AddIcon />}
                      size={"sm"}
                      onClick={() => onOpenNewContact()}
                      isDisabled={!isPurchasingAdmin}>
                      Add New
                    </Button>
                  </HStack>
                  <VendorContactsTable vendor={vendor} />
                </VStack>
              </VStack>
            </VStack>
          }
        />
        <NewVendorContactModal
          isOpen={isOpenNewContact}
          onClose={onCloseNewContact}
          vendor={vendor}
        />
        <MergeVendorModalForm
          vendor={vendor}
          isOpen={isOpenMergeVendor}
          onClose={onCloseMergeVendor}
        />
      </>
    );
  }
  return <Text>Vendor not found</Text>;
};
