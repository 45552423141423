import { CheckCircleIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ConfirmationModal,
  EditableText,
  Header,
  RichTextEditor,
  SplitPage,
} from "@sciencecorp/helix-components";
import React, { useState } from "react";
import { FiCheckCircle } from "react-icons/fi";
import { useParams } from "react-router";
import { useDeleteFile } from "../../api/blob_files";
import { useCreateServiceRequestEvent } from "../../api/service_request_events";
import {
  invalidateServiceRequest,
  useGetServiceRequestPurchases,
  useGetServiceRequestQuery,
  useUpdateServiceRequest,
} from "../../api/service_requests";
import { useGetServiceQuery } from "../../api/services";
import { useCurrentUserQuery, userHasRole } from "../../api/user";
import { useCurrency } from "../../contexts/CurrencyContext";
import { Money } from "../../helpers/Money";
import { LinkifyText } from "../Purchasing/util";
import { BlobUploadButton } from "../shared/BlobUploadButton";
import { DeleteableFileDownload } from "../shared/DeleteableFileDownload";
import { SpendingAuthorityInfo } from "../shared/SpendingAuthorityInfo";
import { ServiceRequestTimeline } from "./components/RequestTimeline";
import { ServiceRequestDetailsSidebar } from "./components/ServiceRequestDetailsSidebar";
import { ServiceRequestPurchases } from "./components/ServiceRequestPurchases";
import { ServiceRequestStatus } from "./components/ServiceRequestStatus";
import { LinkToPurchase } from "./LinkToPurchase";
import { serviceStatusMap } from "./utils";

const closeablePurchaseStatuses = ["new", "needs_approval", "declined", "awaiting_purchase"];

export const ServiceRequestDetails = () => {
  const currency = useCurrency();
  const { id } = useParams();
  if (!id) return null;
  const {
    data: serviceRequest,
    isLoading: isLoadingServiceRequest,
    isRefetching: isRefetchingServiceRequest,
    isError,
  } = useGetServiceRequestQuery(Number(id));
  const { data: service, isLoading: isLoadingService } = useGetServiceQuery(
    serviceRequest?.service_id!
  );
  const currentUserQuery = useCurrentUserQuery();
  const isServiceAdmin = userHasRole(currentUserQuery, "services_admin") || false;
  const currentUser = currentUserQuery?.data;
  const { data: purchases } = useGetServiceRequestPurchases(serviceRequest?.id!);
  const { mutate: createServiceEvent } = useCreateServiceRequestEvent(+id);
  const { mutate: updateServiceRequest, isLoading: isLoadingUpdate } = useUpdateServiceRequest(+id);
  const { mutate: deleteFile } = useDeleteFile(() => invalidateServiceRequest(+id));
  const [content, setContent] = useState("");
  const [rawText, setRawText] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const purchasesInCloseableState =
    purchases?.every((purchase) => closeablePurchaseStatuses.includes(purchase.status)) || false;

  const handleComment = () => {
    if (serviceRequest && currentUser) {
      setSession((prev) => ++prev);
      createServiceEvent(
        {
          user_id: currentUser.id,
          service_request_id: serviceRequest.id,
          event_type: "comment",
          event_info: { description: content, raw_text: JSON.parse(rawText) },
          slack_message: `has commented: "${content}"`,
        },
        {
          onSuccess: () => {
            setContent("");
            setRawText("");
          },
        }
      );
    }
  };

  const canClose =
    serviceRequest?.user_in_fulfilling_team ||
    currentUser?.id === serviceRequest?.requesting_user.id ||
    isServiceAdmin;
  const [session, setSession] = useState(0);

  if (isLoadingServiceRequest || isLoadingService) {
    return <Spinner />;
  }

  if (isError) {
    return <Text>Failed to load service request</Text>;
  }
  return (
    <Box pb={40}>
      <Header
        title={`${service?.name} #${serviceRequest.id}`}
        badge={{
          label: serviceRequest.status || "",
          colorScheme:
            serviceStatusMap.find((ele) => ele.value === serviceRequest.status)?.color || "gray",
        }}
        crumbs={[
          { label: "Teams", url: "/teams" },
          {
            label: `${service?.team.name}`,
            url: `/teams/${service?.team.id}/services`,
          },
        ]}
        crumbsColor="teal.500"
      />

      <SplitPage
        sidebarWidth="350px"
        sidebarWidthXL="450px"
        breakpoint="lg"
        sidebar={
          <>
            {serviceRequest.purchase_id && (
              <LinkToPurchase purchaseId={serviceRequest.purchase_id} />
            )}
            {serviceRequest && currentUser && service && (
              <ServiceRequestDetailsSidebar
                serviceRequest={serviceRequest}
                isServiceAdmin={isServiceAdmin}
                currentUser={currentUser}
                service={service}
              />
            )}
          </>
        }
        main={
          <Stack>
            <Flex gap={4} direction={{ base: "column", lg: "row" }} width="100%" marginBottom={"5"}>
              <VStack
                flex="1"
                align="flex-start"
                border={"1px"}
                borderRadius={"md"}
                borderColor={useColorModeValue("gray.200", "gray.600")}
                padding={"4"}>
                <VStack align="flex-start" w="100%">
                  <Heading size="md">Description</Heading>
                  <Box w="100%">
                    <EditableText
                      preview={
                        <LinkifyText
                          text={serviceRequest?.request_description || ""}
                          maxLinkChars={50}
                        />
                      }
                      multiline
                      defaultValue={serviceRequest?.request_description || ""}
                      onSubmit={(value) => {
                        updateServiceRequest({
                          id: serviceRequest.id,
                          request_description: value || "",
                        });
                      }}
                      disabled={currentUser?.id !== serviceRequest?.requesting_user.id}
                    />
                  </Box>
                </VStack>
                {currentUser?.id === serviceRequest.requesting_user.id ? (
                  <VStack align="start">
                    <Heading size="md">Attachments</Heading>
                    <HStack maxW="100%" flexWrap="wrap">
                      {serviceRequest?.uploaded_files?.map((file) => (
                        <DeleteableFileDownload
                          key={file.filename}
                          file={file}
                          deleteFile={deleteFile}
                        />
                      ))}
                      <BlobUploadButton
                        fileableColumn="uploaded_files"
                        recordId={serviceRequest?.id}
                        recordType={"ServiceRequest"}
                        onSuccessCallback={() => invalidateServiceRequest(+id)}
                      />
                    </HStack>
                  </VStack>
                ) : serviceRequest?.uploaded_files?.length ? (
                  <VStack align="start">
                    <Heading size="md">Attachments</Heading>
                    <HStack maxW="100%" flexWrap="wrap">
                      {serviceRequest?.uploaded_files?.map((file) => (
                        <DeleteableFileDownload
                          key={file.filename}
                          file={file}
                          deleteFile={deleteFile}
                        />
                      ))}
                    </HStack>
                  </VStack>
                ) : null}
              </VStack>

              <SpendingAuthorityInfo
                spendingAuthority={serviceRequest.spending_authority}
                pendingAmount={serviceRequest.total_cost}
                session={session}
                rootSpendingAuthority={serviceRequest.root_spending_authority}
              />
            </Flex>
            {serviceRequest && (
              <>
                <ServiceRequestTimeline serviceRequest={serviceRequest} />
                {currentUser && (
                  <>
                    <ServiceRequestPurchases
                      serviceRequest={serviceRequest}
                      currentUser={currentUser}
                      purchases={purchases || []}
                      isServicesAdmin={isServiceAdmin}
                    />
                    <ServiceRequestStatus
                      serviceRequest={serviceRequest}
                      isServicesAdmin={isServiceAdmin}
                      currentUser={currentUser}
                      purchaseCost={Money.sum(
                        Money.zero(currency),
                        ...(purchases || []).map((p) => p.line_items_sum)
                      )}
                    />
                  </>
                )}
              </>
            )}
            <Stack direction="column" width="100%">
              <HStack align="baseline" width="100%">
                <Avatar size="sm" src={currentUser?.picture_uri} />
                <Box flex="1">
                  <RichTextEditor
                    session={session}
                    onChange={(rich, raw) => {
                      setContent(raw ?? "");
                      setRawText(rich);
                    }}
                    placeholder="Leave a comment..."
                  />
                </Box>
              </HStack>
              <HStack justify="flex-end">
                {serviceRequest.status !== "complete" && serviceRequest.status !== "abandoned" ? (
                  <Menu placement="bottom-end">
                    <MenuButton
                      isLoading={isLoadingUpdate || isRefetchingServiceRequest}
                      as={Button}
                      isDisabled={!canClose}
                      leftIcon={<FiCheckCircle />}
                      rightIcon={<ChevronDownIcon />}>
                      Close Request
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        isDisabled={isLoadingUpdate}
                        icon={<CheckCircleIcon color="green.600" boxSize={4} />}
                        onClick={() => {
                          if (currentUser) {
                            createServiceEvent(
                              {
                                service_request_id: serviceRequest.id,
                                user_id: currentUser.id,
                                event_type: "closed",
                                event_info: {
                                  status: "complete",
                                  description: "closed this request as",
                                },
                                slack_message: "has closed this request as complete",
                              },
                              {
                                onSuccess: () => {
                                  updateServiceRequest({
                                    id: serviceRequest.id,
                                    status: "complete",
                                  });
                                },
                              }
                            );
                          }
                        }}>
                        Close request as complete
                      </MenuItem>
                      <MenuItem
                        isDisabled={isLoadingUpdate}
                        icon={<CheckCircleIcon color="orange.600" boxSize={4} />}
                        onClick={() => onOpen()}>
                        Close request as abandoned
                      </MenuItem>
                    </MenuList>
                  </Menu>
                ) : (
                  <Button
                    leftIcon={<FiCheckCircle />}
                    isLoading={isLoadingUpdate || isRefetchingServiceRequest}
                    onClick={() => {
                      if (currentUser) {
                        createServiceEvent(
                          {
                            service_request_id: serviceRequest.id,
                            user_id: currentUser.id,
                            event_type: "reopened",
                            event_info: {
                              description: "reopened the request",
                            },
                            slack_message: "has reopened the request",
                          },
                          {
                            onSuccess: () => {
                              updateServiceRequest({
                                id: serviceRequest.id,
                                status: "review",
                              });
                            },
                          }
                        );
                      }
                    }}>
                    Reopen Request
                  </Button>
                )}
                <Button
                  onClick={handleComment}
                  isDisabled={
                    serviceRequest.status === "completed" || serviceRequest.status === "abandoned"
                  }>
                  Comment
                </Button>
              </HStack>
            </Stack>
            <ConfirmationModal
              colorScheme="red"
              isOpen={isOpen}
              onClose={onClose}
              header="Close Request"
              confirmText={purchasesInCloseableState ? "Abandon Request" : "Review Purchases"}
              onClick={() => {
                if (currentUser) {
                  if (purchasesInCloseableState) {
                    createServiceEvent(
                      {
                        service_request_id: serviceRequest.id,
                        user_id: currentUser.id,
                        event_type: "closed",
                        event_info: {
                          status: "abandoned",
                          description: "closed this request as",
                        },
                        slack_message: "has closed this request as abandoned",
                      },
                      {
                        onSuccess: () => {
                          updateServiceRequest({
                            id: serviceRequest.id,
                            status: "abandoned",
                          });
                        },
                      }
                    );
                  } else {
                    onClose();
                  }
                }
              }}>
              {purchasesInCloseableState ? (
                <Text>
                  Are you sure you want to abandon this request? Abandoning the request will also
                  cancel any associated purchase requests.
                </Text>
              ) : (
                <Text>
                  To abandon this request, please review all associated purchases and reassign them
                  to a different spending authority.
                </Text>
              )}
            </ConfirmationModal>
          </Stack>
        }
      />
    </Box>
  );
};
