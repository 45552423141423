import { Alert, Flex, Spinner, Stack, Tag, Text } from "@chakra-ui/react";
import { RecordLink } from "@sciencecorp/helix-components";
import { humanize, underscore } from "inflection";
import React, { useEffect, useState } from "react";
import { MinimalSpendingAuthority, useGetSpendingAuthority } from "../../api/spending_authority";
import {
  SpendingAuthorityOption,
  SpendingAuthorityTree,
  SpendingAuthorityValue,
} from "../../api/spending_authority_tree";
import { useUserSpendingAuthorityOptions } from "../../api/user";
import { EditableTreeSelect } from "../shared/EditableTreeSelect";
import { TreeSelect } from "../shared/TreeSelect";

type NullableSpendingAuthority = MinimalSpendingAuthority | null;

interface SpendingAuthoritySelectProps {
  onChange: (spendingAuthority: NullableSpendingAuthority) => void;
  spendingAuthority: NullableSpendingAuthority | null;
  excludeTypes?: SpendingAuthorityTree["type"][];
  disabled?: boolean;
}
interface EditableSpendingAuthoritySelectProps
  extends Omit<SpendingAuthoritySelectProps, "onChange"> {
  onSubmit: (spendingAuthority: NullableSpendingAuthority) => void;
}

const getLeaves = (options: SpendingAuthorityOption[]): SpendingAuthorityOption[] => {
  return options.flatMap((option) => {
    if (option.children) {
      return getLeaves(option.children);
    } else {
      return [option];
    }
  });
};

const findOption = (
  options: SpendingAuthorityOption[],
  spendingAuthority: NullableSpendingAuthority
): SpendingAuthorityOption | undefined => {
  const leaves = getLeaves(options);
  return leaves.find((option) => {
    return (
      spendingAuthority &&
      option.value?.spendingAuthority?.id === spendingAuthority?.id &&
      option.value?.spendingAuthority?.type === spendingAuthority?.type
    );
  });
};

export const SpendingAuthoritySelect = ({
  disabled = false,
  onChange,
  spendingAuthority,
}: SpendingAuthoritySelectProps) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [defaultValue, setDefaultValue] = useState<SpendingAuthorityValue>();
  const userSpendingAuthorityOptions = useUserSpendingAuthorityOptions(
    searchTerm && searchTerm.trim() !== "" ? searchTerm : undefined
  );

  useEffect(() => {
    if (spendingAuthority) {
      setDefaultValue(
        findOption(userSpendingAuthorityOptions.data || [], spendingAuthority)?.value
      );
    }
  }, [spendingAuthority, userSpendingAuthorityOptions.data]);

  return (
    <TreeSelect
      testId="spending-authority-select-tree"
      isLoading={userSpendingAuthorityOptions.isLoading}
      options={userSpendingAuthorityOptions.data || []}
      onChange={(value) => {
        if (value && value.spendingAuthority) {
          onChange(value.spendingAuthority);
        }
      }}
      isDisabled={disabled}
      defaultValue={defaultValue}
      renderOption={RenderSpendingAuthorityOption}
      onSearchChanged={(search) => {
        setSearchTerm(search);
      }}
    />
  );
};

interface SpendingAuthorityPreviewProps {
  spendingAuthorityId: number;
  spendingAuthorityType: MinimalSpendingAuthority["type"];
  rootSpendingAuthorityId?: number;
  rootSpendingAuthorityType?: MinimalSpendingAuthority["type"];
  maxWidth?: string;
  width?: string;
  outsideOfAttributesTable?: boolean;
}

export const SpendingAuthorityPreview = ({
  spendingAuthorityId,
  spendingAuthorityType,
  rootSpendingAuthorityId,
  rootSpendingAuthorityType,
  maxWidth,
  outsideOfAttributesTable,
}: SpendingAuthorityPreviewProps) => {
  const spendingAuthorityQuery = useGetSpendingAuthority(
    spendingAuthorityType !== "RevenueItem" ? spendingAuthorityId : undefined,
    spendingAuthorityType !== "RevenueItem" ? spendingAuthorityType : undefined
  );

  const rootSpendingAuthorityQuery = useGetSpendingAuthority(
    rootSpendingAuthorityType !== "RevenueItem" ? rootSpendingAuthorityId : undefined,
    rootSpendingAuthorityType !== "RevenueItem" ? rootSpendingAuthorityType : undefined
  );

  if (!rootSpendingAuthorityType && !rootSpendingAuthorityId && spendingAuthorityQuery.isSuccess) {
    return (
      <Stack direction={outsideOfAttributesTable ? "row" : "column"} flexWrap="wrap">
        {spendingAuthorityType !== "ServiceRequest" && (
          <RecordLink
            maxWidth={maxWidth || "17ch"}
            type={spendingAuthorityQuery.data?.type}
            identifier={spendingAuthorityQuery.data?.source_of_authority?.name ?? ""}
            link={spendingAuthorityQuery.data?.source_of_authority?.app_href}
            disableTooltip={false}
            width={outsideOfAttributesTable ? "max-content" : "100%"}
            justifyContent="space-between"
          />
        )}
        <RecordLink
          maxWidth={maxWidth || "14ch"}
          type={humanize(underscore(spendingAuthorityType))}
          identifier={spendingAuthorityQuery.data.identifier}
          link={spendingAuthorityQuery.data.link}
          disableTooltip={false}
          justifyContent="space-between"
          width={outsideOfAttributesTable ? "max-content" : "100%"}
        />
      </Stack>
    );
  }

  if (spendingAuthorityQuery.isSuccess) {
    return (
      <Stack direction={outsideOfAttributesTable ? "row" : "column"} flexWrap="wrap">
        {spendingAuthorityType === "ServiceRequest" && (
          <RecordLink
            maxWidth={maxWidth || "14ch"}
            type={humanize(underscore(spendingAuthorityType))}
            identifier={spendingAuthorityQuery.data.identifier}
            link={spendingAuthorityQuery.data.link}
            disableTooltip={false}
            justifyContent="space-between"
            width={outsideOfAttributesTable ? "max-content" : "100%"}
          />
        )}

        {rootSpendingAuthorityType !== "RevenueItem" && (
          <>
            {rootSpendingAuthorityQuery.data?.budget_group && (
              <RecordLink
                maxWidth={maxWidth || "17ch"}
                type={"BudgetGroup"}
                identifier={rootSpendingAuthorityQuery.data?.budget_group?.name ?? ""}
                link={rootSpendingAuthorityQuery.data?.budget_group?.app_href}
                disableTooltip={false}
                width={outsideOfAttributesTable ? "max-content" : "100%"}
                justifyContent="space-between"
              />
            )}

            <RecordLink
              maxWidth={maxWidth || "17ch"}
              type={rootSpendingAuthorityQuery.data?.type}
              identifier={rootSpendingAuthorityQuery.data?.source_of_authority?.name ?? ""}
              link={rootSpendingAuthorityQuery.data?.source_of_authority?.app_href}
              disableTooltip={false}
              width={outsideOfAttributesTable ? "max-content" : "100%"}
              justifyContent="space-between"
            />

            <RecordLink
              maxWidth={maxWidth || "14ch"}
              type={humanize(underscore(rootSpendingAuthorityType ?? ""))}
              identifier={rootSpendingAuthorityQuery.data?.identifier ?? ""}
              link={rootSpendingAuthorityQuery.data?.link}
              disableTooltip={false}
              justifyContent="space-between"
              width={outsideOfAttributesTable ? "max-content" : "100%"}
            />
          </>
        )}
      </Stack>
    );
  } else if (spendingAuthorityQuery.isLoading && spendingAuthorityQuery.isFetching) {
    return <Spinner />;
  } else if (spendingAuthorityQuery.isError) {
    return <Alert status="error">Error while getting spending authority</Alert>;
  }
  return <></>;
};

const SpendingAuthorityIcon = ({ option }: { option: SpendingAuthorityOption }): JSX.Element => {
  return <></>;
};

function RenderSpendingAuthorityOption<V>(option: SpendingAuthorityOption): JSX.Element {
  return (
    <Flex width={"100%"} height={"100%"} justifyContent={"start"} alignItems={"center"}>
      <SpendingAuthorityIcon option={option} />
      <Text fontWeight={"normal"} alignItems={"center"}>
        {option.type && `${option.type}: `}
        {option.label}
      </Text>
    </Flex>
  );
}

export const EditableSpendingAuthoritySelect = ({
  onSubmit,
  spendingAuthority,
  excludeTypes = [],
  disabled = false,
}: EditableSpendingAuthoritySelectProps): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [defaultValue, setDefaultValue] = useState<SpendingAuthorityValue>();
  const userSpendingAuthorityOptions = useUserSpendingAuthorityOptions(searchTerm);

  useEffect(() => {
    if (spendingAuthority) {
      setDefaultValue(
        findOption(userSpendingAuthorityOptions.data || [], spendingAuthority)?.value
      );
    }
  }, [spendingAuthority, userSpendingAuthorityOptions.data]);

  return (
    <EditableTreeSelect
      disabled={disabled}
      options={(userSpendingAuthorityOptions.data || []).filter(
        (ops) => !excludeTypes.includes(ops.type as (typeof excludeTypes)[0])
      )}
      isLoading={userSpendingAuthorityOptions.isLoading}
      defaultValue={defaultValue}
      renderOption={RenderSpendingAuthorityOption}
      preview={() => {
        if (userSpendingAuthorityOptions.isLoading) {
          return <Spinner />;
        }
        return spendingAuthority ? (
          <SpendingAuthorityPreview
            spendingAuthorityType={spendingAuthority.type}
            spendingAuthorityId={spendingAuthority.id}
          />
        ) : (
          <Tag colorScheme={"red"}>No spending authority</Tag>
        );
      }}
      onSubmit={(selectedValue) => {
        if (!selectedValue || !selectedValue.spendingAuthority) {
          return;
        }
        return onSubmit(selectedValue.spendingAuthority);
      }}
      onSearchChanged={(search) => {
        setSearchTerm(search);
      }}
    />
  );
};
