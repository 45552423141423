import {
  Badge,
  Center,
  Checkbox,
  Collapse,
  Flex,
  HStack,
  Heading,
  IconButton,
  Spinner,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Column, EditableSelect, RecordLink } from "@sciencecorp/helix-components";
import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { MdOutlineArrowDropDown, MdOutlineArrowRight } from "react-icons/md";
import { useBudgetItems, useGetBudgetSummary } from "../../api/budget";
import { BudgetItemShowData, useUpdateBudgetItemQuery } from "../../api/budget_items";
import { useCurrency } from "../../contexts/CurrencyContext";
import { Money } from "../../helpers/Money";
import { MoneyText } from "../MoneyText";
import { BudgetItemList } from "./SpendingCard";
import { useGeneralLedgerCodeOptions } from "../../api/options";

export const SubTeamBudgetItems = ({
  budgetId,
  parentTeamBudget,
  selectedBudgetItemIds,
  handleSelectItem,
  timeFilter,
  editable,
  showArchived,
}: {
  budgetId: number;
  selectedBudgetItemIds: number[];
  handleSelectItem: (itemId: number) => void;
  parentTeamBudget: number;
  showArchived?: boolean;
  editable: boolean;
  timeFilter: string;
}) => {
  const currency = useCurrency();
  const endDate = DateTime.now().endOf("year");

  const budgetQuery = useGetBudgetSummary(budgetId, endDate);
  const budgetItemsQuery = useBudgetItems({
    budget_id: budgetId,
    end_date: endDate,
  });

  const glCodeOptions = useGeneralLedgerCodeOptions();

  const { mutate: updateBudgetItem } = useUpdateBudgetItemQuery(budgetId, parentTeamBudget);
  const [expanded, setExpanded] = useState(false);
  const renderSubTeamBudgets = () => {
    const sub_team_budgets = budgetQuery.data?.sub_team_budgets || [];
    if (sub_team_budgets && sub_team_budgets.length > 0) {
      return sub_team_budgets.map((subTeamBudget) => (
        <SubTeamBudgetItems
          key={subTeamBudget!.budget.id}
          selectedBudgetItemIds={selectedBudgetItemIds}
          handleSelectItem={handleSelectItem}
          budgetId={subTeamBudget!.budget.id}
          parentTeamBudget={parentTeamBudget}
          editable={editable}
          timeFilter={timeFilter}
        />
      ));
    }
    return null;
  };

  const sumSubTeamBudgets = useMemo(
    () =>
      Money.sum(
        Money.zero(currency),
        ...(budgetQuery.data?.sub_team_budgets || []).map(
          (stb) => stb.summary.spent_amount || Money.zero(currency)
        )
      ),
    [budgetQuery.data?.sub_team_budgets]
  );

  const isDisabled = (budgetItem: BudgetItemShowData) => {
    return !editable || budgetItem.is_archived;
  };

  const columns: Column[] = [
    {
      label: "",
      weight: 0.25,
      render: (budgetItem: BudgetItemShowData) =>
        !budgetItem.archived_at && editable ? (
          <Checkbox
            isDisabled={isDisabled(budgetItem)}
            colorScheme="teal"
            isChecked={selectedBudgetItemIds.includes(budgetItem.id)}
            onChange={() => handleSelectItem(budgetItem.id)}
          />
        ) : null,
    },
    {
      label: "Item",
      weight: 2,
      render: (budgetItem: BudgetItemShowData) => {
        return (
          <VStack align="start">
            <RecordLink
              link={`/teams/${budgetId}/budget/${budgetItem.id}`}
              type=""
              identifier={budgetItem.item_name}></RecordLink>
            {budgetItem.archived_at && <Tag colorScheme="red">Archived</Tag>}
          </VStack>
        );
      },
    },
    {
      label: "GL Code",
      render: (budgetItem: BudgetItemShowData) => (
        <EditableSelect
          options={glCodeOptions}
          selectedValue={budgetItem.general_ledger_code?.id.toString()}
          onSubmit={(value) => {
            if (value)
              updateBudgetItem({
                id: budgetItem.id,
                general_ledger_code_id: Number(value),
              });
          }}
        />
      ),
    },

    {
      label: "Spent",
      render: (budgetItem: BudgetItemShowData) => (
        <MoneyText
          fontWeight="bold"
          money={budgetItem.summary?.spent_amount}
          formatOptions={{ compact: "never" }}
        />
      ),
    },
    {
      label: "Pending Approval",
      render: (budgetItem: BudgetItemShowData) => {
        const fontColor =
          (budgetItem.summary?.pending_approval_amount.cents.toNumber() || 0) > 0
            ? "red.500"
            : "auto";
        return (
          <HStack>
            <MoneyText
              color={fontColor}
              money={budgetItem.summary?.pending_approval_amount}
              formatOptions={{ compact: "never" }}
            />
            {(budgetItem.summary?.pending_approval_records_count || 0) > 0 && (
              <Badge colorScheme="red">
                {budgetItem.summary?.pending_approval_records_count || 0}
              </Badge>
            )}
          </HStack>
        );
      },
    },
  ];

  if (budgetQuery.isError || budgetItemsQuery.isError) {
    return <Text>Error Loading Subteam budgets</Text>;
  }

  if (budgetQuery.isLoading || budgetItemsQuery.isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <Flex w="100%" direction="column" p={2}>
      <HStack justify="space-between">
        <HStack>
          <IconButton
            variant="ghost"
            aria-label="expand list"
            onClick={() => setExpanded(!expanded)}
            icon={expanded ? <MdOutlineArrowDropDown /> : <MdOutlineArrowRight />}
          />
          <Heading size="md">{budgetQuery.data?.budgetable?.name}</Heading>
        </HStack>
        <MoneyText
          money={
            expanded
              ? budgetQuery.data?.summary.spent_amount
              : (budgetQuery.data?.summary.spent_amount || Money.zero(currency)).add(
                  sumSubTeamBudgets
                )
          }
          formatOptions={{ compact: "never" }}
          fontWeight="semibold"
        />
      </HStack>

      <Collapse in={expanded} animateOpacity>
        <Flex mt={2} flexDirection="column">
          <BudgetItemList
            budgetItemsQuery={budgetItemsQuery}
            columns={columns}
            showArchived={showArchived}
          />
          {renderSubTeamBudgets()}
        </Flex>
      </Collapse>
    </Flex>
  );
};
