import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  Box,
  Popover,
  PopoverContent,
  Text,
  HStack,
  Checkbox,
  Flex,
  Input,
  useColorModeValue,
  VStack,
  useDisclosure,
  PopoverAnchor,
} from "@chakra-ui/react";
import { useTeamOptions, useUserOptions } from "../../../../api/options";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Option } from "@sciencecorp/helix-components";
import { set } from "zod";

export const TeamAndEmployeeSelector = ({
  setSelectedUsers,
  setSelectedTeams,
  selectedTeams,
  selectedUsers,
  teamOptions,
  userOptions,
}) => {
  const [filteredTeamOptions, setFilteredTeamOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [filteredUserOptions, setFilteredUserOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const [search, setSearch] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const handleSearchChange = (e) => {
    const search = e.target.value.toLowerCase();
    setSearch(search);
    const filteredTeams = teamOptions.filter((team) => team.label.toLowerCase().includes(search));
    const filteredUsers = userOptions.filter((user) => user.label.toLowerCase().includes(search));

    setFilteredTeamOptions(filteredTeams);
    setFilteredUserOptions(filteredUsers);
  };

  useEffect(() => {
    setFilteredTeamOptions(teamOptions);
    setFilteredUserOptions(userOptions);
  }, [teamOptions, userOptions]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Popover autoFocus={false} isOpen={isOpen} onClose={handleClose} matchWidth>
      <PopoverAnchor>
        <HStack
          border={"1px solid"}
          borderColor={"inherit"}
          borderRadius={"md"}
          w={"100%"}
          bg={useColorModeValue("auto", "gray.800")}>
          <Box pl={2} width={"100%"} display={["none", "none", "flex"]}>
            <Input
              width={"100%"}
              onFocus={onOpen}
              variant={"ghost"}
              bg={useColorModeValue("auto", "gray.800")}
              type="select"
              value={search}
              ref={inputRef}
              onBlur={(e) => {
                if (!popoverRef.current?.contains(e.relatedTarget)) {
                  handleClose();
                }
              }}
              onChange={(e) => handleSearchChange(e)}
            />
            <Flex
              pr={2}
              justifyContent={"center"}
              alignItems={"center"}
              onClick={() => {
                inputRef.current?.select();
              }}>
              {" "}
              <ChevronDownIcon boxSize={4} />
            </Flex>
          </Box>
        </HStack>
      </PopoverAnchor>
      <PopoverContent minWidth={"100%"} maxWidth={"90vw"}>
        <Box ref={popoverRef}>
          <Flex
            direction="column"
            w={"100%"}
            maxH={"50vh"}
            overflowY={"auto"}
            overflowX={"hidden"}
            textOverflow={"ellipsis"}
            gap={0}>
            {filteredTeamOptions.length > 0 && (
              <Box bg={useColorModeValue("gray.50", "gray.600")} px={3}>
                <Text textTransform="uppercase" fontSize="xs" fontWeight="semibold">
                  Teams
                </Text>
              </Box>
            )}
            {filteredTeamOptions.map((team, idx) => (
              <OptionComponentDefault
                key={`${idx}-${team.value}`}
                option={team}
                setSelectedItems={setSelectedTeams}
                selectedItems={selectedTeams}
                setSearch={setSearch}
              />
            ))}
            {filteredUserOptions.length > 0 && (
              <Box bg={useColorModeValue("gray.50", "gray.600")} px={3}>
                <Text textTransform="uppercase" fontSize="xs" fontWeight="semibold">
                  Users
                </Text>
              </Box>
            )}
            {filteredUserOptions.map((user, idx) => (
              <OptionComponentDefault
                key={`${idx}-${user.value}`}
                option={user}
                setSelectedItems={setSelectedUsers}
                selectedItems={selectedUsers}
                setSearch={setSearch}
              />
            ))}
            {filteredUserOptions.length === 0 && filteredTeamOptions.length === 0 && (
              <Text textAlign={"center"} py={5}>
                No results found
              </Text>
            )}
          </Flex>
        </Box>
      </PopoverContent>
    </Popover>
  );
};

const OptionComponentDefault = ({
  option,
  selectedItems,
  setSelectedItems,
  setSearch,
}: {
  option: Option;
  selectedItems: { id: number; name: string }[];
  setSelectedItems: any;
  setSearch: any;
}) => {
  const isChecked = useMemo(() => {
    return selectedItems.some((item) => Number(item.id) === Number(option.value));
  }, [selectedItems, option]);

  const handleChange = (option) => {
    setSelectedItems((prev) => {
      if (isChecked) {
        return prev.filter((item) => item.id !== option.value);
      } else {
        return [...prev, { id: option.value, name: option.label }];
      }
    });
    setSearch("");
  };

  return (
    <HStack px={5} py={2} onClick={() => handleChange(option)} _hover={{ cursor: "pointer" }}>
      <Checkbox key={option.value} colorScheme="teal" value={option.value} isChecked={isChecked} />
      <Text fontWeight={isChecked ? "semibold" : "normal"}>{option.label}</Text>
    </HStack>
  );
};
