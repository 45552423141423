import React, { useCallback, useMemo, useState } from "react";
import {
  buildFacets,
  CollectionPage,
  ConfirmationModal,
  Header,
  RecordLink,
  useCollection,
} from "@sciencecorp/helix-components";
import {
  Avatar,
  Box,
  Text,
  HStack,
  Icon,
  IconButton,
  useColorModeValue,
  Tag,
  Flex,
  Spinner,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { InventoryData, InventoryShowData } from "../../../api/inventory";
import { titleize, humanize } from "inflection";
import { BiCube } from "react-icons/bi";
import { useSearchInventories } from "../../../api/inventory";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { FaEllipsisV } from "react-icons/fa";
import { useUpdateInventory } from "../../../api/inventory";
import { inventoryStatusColor } from "../util";
import { RepeatIcon } from "@chakra-ui/icons";

export const ArchivedInventoryPage = () => {
  const [currentInventoryId, setCurrentInventoryId] = useState<number | null>(null);
  const { pagination, order, onPagination, onOrder, facets, onFacets, filters } = useCollection();
  const { search, debouncedSearch } = useDebouncedSearch();
  const INVENTORY_AGGREGATIONS_QUERY = ["category", "status", "users", "sites"];
  const toast = useToast();
  const { data, isLoading, isSuccess } = useSearchInventories({
    term: search || "*",
    filters: { is_archived: true },
    aggs: INVENTORY_AGGREGATIONS_QUERY,
    pagination: { per_page: -1, page: 1 },
    order,
  });
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: updateInventory } = useUpdateInventory();

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  const handleRestoreClick = () => {
    navigate("/inventory/items");
    updateInventory({ id: Number(currentInventoryId), is_archived: false });
    toast({
      title: "Inventory item restored",
      status: "success",
      duration: 3000,
    });
  };

  const columns = useMemo(
    () => [
      {
        label: "Item Name",
        render: (inventory: InventoryData) => (
          <RecordLink
            type=""
            icon={<Icon as={BiCube} ml={2} />}
            identifier={inventory.name}
            link={`/inventory/items/${inventory.id}/details`}
          />
        ),
      },
      {
        label: "Category",
        render: (inventory: InventoryData) => titleize(humanize(inventory.category)),
      },
      {
        label: "Stock",
        render: (inventory: InventoryShowData) => inventory.stock,
      },
      {
        label: "Status",
        render: (inventory: InventoryShowData) => (
          <Tag
            colorScheme={inventoryStatusColor(String(inventory.status))}
            flexShrink={0}
            whiteSpace="nowrap"
            size={["sm", "md"]}>
            {titleize(humanize(String(inventory.status)))}
          </Tag>
        ),
      },
      {
        label: "Owner",
        render: (inventory: InventoryShowData) => (
          <HStack>
            {inventory.users && inventory.users.length > 0 ? (
              inventory.users.map((user) => (
                <HStack key={user.id}>
                  <Avatar size="sm" name={user.user.name} src={user.user.picture_uri} />
                </HStack>
              ))
            ) : (
              <Text fontStyle="italic" color={useColorModeValue("gray.400", "gray.200")}>
                No owners associated
              </Text>
            )}
          </HStack>
        ),
      },
      {
        label: "Archived",
        render: (inventory: InventoryShowData) => (
          <Text>
            {inventory.archived_at ? (
              inventory.archived_at.toLocaleString(DateTime.DATETIME_MED)
            ) : (
              <Text>Unknown</Text>
            )}
          </Text>
        ),
      },
      {
        label: "",
        render: (inventory: InventoryShowData) => (
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<FaEllipsisV />}
              variant="ghost"
              colorScheme="gray"
              size="sm"
              aria-label="Options"
            />
            <MenuList>
              <MenuItem
                icon={<RepeatIcon />}
                onClick={() => {
                  onOpen();
                  setCurrentInventoryId(inventory.id);
                }}>
                Restore
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
    ],
    [data]
  );

  if (isLoading) {
    return <Spinner />;
  } else if (isSuccess) {
    return (
      <Flex direction="column" gap={4}>
        <Box w="100%" overflowX="scroll">
          <Header
            title="Archive"
            crumbs={[{ url: "/inventory/items", label: "Inventory" }]}
            crumbsColor="teal.500"
          />
          <CollectionPage
            columns={columns}
            items={data?.results || []}
            pagination={data?.pagination || pagination}
            isLoading={isLoading}
            order={order}
            onPagination={onPagination}
            onOrder={onOrder}
            facets={populatedFacets}
            onFacets={onFacets}
            facetsProps={{ debouncedSearch: debouncedSearch, background: true, search: true }}
          />
        </Box>
        <ConfirmationModal
          colorScheme="teal"
          isOpen={isOpen}
          onClose={onClose}
          confirmText="Continue"
          header="Restore Inventory Item"
          children="Restoring this item will place it back on the item list"
          onClick={handleRestoreClick}
        />
      </Flex>
    );
  } else {
    return <Text>Error loading inventory items.</Text>;
  }
};
