import { z } from "zod";
import { api } from ".";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { createSearchResponseSchema } from "./shared";
import { indexParams, searchParams, SearchParams } from "./collection_types";
import { zodParse } from "./zodParse";
import { CREDENTIAL_PACKAGE_USERS_BASE_URL } from "./credential_package_users";

export const CREDENTIAL_PACKAGES_BASE_URL = "credential_packages";
export const CREDENTIAL_PACKAGE_CREDENTIALS_BASE_URL = "credential_package_credentials";

export const credentialPackageSchema = z.object({
  id: z.number(),
  name: z.string(),
  is_draft: z.boolean(),
});

export const credentialPackageCredentialSchema = z.object({
  id: z.number(),
  credential_package_id: z.number(),
  credential_id: z.number(),
});

export const credentialPackageShowSchema = credentialPackageSchema.extend({
  assigned_user_ids: z.array(z.number()),
  credential_package_credentials: z.array(
    z.object({
      id: z.number(),
      credential: z.object({
        id: z.number(),
        name: z.string(),
      }),
    })
  ),
  total_users_completed_count: z.number(),
  total_users_count: z.number(),
  credentials_count: z.number(),
  trainings_count: z.number(),
});

export const credentialPackageIndexSchema = createSearchResponseSchema(credentialPackageShowSchema);

export type CredentialPackageData = z.infer<typeof credentialPackageSchema>;
export type CredentialPackageShowData = z.infer<typeof credentialPackageShowSchema>;
export type CredentialPackageCreateParams = Pick<CredentialPackageData, "name">;

export type CredentialPackageCredentialData = z.infer<typeof credentialPackageCredentialSchema>;
export type CredentialPackageCredentialCreateParams = {
  credential_package_id: number;
  credential_ids: number[];
};

export const invalidateCredentialPackages = (id?: number) => {
  const queryClient = useQueryClient();
  const queryKey: (number | string)[] = [CREDENTIAL_PACKAGES_BASE_URL];
  if (id) queryKey.push(id);
  return () => {
    queryClient.invalidateQueries({
      queryKey,
    });
    queryClient.invalidateQueries({
      queryKey: [CREDENTIAL_PACKAGE_USERS_BASE_URL],
    });
  };
};

export const getCredentialPackages = async () => {
  const result = await api.get(CREDENTIAL_PACKAGES_BASE_URL);
  return zodParse(credentialPackageSchema.array(), result.data);
};

export const useGetCredentialPackages = () => {
  return useQuery({
    queryKey: [CREDENTIAL_PACKAGES_BASE_URL],
    queryFn: () => getCredentialPackages(),
  });
};

export const getCredentialPackage = async (id: number | null | undefined) => {
  const result = await api.get(`${CREDENTIAL_PACKAGES_BASE_URL}/${id}`);
  return zodParse(credentialPackageShowSchema, result.data);
};

export const useGetCredentialPackage = (id: number | null | undefined) => {
  return useQuery({
    queryKey: [CREDENTIAL_PACKAGES_BASE_URL, id],
    queryFn: () => getCredentialPackage(id),
    enabled: !!id,
  });
};

export const searchCredentialPackages = async ({
  aggs,
  filters,
  pagination,
  bodyOptions,
  order,
  term,
}: SearchParams) => {
  const path = [CREDENTIAL_PACKAGES_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ bodyOptions, aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(credentialPackageIndexSchema, result.data);
};

export const useSearchCredentialPackages = (params: SearchParams) => {
  return useQuery({
    queryKey: [CREDENTIAL_PACKAGES_BASE_URL, params],
    queryFn: () => searchCredentialPackages(params),
  });
};

export const createCredentialPackage = async (
  credential_package: CredentialPackageCreateParams
) => {
  const result = await api.post(CREDENTIAL_PACKAGES_BASE_URL, { credential_package });
  return result.data;
};

export const useCreateCredentialPackage = () => {
  return useMutation({
    mutationFn: createCredentialPackage,
    onSuccess: invalidateCredentialPackages(),
  });
};

export const updateCredentialPackage = async (
  credential_package: Partial<CredentialPackageData>
) => {
  const result = await api.put(`${CREDENTIAL_PACKAGES_BASE_URL}/${credential_package.id}`, {
    credential_package,
  });
  return result.data;
};

export const useUpdateCredentialPackage = (id?: number) => {
  return useMutation({
    mutationFn: updateCredentialPackage,
    onSuccess: invalidateCredentialPackages(id),
  });
};

export const deleteCredentialPackage = async (id: number) => {
  await api.delete(`${CREDENTIAL_PACKAGES_BASE_URL}/${id}`);
};

export const useDeleteCredentialPackage = () => {
  return useMutation({
    mutationFn: deleteCredentialPackage,
    onSuccess: invalidateCredentialPackages(),
  });
};

export const associateCredentialsToCredentialPackage = async (
  credential_package_credential: CredentialPackageCredentialCreateParams
) => {
  const result = await api.post(CREDENTIAL_PACKAGE_CREDENTIALS_BASE_URL, {
    credential_package_credential,
  });
  return result.data;
};

export const useAssociateCredentialPackageCredential = () => {
  return useMutation({
    mutationFn: associateCredentialsToCredentialPackage,
    onSuccess: invalidateCredentialPackages(),
  });
};

export const deleteCredentialPackageCredential = async (id: number) => {
  await api.delete(`${CREDENTIAL_PACKAGE_CREDENTIALS_BASE_URL}/${id}`);
};

export const useDeleteCredentialPackageCredential = (id?: number) => {
  return useMutation({
    mutationFn: deleteCredentialPackageCredential,
    onSuccess: invalidateCredentialPackages(id),
  });
};
