import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Stack,
  VStack,
  Text,
  HStack,
  useColorModeValue,
  Divider,
  Icon,
  useDisclosure,
  Textarea,
  Tag,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Badge,
  Avatar,
} from "@chakra-ui/react";
import { SplitPage, ConfirmationButton, Header, RecordLink } from "@sciencecorp/helix-components";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { IoMedalOutline } from "react-icons/io5";
import { MdPersonAddAlt } from "react-icons/md";
import { SlPeople } from "react-icons/sl";
import { FiArchive } from "react-icons/fi";
import { RiCheckFill, RiCloseFill, RiEdit2Line } from "react-icons/ri";
import {
  useGetTraining,
  useUpdateTraining,
  useGetNextTrainingSession,
  invalidateTraining,
} from "../../../api/trainings";
import { TrainingMaterialShowData } from "../../../api/training_materials";
import { useUpdateTrainingSession } from "../../../api/training_sessions";
import {
  useUpdateUserTrainingSession,
  useDeleteUserTrainingSession,
} from "../../../api/user_training_sessions";
import { useCurrentUserQuery, userHasRole } from "../../../api/user";
import { AllTrainingSessionTable } from "./components/AllTrainingSessionsTable";
import { NewMaterial } from "./components/NewMaterial";
import { NewTrainingModal } from "./components/NewTrainingModal";
import { TrainingSessionImage } from "./components/TrainingImages";
import { NewTrainingSession } from "./components/NewTrainingSession";
import { TrainingMaterials } from "./components/TrainingMaterials";
import { RSVPModal } from "./components/RSVPModal";
import {
  AttendanceHistoryTable,
  FacilitatorHistoryTable,
} from "./components/TrainingSessionTables";
import { SidebarList } from "../util";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { useSearchTrainings } from "../../../api/trainings";

import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router";
import { CiUndo } from "react-icons/ci";
import { attendanceHistoryColorScheme } from "../util";

export const TrainingPage = () => {
  const { id } = useParams();
  if (!id) return null;

  const { search, debouncedSearch } = useDebouncedSearch();
  const { data: training } = useGetTraining(+id);
  const { data: nextSession } = useGetNextTrainingSession(+id);
  const { mutate: updateTraining } = useUpdateTraining();
  const { mutate: updateTrainingSession } = useUpdateTrainingSession(() => invalidateTraining(+id));
  const { mutate: updateUserTrainingSession } = useUpdateUserTrainingSession(() =>
    invalidateTraining(+id)
  );
  const { mutate: deleteUserTrainingSession } = useDeleteUserTrainingSession(() =>
    invalidateTraining(+id)
  );
  const { data: trainingSearch } = useSearchTrainings({
    term: search || "*",
    pagination: { per_page: 10 },
  });
  const currentUser = useCurrentUserQuery();
  const isCredentialsAdmin = userHasRole(currentUser, "credentials_admin");
  const [editDescription, setEditDescription] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const navigate = useNavigate();

  const {
    isOpen: isOpenNewTraining,
    onOpen: onOpenNewTraining,
    onClose: onCloseNewTraining,
  } = useDisclosure();

  const {
    isOpen: isOpenNewSession,
    onOpen: onOpenNewSession,
    onClose: onCloseNewSession,
  } = useDisclosure();

  const {
    isOpen: isOpenMaterial,
    onOpen: onOpenMaterial,
    onClose: onCloseMaterial,
  } = useDisclosure();

  const { isOpen: isOpenRSVP, onOpen: onOpenRSVP, onClose: onCloseRSVP } = useDisclosure();

  const [activeMaterial, setActiveMaterial] = useState<TrainingMaterialShowData | null>(null);

  const actions = isCredentialsAdmin
    ? !training?.is_archived
      ? ([
          training?.is_draft && (
            <ConfirmationButton
              label="Publish"
              variant="Button"
              confirmationButtonLabel="Yes"
              colorScheme="teal"
              children="Publishing this training will make it active and visible to all employees. Are you sure you want to proceed?"
              confirmationHeader="Archive Credential"
              onConfirm={() => updateTraining({ id: +id, is_draft: false })}
            />
          ),
          <ConfirmationButton
            buttonVariant="outline"
            leftIcon={<FiArchive />}
            label="Archive"
            variant="Button"
            confirmationButtonLabel="Yes"
            colorScheme="red"
            children="Archiving the training will remove it from the current list, but you can retrieve it by going to Trainings >> Archive."
            confirmationHeader="Archive Training"
            onConfirm={() => updateTraining({ id: +id, is_archived: true })}
          />,
          <Button leftIcon={<EditIcon />} onClick={() => onOpenNewTraining()}>
            Edit
          </Button>,
        ].filter(Boolean) as JSX.Element[])
      : [
          <Button leftIcon={<EditIcon />} onClick={() => onOpenNewTraining()}>
            Edit
          </Button>,
          <ConfirmationButton
            leftIcon={<CiUndo />}
            label="Restore"
            variant="Button"
            confirmationButtonLabel="Continue"
            colorScheme="teal"
            confirmationHeader="Restore Training"
            children="Restoring this training will place it back on the trainings list."
            onConfirm={() => {
              updateTraining({ id: +id, is_archived: false });
            }}
          />,
        ]
    : [];

  return (
    <>
      {training?.is_archived && (
        <>
          <Alert status="warning" width="100%" mb={4}>
            <AlertIcon />
            <VStack align="start" spacing={0}>
              <AlertTitle>Archived</AlertTitle>
              <AlertDescription>This training is in the training archive. </AlertDescription>
            </VStack>
          </Alert>
        </>
      )}
      <SplitPage
        sidebar={
          !training?.is_archived ? (
            <SidebarList
              breadcrumbTitle="Back to Trainings"
              title="Other Trainings"
              data={trainingSearch?.results || []}
              url="/trainings"
              breadcrumbUrl="/credentials/trainings"
              debouncedSearch={debouncedSearch}
              selectedItemId={+id}
            />
          ) : null
        }
        sidebarWidth="350px"
        sidebarWidthXL="450px"
        main={
          <VStack align="start" width="100%" spacing={5}>
            <Flex direction="column" width="100%">
              <Header
                title={training?.name ?? ""}
                badge={{
                  label: training?.user_training_session_status ?? "Not Assigned",
                  colorScheme: attendanceHistoryColorScheme(
                    training?.user_training_session_status || ""
                  ),
                }}
                actions={actions}
              />
            </Flex>
            <VStack align="start" spacing={6} width="100%">
              <Stack
                direction={{ base: "column", md: "row" }}
                width="100%"
                align="center"
                border="1px"
                bg={
                  (nextSession?.user_training_session &&
                    nextSession?.user_training_session.status !== "skipped") ||
                  training?.is_next_session_facilitator
                    ? useColorModeValue("teal.50", "gray.700")
                    : useColorModeValue("gray.50", "gray.700")
                }
                borderColor={
                  (nextSession?.user_training_session &&
                    nextSession?.user_training_session.status !== "skipped") ||
                  training?.is_next_session_facilitator
                    ? useColorModeValue("teal.200", "gray.600")
                    : "chakra-border-color"
                }
                borderRadius="md"
                spacing={6}
                p={4}>
                <TrainingSessionImage boxSize={24} />
                <VStack flex="1" align="start">
                  <Stack direction={{ base: "column", md: "row" }} width="100%" textAlign="center">
                    <Heading size="sm">Next Training Session</Heading>
                    <Text color={useColorModeValue("gray.600", "gray.300")} fontSize="sm">
                      {nextSession?.training_session?.scheduled_date
                        ? `${nextSession?.training_session.scheduled_date.toFormat(
                            "LLL dd yyyy"
                          )} • ${nextSession?.training_session.scheduled_date.toFormat("hh:mm a")}`
                        : "N/A"}
                    </Text>
                    {nextSession?.user_training_session &&
                    nextSession?.user_training_session.status !== "skipped" ? (
                      <Box>
                        <Tag colorScheme="green">Registered</Tag>
                      </Box>
                    ) : null}
                  </Stack>
                  <Divider />
                  <HStack>
                    <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.300")}>
                      Facilitated By:
                    </Text>
                    {nextSession?.training_session?.facilitator ? (
                      <HStack>
                        <Avatar
                          size="sm"
                          src={nextSession.training_session.facilitator.picture_uri}
                          aria-label="facilitator"
                        />
                        <VStack align="start">
                          <Text fontSize="sm" fontWeight="semibold">
                            {nextSession.training_session.facilitator?.name}
                          </Text>
                        </VStack>
                      </HStack>
                    ) : (
                      <Text>N/A</Text>
                    )}
                  </HStack>
                </VStack>
                {nextSession?.training_session?.spots_remaining ? (
                  <HStack fontSize="sm">
                    <Icon as={SlPeople} />
                    <Text>{nextSession.training_session.spots_remaining} seats left</Text>
                  </HStack>
                ) : null}
                {nextSession?.training_session ? (
                  training?.is_next_session_facilitator ? (
                    !nextSession.training_session.started_at ? (
                      <Button
                        size="sm"
                        colorScheme="teal"
                        onClick={() => {
                          if (nextSession.training_session) {
                            updateTrainingSession({
                              id: nextSession.training_session.id,
                              started_at: DateTime.now(),
                            });
                            navigate(
                              `/trainings/${id}/training_sessions/${nextSession.training_session.id}`
                            );
                          }
                        }}
                        isDisabled={
                          DateTime.now().toISODate() !==
                          nextSession.training_session.scheduled_date.toISODate()
                        }>
                        Start Session
                      </Button>
                    ) : !nextSession.training_session.ended_at ? (
                      <Button
                        size="sm"
                        colorScheme="teal"
                        onClick={() =>
                          nextSession.training_session &&
                          navigate(
                            `/trainings/${id}/training_sessions/${nextSession.training_session.id}`
                          )
                        }>
                        Continue Session
                      </Button>
                    ) : null
                  ) : !nextSession?.user_training_session ||
                    nextSession?.user_training_session.status === "skipped" ? (
                    <Button
                      size="sm"
                      colorScheme="teal"
                      leftIcon={<Icon as={MdPersonAddAlt} />}
                      onClick={() => onOpenRSVP()}>
                      Register
                    </Button>
                  ) : nextSession?.user_training_session &&
                    nextSession.user_training_session.status === "registered" ? (
                    <Button
                      size="sm"
                      colorScheme="teal"
                      variant="outline"
                      onClick={() =>
                        nextSession.user_training_session &&
                        deleteUserTrainingSession(nextSession.user_training_session.id)
                      }>
                      Cancel Registration
                    </Button>
                  ) : (
                    <ConfirmationButton
                      size="sm"
                      colorScheme="teal"
                      label="Skip Session"
                      confirmationButtonLabel="Skip"
                      variant="Button"
                      buttonVariant="outline"
                      confirmationHeader="Skip This Session"
                      children="If you skip this session, you will be automatically assigned to the next session."
                      onConfirm={() =>
                        nextSession.user_training_session &&
                        updateUserTrainingSession({
                          id: nextSession.user_training_session.id,
                          status: "skipped",
                        })
                      }
                    />
                  )
                ) : null}
              </Stack>
              <VStack align="start" spacing={1}>
                <HStack>
                  <Heading size="md">Description</Heading>
                  {isCredentialsAdmin &&
                    (!editDescription ? (
                      <IconButton
                        size="xs"
                        aria-label="edit description"
                        icon={<RiEdit2Line />}
                        onClick={() => setEditDescription(true)}
                      />
                    ) : (
                      <Flex gap={1}>
                        <IconButton
                          size="xs"
                          aria-label="Save Edits"
                          icon={<RiCheckFill />}
                          onClick={() =>
                            updateTraining(
                              { id: +id, description: description },
                              { onSuccess: () => setEditDescription(false) }
                            )
                          }
                        />
                        <IconButton
                          size="xs"
                          aria-label="Cancel Edits"
                          icon={<RiCloseFill />}
                          onClick={() => setEditDescription(false)}
                        />
                      </Flex>
                    ))}
                </HStack>
                {!editDescription ? (
                  training?.description ? (
                    <Text fontSize="sm">{training?.description}</Text>
                  ) : (
                    <Text as="i" fontSize="sm">
                      No description yet
                    </Text>
                  )
                ) : (
                  <Textarea
                    width="100%"
                    defaultValue={training?.description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                )}
              </VStack>
            </VStack>
            <Stack width="100%" direction={{ base: "column", lg: "row" }}>
              {training && (
                <TrainingMaterials
                  trainingId={+id}
                  editable={isCredentialsAdmin || training.is_facilitator}
                  onOpen={onOpenMaterial}
                  setActiveMaterial={setActiveMaterial}
                />
              )}
              <VStack
                p={5}
                spacing={4}
                border="1px"
                borderColor="chakra-border-color"
                borderRadius="md"
                align="start"
                width={{ base: "100%", lg: "50%" }}>
                <HStack>
                  <Box
                    bg={useColorModeValue("green.100", "green.700")}
                    p={2}
                    borderRadius="md"
                    display="flex">
                    <Icon as={IoMedalOutline} color={useColorModeValue("green.700", "green.400")} />
                  </Box>
                  <Heading size="md">Award </Heading>
                </HStack>
                <Divider />
                {training?.credential_trainings.length && (
                  <Flex width="100%" align="start" gap={4} flexWrap="wrap">
                    {isCredentialsAdmin
                      ? training?.credential_trainings.map((credentialTraining) => (
                          <RecordLink
                            type=""
                            identifier={credentialTraining.credential.name}
                            link={`/credentials/all/${credentialTraining.credential.id}`}
                            icon={
                              <Box ml={3} mt={1}>
                                <Icon as={IoMedalOutline} boxSize={4} />
                              </Box>
                            }
                          />
                        ))
                      : training?.credential_trainings.map((credentialTraining) => (
                          <Badge size="lg">
                            <HStack p={1}>
                              <Icon as={IoMedalOutline} />
                              <Text textTransform="capitalize">
                                {credentialTraining.credential.name}
                              </Text>
                            </HStack>
                          </Badge>
                        ))}
                  </Flex>
                )}
              </VStack>
            </Stack>
            <VStack width="100%" align="start" spacing={2}>
              <Heading size="md">Attendance History</Heading>
              <Box width="100%">
                <AttendanceHistoryTable trainingId={+id} />
              </Box>
            </VStack>
            {training?.is_facilitator && (
              <VStack width="100%" align="start" spacing={2}>
                <Heading size="md">Training Sessions You Facilitated</Heading>
                <Box width="100%">
                  <FacilitatorHistoryTable trainingId={+id} />
                </Box>
              </VStack>
            )}
            {isCredentialsAdmin && (
              <VStack width="100%" align="start" spacing={2}>
                <HStack justify="space-between" width="100%">
                  <Heading size="md">Training Sessions</Heading>
                  <Button leftIcon={<AddIcon />} onClick={onOpenNewSession}>
                    New Session
                  </Button>
                  <NewTrainingSession
                    trainingId={+id}
                    isOpen={isOpenNewSession}
                    onClose={onCloseNewSession}
                  />
                </HStack>
                <Box width="100%">
                  <AllTrainingSessionTable id={+id} />
                </Box>
              </VStack>
            )}
            {activeMaterial && (
              <NewMaterial
                isOpen={isOpenMaterial}
                onClose={onCloseMaterial}
                activeMaterial={activeMaterial}
              />
            )}
            <NewTrainingModal
              isOpen={isOpenNewTraining}
              onClose={onCloseNewTraining}
              training={training}
            />
            {nextSession?.training_session && training && currentUser?.data && (
              <RSVPModal
                isOpen={isOpenRSVP}
                onClose={onCloseRSVP}
                training={training}
                nextSession={nextSession}
                currentUser={currentUser.data}
              />
            )}
          </VStack>
        }
      />
    </>
  );
};
