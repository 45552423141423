import React, { useMemo, useState, useCallback } from "react";
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Flex,
  VStack,
  Heading,
  Text,
  Tag,
  Badge,
  Box,
  HStack,
} from "@chakra-ui/react";
import {
  Collection,
  EmptyState,
  Facets,
  RecordLink,
  Select,
  buildFacets,
  useCollection,
} from "@sciencecorp/helix-components";
import { DateTime } from "luxon";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { MoneyText } from "../../../../MoneyText";
import { PurchaseIndexItemData, useSearchPurchases } from "../../../../../api/purchase";
import { humanize, titleize } from "inflection";
import {
  ReimbursementIndexItemData,
  useSearchReimbursements,
} from "../../../../../api/reimbursement";
import { generatePaymentsCSV } from "../../../../../api/purchase_payment";
import { UserRequestPill } from "../../../../Requests/utils";
import { reimbursementStatusColor } from "../../../../Purchasing/Reimbursements/components/utils";
import { Filters } from "../../../../../api/collection_types";
import {
  PurchasePaymentTransactionData,
  useSearchPurchasePayments,
} from "../../../../../api/purchase_payment";
import {
  ServiceRequestIndexItemData,
  useSearchServiceRequestsQuery,
} from "../../../../../api/service_requests";
import { LinkifyText, purchaseRequestStatusColor } from "../../../../Purchasing/util";
import { CsvDownload } from "../../../../shared/csvDownloadButton";
import { CurrencySelect } from "../../../../CurrencySelect";
import { ContractIndexData, useSearchContracts } from "../../../../../api/contracts";

export const TransactionTable = ({
  queryFilters,
  canDownload,
}: {
  queryFilters: Filters;
  canDownload?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [transactionTabIndex, setTransactionTabIndex] = useState(0);

  const expenditureTypes = [
    { label: "Purchase Payments" },
    { label: "Purchases" },
    { label: "Contracts" },
    { label: "Service Requests" },
    { label: "Reimbursements" },
  ];

  const handleTabChange = useCallback((index: number) => {
    setTransactionTabIndex && setTransactionTabIndex(index);
    resetFacets();
    resetPagination();
    navigate(location.pathname);
  }, []);

  const {
    onPagination,
    onOrder,
    order,
    pagination,
    filters,
    facets,
    onFacets,
    resetFacets,
    resetPagination,
  } = useCollection({ pagination: { per_page: 100 } });

  const AGGREGATION_QUERY = ["vendor_name", "status", "type", "by_month"];
  const [currency, setCurrency] = useState<string | null>(null);

  const PAYMENTS_TAB = 0;
  const PURCHASE_TAB = 1;
  const CONTRACT_TAB = 2;
  const SERVICE_REQUEST_TAB = 3;

  const { data, isLoading } =
    transactionTabIndex === PAYMENTS_TAB
      ? useSearchPurchasePayments({
          currency: currency,
          term: "*",
          aggs: AGGREGATION_QUERY,
          filters: {
            ...filters,
            ...queryFilters,
          },
          pagination,
          order,
        })
      : transactionTabIndex === PURCHASE_TAB
      ? useSearchPurchases({
          currency: currency,
          term: "*",
          aggs: ["vendor_name", "status"],
          filters: {
            status: ["awaiting_purchase", "awaiting_payment", "awaiting_delivery", "delivered"],
            ...filters,
            ...queryFilters,
          },
          pagination,
          order,
        })
      : transactionTabIndex === CONTRACT_TAB
      ? useSearchContracts({
          currency: currency,
          term: "*",
          filters: {
            ...filters,
            ...queryFilters,
          },
          pagination,
          order,
        })
      : transactionTabIndex === SERVICE_REQUEST_TAB
      ? useSearchServiceRequestsQuery({
          currency: currency,

          term: "*",
          aggs: ["fulfilling_team_name", "requesting_team_name"],
          filters: {
            ...filters,
            has_cost: true,
            ...queryFilters,
          },
          pagination,
        })
      : useSearchReimbursements({
          currency: currency,
          term: "*",
          aggs: ["approval_status", "month_paid_at"],
          filters: {
            ...filters,
            ...queryFilters,
            approval_status: ["approved", "completed"],
          },
          pagination,
        });

  const columns = useMemo(
    () =>
      transactionTabIndex === PAYMENTS_TAB
        ? [
            {
              label: "Paid at",
              orderOptions: { orderKey: "paid_at" },
              render: (purchasePayment: PurchasePaymentTransactionData) =>
                purchasePayment.paid_at
                  ? DateTime.fromISO(purchasePayment.paid_at, { zone: "utc" }).toFormat(
                      "LLL dd, yyyy"
                    )
                  : "-",
            },
            {
              label: "Record",
              orderOptions: { orderKey: "vendor_name" },
              render: (purchasePayment: PurchasePaymentTransactionData) =>
                purchasePayment.purchase_id ? (
                  <RecordLink
                    type="Purchase"
                    maxWidth="15ch"
                    identifier={purchasePayment.purchase_id || ""}
                    link={`/services/purchasing/${purchasePayment.purchase_id}`}
                  />
                ) : (
                  <RecordLink
                    type="Contract"
                    maxWidth="15ch"
                    identifier={purchasePayment.contract_id || ""}
                    link={`/services/contracts/${purchasePayment.contract_id}`}
                  />
                ),
            },
            {
              label: "Vendor",
              orderOptions: { orderKey: "vendor_name" },
              render: (purchasePayment: PurchasePaymentTransactionData) => (
                <RecordLink
                  type=""
                  maxWidth="15ch"
                  identifier={purchasePayment.vendor_name}
                  link={`/services/vendors/${purchasePayment.vendor_id}`}
                />
              ),
            },
            {
              label: "Cost",
              orderOptions: { orderKey: "amount_cents" },
              render: (purchasePayment: PurchasePaymentTransactionData) => (
                <MoneyText money={purchasePayment.amount} formatOptions={{ compact: "never" }} />
              ),
            },
          ]
        : transactionTabIndex === PURCHASE_TAB
        ? [
            {
              label: "Purchase",
              orderOptions: { orderKey: "id" },
              render: (purchase: PurchaseIndexItemData) => (
                <RecordLink
                  type=""
                  identifier={`#${purchase.id}`}
                  link={`/services/purchasing/${purchase.id}`}
                />
              ),
            },
            {
              label: "Vendor",
              orderOptions: { orderKey: "vendor_name" },
              render: (purchase: PurchaseIndexItemData) => (
                <RecordLink
                  type=""
                  maxWidth="15ch"
                  identifier={purchase.vendor?.name || ""}
                  link={`/services/vendors/${purchase.vendor?.id}`}
                />
              ),
            },

            {
              label: "Amount Paid",
              orderOptions: { orderKey: "amount_cents" },
              render: (purchase: PurchaseIndexItemData) => (
                <MoneyText
                  money={purchase.purchase_payments_paid}
                  formatOptions={{ compact: "never" }}
                />
              ),
            },
            {
              label: "Cost",
              orderOptions: { orderKey: "amount_cents" },
              render: (purchase: PurchaseIndexItemData) => (
                <MoneyText money={purchase.amount} formatOptions={{ compact: "never" }} />
              ),
            },
            {
              label: "Status",
              orderOptions: { orderKey: "status" },
              render: (purchase: PurchaseIndexItemData) => (
                <Tag colorScheme={purchaseRequestStatusColor(purchase.status)} whiteSpace="nowrap">
                  {titleize(humanize(purchase.status))}
                </Tag>
              ),
            },
          ]
        : transactionTabIndex === CONTRACT_TAB
        ? [
            {
              label: "Contract",
              orderOptions: { orderKey: "id" },
              render: (contract: ContractIndexData) => (
                <RecordLink
                  type=""
                  identifier={`#${contract.id}`}
                  link={`/services/contracts/${contract.id}`}
                />
              ),
            },
            {
              label: "Vendor",
              orderOptions: { orderKey: "vendor_name" },
              render: (contract: ContractIndexData) => (
                <RecordLink
                  type=""
                  identifier={contract.vendor.name || ""}
                  maxWidth="15ch"
                  link={`/services/vendors/${contract.vendor.id}`}
                />
              ),
            },
            {
              label: "Total Paid",
              render: (contract: ContractIndexData) => (
                <Text>
                  <MoneyText
                    as="span"
                    money={contract.total_paid}
                    formatOptions={{ compact: "never" }}
                  />
                </Text>
              ),
            },
            {
              label: "Total Cost",
              render: (contract: ContractIndexData) => (
                <Text>
                  <MoneyText
                    as="span"
                    money={contract.total_cost}
                    formatOptions={{ compact: "never" }}
                  />
                </Text>
              ),
            },
          ]
        : transactionTabIndex === SERVICE_REQUEST_TAB
        ? [
            {
              label: "Created",
              orderOptions: { orderKey: "created_at" },
              render: (request: ServiceRequestIndexItemData) =>
                DateTime.fromISO(request.created_at).toFormat("LLL dd yyyy"),
            },
            {
              label: "Request",
              orderOptions: { orderKey: "id" },
              render: (request: ServiceRequestIndexItemData) => (
                <RecordLink
                  identifier={`# ${request.id}`}
                  link={`/services/requests/${request.id}`}
                />
              ),
            },
            {
              label: "Requesting User",
              render: (request: ServiceRequestIndexItemData) =>
                request?.requesting_user && (
                  <RouterLink to={`/users/${request.requesting_user?.id}`}>
                    <UserRequestPill user={request.requesting_user} />
                  </RouterLink>
                ),
            },
            {
              label: "Description",
              orderOptions: { orderKey: "id" },
              render: (request: ServiceRequestIndexItemData) => (
                <LinkifyText
                  text={request?.request_description || "No description available"}
                  maxLinkChars={20}
                />
              ),
            },
            {
              label: "Cost",
              orderOptions: { orderKey: "total_cost_cents" },
              render: (request: ServiceRequestIndexItemData) => {
                return (
                  <MoneyText money={request.total_cost} formatOptions={{ compact: "never" }} />
                );
              },
            },
          ]
        : [
            {
              label: "Paid at",
              orderOptions: { orderKey: "paid_at" },
              render: (reimbursement: ReimbursementIndexItemData) =>
                reimbursement.paid_at ? reimbursement.paid_at.toFormat("LLL dd yyyy") : "-",
            },
            {
              label: "Reimbursement",
              orderOptions: { orderKey: "id" },
              render: (reimbursement: ReimbursementIndexItemData) => (
                <RecordLink
                  link={`/services/reimbursements/${reimbursement.id}`}
                  identifier={`${reimbursement.id}`}
                  size="small"
                />
              ),
            },
            {
              label: "Requested By",
              render: (reimbursement: ReimbursementIndexItemData) => (
                <UserRequestPill user={reimbursement.user} />
              ),
            },
            {
              label: "Status",
              render: (reimbursement: ReimbursementIndexItemData) => (
                <VStack align="start" marginTop={["2", "0"]}>
                  <Tag
                    colorScheme={reimbursementStatusColor(reimbursement.approval_status)}
                    flexShrink={0}
                    whiteSpace="nowrap"
                    size={["sm", "md"]}>
                    {titleize(humanize(reimbursement.approval_status))}
                  </Tag>
                </VStack>
              ),
            },
            {
              label: "Amount",
              render: (reimbursement: ReimbursementIndexItemData) => (
                <MoneyText money={reimbursement.amount} formatOptions={{ compact: "never" }} />
              ),
            },
          ],
    [data, transactionTabIndex]
  );

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  return (
    <Flex
      border="1px"
      borderColor="chakra-border-color"
      borderRadius="md"
      p={6}
      direction="column"
      gap={6}>
      <Flex
        direction={["column", "row"]}
        gap={["2", "auto"]}
        justifyContent="space-between"
        alignItems={["flex-start", "center"]}
        w="100%">
        <Flex gap={2}>
          <Heading size="md">Transactions</Heading>
          <Box>
            <Badge>{data?.pagination.total}</Badge>
          </Box>
        </Flex>
        <HStack>
          <CurrencySelect
            value={currency}
            onChange={(value) => {
              if (value === "original") {
                setCurrency(null);
              } else {
                setCurrency(value);
              }
            }}
            includeOriginal
          />
          <Facets
            facets={populatedFacets}
            defaultIndex={[0]}
            onChange={onFacets}
            variant="button"
          />
          {canDownload && (
            <CsvDownload
              title="budget-group-transactions"
              apiCall={() =>
                generatePaymentsCSV({
                  term: "*",
                  currency: currency,
                  filters: { ...filters, ...queryFilters },
                })
              }
              buttonName="Export"
            />
          )}
        </HStack>
      </Flex>
      <Tabs colorScheme="teal" onChange={handleTabChange}>
        <TabList overflowX={["auto", "unset"]} whiteSpace={["nowrap", "none"]}>
          {expenditureTypes.map((type, index) => (
            <Tab key={`${type}-${index}`}>{type.label}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {expenditureTypes.map((type, index) => (
            <TabPanel key={`${type}-${index}`}>
              <Flex direction="column" gap={4}>
                {(data?.results?.length || 0) > 0 || isLoading ? (
                  <Collection
                    key={"transactions"}
                    columns={columns}
                    isLoading={isLoading}
                    items={data?.results || []}
                    pagination={data?.pagination || pagination}
                    order={order}
                    onPagination={onPagination}
                    onOrder={onOrder}
                  />
                ) : (
                  <EmptyState title={`No ${type.label} yet`} size="2xs" />
                )}
              </Flex>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
