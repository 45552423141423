import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import {
  DynamicPage,
  Facets,
  Header,
  PersonIcon,
  buildFacets,
  useCollection,
} from "@sciencecorp/helix-components";
import { TbMoneybag, TbNotes } from "react-icons/tb";
import { MdDashboard, MdCalendarMonth, MdPayments } from "react-icons/md";
import { useCurrentUserQuery, userHasRole } from "../../api/user";
import { useDebouncedSearch } from "../hooks/useDebouncedSearch";
import { AuditTrail } from "./AuditTrail";
import { CompanyBudgets } from "./Budgets";
import { ScheduledPayments } from "./ScheduledPayments";
import { Dashboard } from "./Dashboard";
import { getBudgetSummaryCsv } from "../../api/budget";
import {
  PurchasePaymentScorecards,
  generatePaymentsCSV,
  useSearchPurchasePaymentScorecards,
} from "../../api/purchase_payment";
import { TimelineEventSearchResponse, useSearchTimelineEvents } from "../../api/timeline_events";
import { getUsersAssociationCsv } from "../../api/user";
import { generateSpendByBudgetItemCSV } from "../../api/budget_items";
import { ChevronDownIcon, DownloadIcon } from "@chakra-ui/icons";

export const Finance = () => {
  const currentUserQuery = useCurrentUserQuery();
  const { tab } = useParams();

  const isCeo = userHasRole(currentUserQuery, "ceo");
  const isPurchasingAdmin = userHasRole(currentUserQuery, "purchasing_admin") || false;
  const budgetGroupLead = currentUserQuery.data?.budget_or_budget_group_lead || false;
  const userCanSeeBudget = userHasRole(currentUserQuery, "finance_admin") || isCeo || false;
  const [purchasingTab, setPurchasingTab] = useState<"paid_at" | "due_date">("paid_at");
  const defaultTab = useMemo(() => {
    if (userCanSeeBudget) {
      return "dashboard";
    }
    if (isPurchasingAdmin) {
      return "scheduled_payments";
    }
    if (budgetGroupLead) {
      return "budgets";
    }
    return "dashboard";
  }, [userCanSeeBudget, isPurchasingAdmin, budgetGroupLead]);

  const [activeTab, setActiveTab] = useState(tab || defaultTab);
  const { filters, onFacets, facets, pagination, onPagination, order, onOrder } = useCollection();
  const { search, debouncedSearch } = useDebouncedSearch();
  const { data, isLoading } =
    activeTab === "scheduled_payments"
      ? useSearchPurchasePaymentScorecards({
          term: search || "*",
          aggs: ["status", "vendor_name", "budgetable_name"],
          pagination: { per_page: -1 },
          filters: filters,
          type: purchasingTab,
        })
      : useSearchTimelineEvents({
          term: search || "*",
          aggs: ["timelineable_type"],
          pagination: pagination,
          filters: filters,
          order: order,
        });

  const TabConfig = useMemo(
    () =>
      [
        {
          label: "Dashboard",
          value: "dashboard",
          icon: <MdDashboard />,
          path: "/finance/dashboard",
          contentComponent: <Dashboard />,
          disabled: !userCanSeeBudget,
        },
        {
          label: "Payments",
          value: "scheduled_payments",
          icon: <MdPayments />,
          path: "/finance/scheduled_payments",
          contentComponent: (
            <ScheduledPayments
              purchasePaymentScorecards={data?.results as PurchasePaymentScorecards}
              setPurchasingTab={setPurchasingTab}
              purchasingTab={purchasingTab}
              isLoadingPurchasePayments={isLoading}
            />
          ),
          disabled: !isPurchasingAdmin && !userCanSeeBudget,
        },
        {
          label: "Budgets",
          value: "budgets",
          path: "/finance/budgets",
          icon: <TbMoneybag />,
          contentComponent: (
            <CompanyBudgets admin={userCanSeeBudget} budgetGroupLead={budgetGroupLead} />
          ),
          disabled: !userCanSeeBudget && !budgetGroupLead,
        },
        {
          label: "Audit",
          value: "audit",
          path: "/finance/audit",
          icon: <TbNotes />,
          contentComponent: (
            <AuditTrail
              data={data as TimelineEventSearchResponse}
              isLoading={isLoading}
              pagination={pagination}
              onPagination={onPagination}
              order={order}
              onOrder={onOrder}
            />
          ),
          disabled: !userCanSeeBudget,
        },
      ].filter((tab) => !tab.disabled),
    [
      currentUserQuery,
      userCanSeeBudget,
      isPurchasingAdmin,
      data,
      isLoading,
      pagination,
      order,
      onOrder,
      onPagination,
    ]
  );

  const populatedFacets = useMemo(
    () => buildFacets(data?.aggregations || {}, facets),
    [data?.aggregations, facets]
  );

  useEffect(() => {
    if (tab && tab !== activeTab) setActiveTab(tab);
    else if (!tab) setActiveTab(defaultTab);
  }, [tab, defaultTab]);

  return userCanSeeBudget || isPurchasingAdmin || budgetGroupLead ? (
    <>
      <Box pb={4}>
        <Header title="Finance" actions={userCanSeeBudget ? [<CsvMenu />] : []} />
      </Box>
      <DynamicPage
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        sidebarChildren={
          (activeTab === "scheduled_payments" || activeTab === "audit") && (
            <>
              <Box display={{ base: "flex", lg: "none" }}>
                <Facets
                  variant="button"
                  facets={populatedFacets}
                  search
                  background
                  debouncedSearch={debouncedSearch}
                  onChange={onFacets}
                />
              </Box>
              <Box display={{ base: "none", lg: "flex" }}>
                <Facets
                  facets={populatedFacets}
                  search
                  background
                  debouncedSearch={debouncedSearch}
                  onChange={onFacets}
                />
              </Box>
            </>
          )
        }
        tabConfigs={TabConfig}
        splitPageProps={{
          breakpoint: "lg",
          sidebarWidth: "200px",
          sidebarWidthXL: "250px",
        }}
      />
    </>
  ) : (
    <Text>Not authorized</Text>
  );
};

const CsvMenu = () => {
  const [isGenerating, setIsGenerating] = useState(false);

  const handleGenerateCsv = async (apiCall, fileName) => {
    setIsGenerating(true);
    try {
      const response = await apiCall();
      const blob = new Blob([response], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        setIsGenerating(false);
      }, 1000);
    } catch (error) {
      console.error("Error generating CSV:", error);
      setIsGenerating(false);
    }
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<DownloadIcon />}
        rightIcon={<ChevronDownIcon />}
        colorScheme="teal"
        isLoading={isGenerating}
        loadingText="Generating Report...">
        Export CSV
      </MenuButton>
      <MenuList zIndex={9999}>
        <MenuItem
          icon={<TbMoneybag />}
          p={3}
          pl={6}
          fontWeight="medium"
          onClick={() => handleGenerateCsv(getBudgetSummaryCsv, "budget-summary")}>
          Budget Overview by Team & Project
        </MenuItem>
        <MenuItem
          icon={<MdCalendarMonth />}
          p={3}
          pl={6}
          fontWeight="medium"
          onClick={() =>
            handleGenerateCsv(generateSpendByBudgetItemCSV, "spending-authorities-summary")
          }>
          Monthly Spending Authorities Summary
        </MenuItem>
        <MenuItem
          icon={<MdPayments />}
          p={3}
          pl={6}
          fontWeight="medium"
          onClick={() => handleGenerateCsv(generatePaymentsCSV, "all-payments")}>
          Payment Records (All Transactions)
        </MenuItem>
        <MenuItem
          icon={<PersonIcon />}
          p={3}
          pl={6}
          fontWeight="medium"
          onClick={() => handleGenerateCsv(getUsersAssociationCsv, "user-associations")}>
          User Role & Team Associations
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
