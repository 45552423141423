import React from "react";
import {
  Stack,
  Flex,
  Heading,
  Box,
  Text,
  VStack,
  HStack,
  Divider,
  SimpleGrid,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  AttributesTable,
  EditableText,
  EditableSelect,
  RecordLink,
  ConfirmationButton,
  EmptyState,
} from "@sciencecorp/helix-components";
import { titleize, humanize } from "inflection";
import {
  invalidateInventories,
  updateInventory,
  InventoryShowData,
} from "../../../../api/inventory";
import {
  useUpdateInventoryVendor,
  useDeleteInventoryVendor,
} from "../../../../api/inventory_vendor";
import { EditableLink } from "../../../Purchasing/Purchase/components/EditableLink";
import { useUserOptions } from "../../../../api/options";
import { InventoryCategoryOptions } from "../../util";
import { EditableInventoryAssociation } from "./EditableInventoryAssociation";
import { InventoryVendorModal } from "./InventoryVendorModal";
import { DeleteableFileDownload } from "../../../shared/DeleteableFileDownload";
import { useDeleteFile } from "../../../../api/blob_files";
import { BlobUploadButton } from "../../../shared/BlobUploadButton";
import { DeleteIcon } from "@chakra-ui/icons";
import { StockLocations } from "./StockLocations";

type InventoryDetailInformationProps = {
  inventory: InventoryShowData;
  isInventoryManager: boolean;
};

export const InventoryDetailInformation = ({
  inventory,
  isInventoryManager,
}: InventoryDetailInformationProps) => {
  const userOptions = useUserOptions();
  const { mutate: updateInventoryVendor } = useUpdateInventoryVendor(inventory.id);
  const { mutate: deleteInventoryVendor } = useDeleteInventoryVendor(inventory.id);
  const { mutate: deleteFile } = useDeleteFile(() => invalidateInventories(inventory.id));

  return (
    <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6} w="100%">
      {/* Notes */}
      <Box border="1px" borderRadius="md" borderColor="chakra-border-color" p={6}>
        <Heading size="md" mb={4} fontWeight="medium">
          Notes
        </Heading>
        <EditableText
          defaultValue={inventory.description || ""}
          onSubmit={(value) => {
            updateInventory({ id: inventory.id, description: String(value) });
          }}
        />
      </Box>

      {/* Attachments */}
      <Box border="1px" borderRadius="md" borderColor="chakra-border-color" p={6}>
        <Heading size="md" mb={4} fontWeight="medium">
          Attachments
        </Heading>
        <HStack spacing={2} flexWrap="wrap">
          {inventory.uploaded_files.map((file) => (
            <DeleteableFileDownload key={file.id} file={file} deleteFile={deleteFile} />
          ))}
          {isInventoryManager && (
            <BlobUploadButton
              fileableColumn="uploaded_files"
              recordId={inventory.id}
              recordType="Inventory"
              onSuccessCallback={() => invalidateInventories(inventory.id)}
            />
          )}
        </HStack>
      </Box>

      {/* Basic Information */}
      <Box border="1px" borderRadius="md" borderColor="chakra-border-color" p={6}>
        <Heading size="md" mb={4} fontWeight="medium">
          Basic Information
        </Heading>
        <AttributesTable
          attributes={[
            {
              label: "ID",
              value: (
                <EditableText
                  defaultValue={inventory.sku || ""}
                  preview={inventory.sku || ""}
                  onSubmit={(value) => {
                    updateInventory({ id: inventory.id, sku: String(value) });
                  }}
                />
              ),
            },
            {
              label: "Type",
              value: (
                <Box maxW={40}>
                  <EditableSelect
                    options={[
                      { label: "Consumable", value: "consumable" },
                      { label: "Durable", value: "durable" },
                    ]}
                    selectedValue={inventory.is_consumable ? "consumable" : "durable"}
                    onSubmit={(value) => {
                      updateInventory({
                        id: inventory.id,
                        is_consumable: value === "consumable",
                      });
                    }}
                  />
                </Box>
              ),
            },
            {
              label: "Category",
              value: (
                <EditableSelect
                  selectedValue={inventory.category || ""}
                  preview={titleize(humanize(inventory.category)) || ""}
                  options={InventoryCategoryOptions}
                  onSubmit={(value) => {
                    updateInventory({ id: inventory.id, category: String(value) });
                  }}
                />
              ),
            },
            {
              label: "Owners",
              value: (
                <EditableInventoryAssociation
                  associationOptions={userOptions}
                  inventoryId={inventory.id}
                  currentAssociations={inventory.users}
                />
              ),
            },
          ]}
        />
      </Box>

      {/* Supplier Information */}
      <Box border="1px" borderRadius="md" borderColor="chakra-border-color" p={6}>
        <HStack justify="space-between" mb={4}>
          <Heading size="md" fontWeight="medium">
            Supplier Information
          </Heading>
          {isInventoryManager && <InventoryVendorModal inventory={inventory} />}
        </HStack>
        <Flex direction="column" gap={3}>
          {inventory.vendors.length > 0 ? (
            inventory.vendors.map((vendor) => (
              <VStack key={`inventory-vendor-${vendor.id}`} align="start" spacing={3} w="100%">
                <HStack w="100%" justify="space-between">
                  <RecordLink
                    identifier={vendor.vendor.name}
                    link={`/services/vendors/${vendor.vendor.id}`}
                  />
                  <HStack>
                    <EditableLink
                      url={vendor.link || ""}
                      onSubmit={(value) => {
                        if (!value) return;
                        updateInventoryVendor({ id: vendor.id, link: String(value) });
                      }}
                    />
                    <ConfirmationButton
                      size="xs"
                      aria-label="delete supplier"
                      icon={<DeleteIcon />}
                      variant="IconButton"
                      label="Delete Supplier"
                      children="Are you sure you want to delete this supplier?"
                      confirmationButtonLabel="Delete"
                      onConfirm={() => deleteInventoryVendor(vendor.id)}
                    />
                  </HStack>
                </HStack>
                <Divider />
              </VStack>
            ))
          ) : (
            <EmptyState title="Add a supplier to show here." />
          )}
        </Flex>
      </Box>

      {/* Stock Locations */}
      <StockLocations inventory={inventory} />

      {/* Manufacturer Information */}
      <Box border="1px" borderRadius="md" borderColor="chakra-border-color" p={6}>
        <Heading size="md" mb={4} fontWeight="medium">
          Manufacturer Information
        </Heading>
        <AttributesTable
          attributes={[
            {
              label: "Original Equipment Manufacturer",
              value: (
                <EditableText
                  defaultValue={inventory.original_equipment_manufacturer || ""}
                  preview={inventory.original_equipment_manufacturer || ""}
                  onSubmit={(value) => {
                    updateInventory({
                      id: inventory.id,
                      original_equipment_manufacturer: String(value),
                    });
                  }}
                />
              ),
            },
            {
              label: "Manufacturing Part Number",
              value: (
                <EditableText
                  defaultValue={inventory.manufacturing_part_number || ""}
                  preview={inventory.manufacturing_part_number || ""}
                  onSubmit={(value) => {
                    updateInventory({
                      id: inventory.id,
                      manufacturing_part_number: String(value),
                    });
                  }}
                />
              ),
            },
          ]}
        />
      </Box>
    </SimpleGrid>
  );
};
