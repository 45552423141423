import React from "react";
import {
  Box,
  Heading,
  HStack,
  Text,
  Progress,
  Badge,
  Flex,
  Icon,
  useColorModeValue,
  VStack,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { CredentialPackageShowData } from "../../../../api/credential_packages";

interface CredentialPackageCardProps {
  credentialPackage: CredentialPackageShowData;
}

const CredentialPackageCard: React.FC<CredentialPackageCardProps> = ({ credentialPackage }) => {
  const cardBg = useColorModeValue("gray.50", "gray.700");
  const cardHoverBg = useColorModeValue("gray.100", "gray.600");

  return (
    <LinkBox borderRadius="lg" bg={cardBg} _hover={{ bg: cardHoverBg }} width="100%" p={4}>
      <LinkOverlay as={Link} to={`/credential_packages/${credentialPackage.id}`}>
        <HStack align="start" spacing={4} width="100%">
          <VStack spacing={2} width="100%" align="start">
            <Heading size="md">{credentialPackage.name}</Heading>
            <Text fontSize="sm" fontWeight="medium">
              {credentialPackage.trainings_count} Trainings | {credentialPackage.credentials_count}{" "}
              Credentials
            </Text>
            <Text fontSize="sm">
              Completed by{" "}
              <Box as="span" fontWeight="semibold">
                {credentialPackage.total_users_completed_count}
              </Box>{" "}
              of {credentialPackage.total_users_count} employees{" "}
            </Text>
            <Progress
              borderRadius="md"
              hasStripe
              w="100%"
              value={
                (credentialPackage.total_users_completed_count /
                  (credentialPackage.total_users_count || 1)) *
                100
              }
              size="sm"
              colorScheme="teal"
            />
          </VStack>
          <ChevronRightIcon alignSelf="center" boxSize={5} color="gray.500" />
        </HStack>
      </LinkOverlay>
    </LinkBox>
  );
};

export default CredentialPackageCard;
