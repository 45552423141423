import React from "react";
import {
  Box,
  Grid,
  HStack,
  Heading,
  Stack,
  Stat,
  StatNumber,
  Tag,
  Text,
  VStack,
  useColorModeValue,
  Progress,
  Circle,
  Divider,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { CredentialCard } from "./CredentialCard";
import { UnfulfilledCredentialCard } from "./UnfulfilledCredentialCard";
import { UpcomingTrainings } from "./UpcomingTrainings";
import { useGetMyTrainingSessions } from "../../../api/training_sessions";

export const MyCredentialsPage = ({ data }) => {
  const { data: trainingSessions, isLoading, isError } = useGetMyTrainingSessions();

  if (!data) {
    return <Spinner />;
  }

  const activeCredentials = data.active_credentials.length;
  const unfulfilledCredentials = data.unfulfilled_credentials.length;
  const totalCredentials = activeCredentials + unfulfilledCredentials;

  return (
    <VStack width="100%" align="start" spacing={8}>
      <Stack
        direction={{ base: "column", md: "row" }}
        p={6}
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md"
        spacing={4}
        width="100%">
        <Flex direction="column" flex="1" gap={8} w="100%" h={"auto"} alignContent="center">
          <Heading size="sm" w="100%">
            Your Progress
          </Heading>
          <Flex direction="column" w="100%" h="100%" align="center">
            <Stack w="80%" align="center">
              <Text fontSize="sm" textAlign="center">
                {totalCredentials > 0
                  ? `${activeCredentials} of ${totalCredentials} credentials earned`
                  : "No credentials assigned"}
              </Text>
              <Progress
                value={totalCredentials > 0 ? (activeCredentials / totalCredentials) * 100 : 0}
                w="100%"
                colorScheme="teal"
                hasStripe
                borderRadius="md"
              />
            </Stack>
            <Stat>
              <StatNumber>
                {totalCredentials > 0
                  ? `${((activeCredentials / totalCredentials) * 100).toFixed(2)}%`
                  : "0%"}
              </StatNumber>
              <Text fontSize="xs" w="100%" fontWeight={"medium"}>
                Complete
              </Text>
            </Stat>
          </Flex>
        </Flex>
        <VStack
          h="100%"
          flex="2"
          p={4}
          spacing={4}
          borderLeft={{ base: "none", md: "1px" }}
          borderColor={{ base: "none", md: "chakra-border-color" }}>
          {unfulfilledCredentials > 0 ? (
            <>
              <Text
                fontSize="sm"
                w="100%"
                textAlign={"start"}
                textColor={useColorModeValue("gray.600", "gray.400")}>
                You are required to obtain the following credentials by completing their associated
                trainings.
              </Text>
              <Stack direction="column" w="100%" spacing={0}>
                {data?.unfulfilled_credentials.map((userCredential) => (
                  <UnfulfilledCredentialCard
                    key={userCredential.id}
                    userCredential={userCredential}
                  />
                ))}
              </Stack>
            </>
          ) : (
            <VStack h="100%" p={4}>
              <Circle
                size="40px"
                bgColor={useColorModeValue("green.100", "green.200")}
                border="1px"
                borderColor={useColorModeValue("green.500", "green.400")}
                mb={4}>
                <Text fontSize="xl" w="100%" textAlign={"center"} justifySelf={"center"}>
                  🎉
                </Text>
              </Circle>
              <Text
                fontSize="sm"
                w="100%"
                textAlign={"center"}
                fontWeight={"semibold"}
                textColor={useColorModeValue("gray.700", "auto")}>
                You're all caught up!
              </Text>
              <Text
                fontSize="sm"
                w="100%"
                textAlign={"center"}
                fontWeight={"medium"}
                textColor={useColorModeValue("gray.600", "auto")}>
                You will be notified when a new credential is assigned to you.
              </Text>
            </VStack>
          )}
        </VStack>
      </Stack>

      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <Text>Error loading upcoming trainings</Text>
      ) : (
        <UpcomingTrainings trainingSessions={trainingSessions} location="me" />
      )}

      <VStack
        p={6}
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md"
        align="start"
        spacing={4}
        width="100%">
        <HStack>
          <Heading size="sm">Credentials Awarded to You</Heading>
          <Tag>{activeCredentials}</Tag>
        </HStack>
        <Grid
          templateColumns="repeat(auto-fit, minmax(var(--chakra-space-72), 1fr))"
          gap={4}
          width="100%">
          {activeCredentials > 0 ? (
            data?.active_credentials.map((userCredential) => (
              <CredentialCard key={userCredential.id} userCredential={userCredential} />
            ))
          ) : (
            <Text fontSize="sm" textAlign="center" w="100%">
              No credentials have been awarded yet.
            </Text>
          )}
        </Grid>
      </VStack>
    </VStack>
  );
};
