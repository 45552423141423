import { AddIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Heading,
  Icon,
  Progress,
  Tag,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Attribute,
  AttributesTable,
  ConfirmationButton,
  FileDownloadButton,
  RecordLink,
} from "@sciencecorp/helix-components";
import { humanize, titleize } from "inflection";
import React, { useState } from "react";
import { FiShoppingCart, FiTrash2 } from "react-icons/fi";
import { MdOutlineStorefront } from "react-icons/md";
import { RiEdit2Line } from "react-icons/ri";
import { PurchaseShowData, useUpdatePurchase } from "../../../api/purchase";
import { ServiceRequestShowData, invalidateServiceRequest } from "../../../api/service_requests";
import { NewPurchaseModal } from "../../Purchasing/Purchase/components/NewPurchaseModal";
import { ActionRequired } from "./ActionRequired";

import { UserLoggedInData } from "../../../api/user";
import { MoneyText } from "../../MoneyText";
import { OrderTrackingProgress } from "../../Purchasing/Purchase/components/OrderTracking";
import { purchaseRequestStatusColor } from "../../Purchasing/util";
import { Money } from "../../../helpers/Money";

type ServiceRequestPurchasesProps = {
  serviceRequest: ServiceRequestShowData;
  purchases: PurchaseShowData[];
  currentUser: UserLoggedInData;
  isServicesAdmin: boolean;
};

export const ServiceRequestPurchases = ({
  serviceRequest,
  purchases,
  currentUser,
  isServicesAdmin,
}: ServiceRequestPurchasesProps) => {
  const { mutate: updatePurchase } = useUpdatePurchase(() =>
    invalidateServiceRequest(serviceRequest.id)
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activePurchase, setActivePurchase] = useState<PurchaseShowData | null>(null);

  const editable = serviceRequest.user_in_fulfilling_team || isServicesAdmin;

  return (
    <VStack width="100%" align="start" spacing={4} py={2}>
      <HStack justify="space-between" width="100%">
        <Heading size="md">Purchases</Heading>
        {editable && (
          <Button
            size="sm"
            leftIcon={<AddIcon />}
            onClick={onOpen}
            isDisabled={!serviceRequest.spending_authority}>
            Add New
          </Button>
        )}
      </HStack>

      <Accordion width="100%" allowMultiple>
        {purchases?.map((purchase) => (
          <AccordionItem
            key={purchase.id}
            p={2}
            mb={2}
            width="100%"
            bg={useColorModeValue("gray.50", "gray.700")}
            borderRadius="md"
            border="1px"
            borderColor="chakra-border-color">
            <AccordionButton>
              <HStack justify="space-between" width="100%">
                <HStack>
                  <Icon as={FiShoppingCart} />
                  <Text>
                    Purchase{" "}
                    <Box as="span" fontWeight="light" color="gray.500">
                      #{purchase.id}
                    </Box>
                  </Text>
                </HStack>
                <HStack>
                  <Tag colorScheme={purchaseRequestStatusColor(purchase.status || "")}>
                    {titleize(humanize(purchase.status || ""))}
                  </Tag>
                  <MoneyText fontWeight="medium" money={purchase.line_items_sum} />
                </HStack>
              </HStack>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <VStack spacing={3}>
                {purchase.requires_approval && purchase.current_user_can_approve ? (
                  <ActionRequired serviceRequest={serviceRequest} purchase={purchase} />
                ) : null}

                {purchase.ops_treated && !purchase.line_items_sum ? (
                  <Alert status="warning" width="100%" borderRadius="md">
                    <AlertIcon />
                    <Box>
                      <AlertTitle>Awaiting Cost Update From Ops Team.</AlertTitle>
                      <AlertDescription>
                        You will be notified when a cost estimate has been added.
                      </AlertDescription>
                    </Box>
                  </Alert>
                ) : purchase.line_items_sum ? (
                  <VStack
                    align="start"
                    width="100%"
                    p={4}
                    bg={"chakra-body-bg"}
                    spacing={6}
                    border="1px"
                    borderColor="chakra-border-color"
                    borderRadius="md">
                    <HStack width="100%" justify="space-between">
                      <Heading size="xs">Payment</Heading>
                      {/* <CardDetails purchase={purchase} /> */}
                    </HStack>
                    <Box width="100%">
                      <Flex fontWeight="semibold" gap={2} mb={2}>
                        <Text fontSize="sm">
                          <MoneyText money={purchase.purchase_payments_paid} />
                        </Text>
                        <Box as="span" fontWeight="medium" color="gray.500" fontSize="sm">
                          / <MoneyText as={"span"} money={purchase.line_items_sum} />
                        </Box>
                      </Flex>
                      {purchase.line_items_sum && (
                        <Progress
                          value={Money.pct(
                            purchase.purchase_payments_paid,
                            purchase.line_items_sum
                          ).toNumber()}
                          colorScheme="teal"
                          size="lg"
                          width="100%"
                        />
                      )}
                    </Box>
                  </VStack>
                ) : null}

                {purchase.ops_treated && (
                  <VStack
                    align="start"
                    width="100%"
                    p={4}
                    bg={"chakra-body-bg"}
                    spacing={3}
                    border="1px"
                    borderColor="chakra-border-color"
                    borderRadius="md">
                    <Heading size="xs">Details</Heading>
                    {purchase.description?.split("\n").map((line, index) => (
                      <Text key={index} fontSize="sm">
                        {line}
                      </Text>
                    ))}
                    {purchase.uploaded_files?.map((file) => (
                      <FileDownloadButton
                        key={file.filename}
                        file={file.app_href}
                        filename={file.filename}
                      />
                    ))}
                  </VStack>
                )}

                <VStack
                  align="start"
                  width="100%"
                  p={4}
                  bg={"chakra-body-bg"}
                  spacing={6}
                  border="1px"
                  borderColor="chakra-border-color"
                  borderRadius="md">
                  <Heading size="xs">Other Information</Heading>
                  <AttributesTable
                    title=""
                    attributes={
                      [
                        {
                          label: "Request",
                          value: (
                            <RecordLink
                              type="Purchase"
                              identifier={purchase.id}
                              link={`/services/purchasing/${purchase.id}`}
                            />
                          ),
                        },
                        purchase.vendor && {
                          label: "Vendor",
                          value: (
                            <RecordLink
                              type=""
                              identifier={purchase.vendor.name}
                              link={`/services/vendors/${purchase.vendor.id}`}
                              icon={
                                <Box mt={1} ml={3}>
                                  <Icon as={MdOutlineStorefront} boxSize={4} />
                                </Box>
                              }
                            />
                          ),
                        },
                        {
                          label: "Request Type",
                          value: titleize(humanize(purchase.purchase_type)),
                        },
                        {
                          label: "Estimated Ship Date",
                          value: purchase.estimated_ship_date || "Unknown",
                        },
                      ].filter(Boolean) as Attribute[]
                    }
                  />
                </VStack>
                <VStack
                  align="start"
                  width="100%"
                  p={4}
                  bg={"chakra-body-bg"}
                  spacing={6}
                  border="1px"
                  borderColor="chakra-border-color"
                  borderRadius="md">
                  <Heading size="xs">Order Tracking</Heading>
                  {purchase && (
                    <OrderTrackingProgress
                      isPurchasingAdmin={false}
                      purchase={purchase}
                      onSuccessCallback={() => invalidateServiceRequest(serviceRequest.id)}
                      currentUser={currentUser}
                    />
                  )}
                </VStack>
                {editable && (
                  <ButtonGroup width="100%" display="flex" justifyContent="flex-end">
                    <Button
                      variant="ghost"
                      size="sm"
                      isDisabled={purchase.purchase_payments_paid.isPos()}
                      colorScheme="teal"
                      leftIcon={<RiEdit2Line />}
                      onClick={() => {
                        setActivePurchase(purchase);
                        onOpen();
                      }}>
                      Edit
                    </Button>
                    <ConfirmationButton
                      label="Delete"
                      isDisabled={purchase.purchase_payments_paid.isPos()}
                      confirmationHeader="Delete Purchase"
                      variant="Button"
                      leftIcon={<FiTrash2 />}
                      buttonVariant="ghost"
                      size="sm"
                      colorScheme="red"
                      confirmationButtonLabel="Yes"
                      children="Are you sure you want to delete this purchase? You can't undo this action."
                      onConfirm={() => updatePurchase({ id: purchase.id, is_deleted: true })}
                    />
                  </ButtonGroup>
                )}
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <NewPurchaseModal
        isOpen={isOpen}
        onClose={onClose}
        spendingAuthority={{
          id: serviceRequest.id,
          type: "ServiceRequest",
          name: serviceRequest.request_description,
        }}
        disableSpendingAuthorityPicker
        purchase={activePurchase}
        onSuccessCallback={() => invalidateServiceRequest(serviceRequest.id, "purchases")}
      />
    </VStack>
  );
};
