import { z } from "zod";
import { api } from ".";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { aggregationSchema, searchParams, SearchParams } from "./collection_types";
import { invalidateCredentials } from "./credentials";
import { zodParse } from "./zodParse";
import { userMinimalSchema } from "./user";
import { dateTimeSchema } from "../helpers/dateTime";
import { createSearchResponseSchema } from "./shared";
import { get } from "http";

export const USER_CREDENTIALS_BASE_URL = "user_credentials";

export const userCredentialSchema = z.object({
  id: z.number(),
  user_id: z.number(),
  credential_id: z.number(),
  expiration_date: dateTimeSchema.nullable(),
  status: z.string(),
  date_issued: dateTimeSchema.nullable(),
});

export const userCredentialShowSchema = userCredentialSchema
  .omit({ credential_id: true, user_id: true })
  .extend({
    user: z.lazy(() => userMinimalSchema),
    credential: z.object({
      id: z.number(),
      name: z.string(),
      description: z.string(),
      is_public: z.boolean(),
    }),
    days_until_expiration: z.number().optional(),
    expiration_percentage: z.number().optional(),
    user_training_sessions_for_trainings: z
      .object({
        id: z.number(),
        name: z.string(),
        status: z.string(),
      })
      .array(),
  });

export const userCredentialUnfulfilledShowSchema = userCredentialShowSchema
  .omit({
    days_until_expiration: true,
    expiration_percentage: true,
    user_training_sessions_for_trainings: true,
    date_issued: true,
    expiration_date: true,
  })
  .extend({
    trainings_total_count: z.number(),
    trainings_completed_count: z.number(),
  });

export const userCredentialIndexSchema = z.object({
  id: z.number(),
  credential: z.object({
    id: z.number(),
    name: z.string(),
  }),
  description: z.string(),
});

export const userCredentialSearchSchema = createSearchResponseSchema(
  userCredentialIndexSchema
).extend({
  active_credentials: userCredentialShowSchema.array(),
  unfulfilled_credentials: z.array(userCredentialUnfulfilledShowSchema),
  employee_count: z.number(),
});

export const userCredentialFlagSchema = z.object({
  user: z.lazy(() => userMinimalSchema),
  user_credentials: z.array(
    z.object({
      id: z.number(),
      credential: z.object({ id: z.number(), name: z.string() }),
      expiration_date: dateTimeSchema,
    })
  ),
});

export const userCredentialFlagResponseSchema = z.object({
  results: userCredentialFlagSchema.array(),
  credential_count: z.number(),
});

export const UserCredentialCreateParamsSchema = z.object({
  user_ids: z.number().array(),
  credential_id: z.number(),
  status: z.string(),
  date_issued: dateTimeSchema.nullable(),
});

export type UserCredentialData = z.infer<typeof userCredentialSchema>;
export type UserCredentialShowData = z.infer<typeof userCredentialShowSchema>;
export type UserCredentialFlagData = z.infer<typeof userCredentialFlagSchema>;
export type UserCredentialUnfulfilledShowData = z.infer<typeof userCredentialUnfulfilledShowSchema>;
export type UserCredentialIndexData = z.infer<typeof userCredentialIndexSchema>;
export type UserCredentialCreateParams = z.infer<typeof UserCredentialCreateParamsSchema>;

// api queries

export const getUserCredentials = async () => {
  const result = await api.get(USER_CREDENTIALS_BASE_URL);
  return zodParse(userCredentialIndexSchema, result.data);
};

export const getUserCredential = async (id: number) => {
  const result = await api.get(`${USER_CREDENTIALS_BASE_URL}/${id}`);
  return zodParse(userCredentialShowSchema, result.data);
};

export const getUserCredentialFlags = async ({ status }: { status: string }) => {
  const result = await api.get(`${USER_CREDENTIALS_BASE_URL}/flags`, { params: { status } });
  return zodParse(userCredentialFlagResponseSchema, result.data);
};

export const searchUserCredentials = async ({ aggs, bodyOptions, filters, term }: SearchParams) => {
  const path = [USER_CREDENTIALS_BASE_URL, "search"];
  const search = searchParams({ aggs, bodyOptions, filters, term });
  const result = await api.post(path.join("/"), { ...search });
  return zodParse(userCredentialSearchSchema, result.data);
};

export const newUserCredential = async (user_credential: UserCredentialCreateParams) => {
  const result = await api.post(USER_CREDENTIALS_BASE_URL, { user_credential });
  return result.data;
};

export const updateUserCredential = async (user_credential: Partial<UserCredentialData>) => {
  const result = await api.put(`${USER_CREDENTIALS_BASE_URL}/${user_credential.id}`, {
    user_credential,
  });
  return result.data;
};

export const deleteUserCredential = async (id: number) => {
  await api.delete(`${USER_CREDENTIALS_BASE_URL}/${id}`);
};

// query hooks

export const invalidateUserCredentials = (id?: number) => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({
      queryKey: [USER_CREDENTIALS_BASE_URL, id],
    });
};

export const useGetUserCredentials = () => {
  return useQuery({
    queryKey: [USER_CREDENTIALS_BASE_URL],
    queryFn: () => getUserCredentials(),
  });
};

export const useGetUserCredential = (id: number) => {
  return useQuery({
    queryKey: [USER_CREDENTIALS_BASE_URL, id],
    queryFn: () => getUserCredential(id),
  });
};

export const useGetUserCredentialFlags = (status: string) => {
  return useQuery({
    queryKey: [USER_CREDENTIALS_BASE_URL, "flag", status],
    queryFn: () => getUserCredentialFlags({ status }),
  });
};

export const useSearchUserCredentials = (params: SearchParams) => {
  return useQuery({
    queryKey: [USER_CREDENTIALS_BASE_URL, params],
    queryFn: () => searchUserCredentials(params),
  });
};

export const useNewUserCredential = (credentialId: number) => {
  return useMutation({
    mutationFn: newUserCredential,
    onSuccess: invalidateCredentials(credentialId),
  });
};

export const useUpdateUserCredential = () => {
  return useMutation({
    mutationFn: updateUserCredential,
    onSuccess: invalidateCredentials(),
  });
};

export const useDeleteUserCredential = (credentialId: number) => {
  return useMutation({
    mutationFn: deleteUserCredential,
    onSuccess: invalidateCredentials(credentialId),
  });
};
